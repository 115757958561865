import { IconButton, Popover } from "@mui/material";
import { Filter } from "lucide-react";
import React, { useState } from "react";

const FilterBtn = ({ component }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        disableRipple
        sx={{ backgroundColor: "#0a1e40", borderRadius: "8px" }}
        onClick={handleClick}
      >
        <Filter className="text-white text-sm" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ width: 280 }}>{component}</div>
      </Popover>
    </div>
  );
};

export default FilterBtn;
