import { MuiBox, MuiTypography, Textfield } from 'components/atoms'
import React, { useState } from 'react'
import { Button, Checkbox } from '@mui/material'

import FilledStar from 'assets/images/SVG/filled star.svg'
import NullStar from 'assets/images/SVG/null star.svg'
import Reviews from 'assets/images/SVG/reviews img.svg'
const ReviewsModal = () => {

    // For Rating
    const [rating, setRating] = useState(0);
    const handleRatingChange = (value) => {
        setRating(value);
    };
    return (
        <MuiBox sx={{ px: { xs: "15px", sm: "30px", md: "60px" }, py: 5, display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <MuiTypography textAlign='center' variant='h6' fontWeight='bold'>
                Reviews
            </MuiTypography>
            <MuiBox sx={{ mt: { xs: 2, sm: 5, md: 10 } }}>
                <MuiBox sx={{ maxWidth: '276px', m: '0 auto' }}>
                    <img src={Reviews} />
                </MuiBox>
            </MuiBox>
            <MuiBox sx={{ mt: 3, bgcolor: '#fff', borderRadius: '12px', p: '3px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {[1, 2, 3, 4, 5].map((star) => (
                    <Checkbox
                        key={star}
                        icon={<img src={NullStar} alt={`Star ${star}`} />}
                        checkedIcon={<img src={FilledStar} alt={`Star ${star}`} />}
                        checked={rating >= star}
                        onChange={() => handleRatingChange(star)}
                    />
                ))}
            </MuiBox>

            <MuiBox sx={{ mt: 2 }}>
                <Textfield label={'Comments/information'} className={'cancel-modal-textfield'} rows={6} />
            </MuiBox>

            <MuiBox sx={{ textAlign: "center", mt: 2 }}>
                <Button variant='lightButton' sx={{ maxWidth: '350px' }}>
                    Send
                </Button>
            </MuiBox>
        </MuiBox>
    )
}

export default ReviewsModal