import React from "react";
import ImgProfile from "assets/images/PNG/sender.png";
import MuiBox from "../MuiBox";

const InviteSvgIcon = () => {
  return (
    <>
      <MuiBox>
        <svg
          viewBox="0 0 308 290"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "auto" }}
          preserveAspectRatio="xMidYMid meet"
        >
          <line
            x1="103.962"
            y1="118.948"
            x2="62.9845"
            y2="57.9574"
            stroke="#817D86"
            strokeLinecap="round"
            strokeDasharray="5 5"
          />
          <circle
            cx="48.1486"
            cy="32.1486"
            r="27.7709"
            fill="url(#pattern0)"
            stroke="#0A1E41"
            strokeWidth="7.24458"
          />
          <line
            x1="197"
            y1="118.678"
            x2="239.76"
            y2="75.9173"
            stroke="#817D86"
            strokeLinecap="round"
            strokeDasharray="5 5"
          />
          <circle
            cx="255.922"
            cy="55.1486"
            r="27.7709"
            fill="url(#pattern1)"
            stroke="#0A1E41"
            strokeWidth="7.24458"
          />
          <line
            x1="209.707"
            y1="170"
            x2="253.433"
            y2="213.726"
            stroke="#817D86"
            strokeLinecap="round"
            strokeDasharray="5 5"
          />
          <circle
            cx="275.649"
            cy="230.854"
            r="27.7709"
            fill="url(#pattern2)"
            stroke="#0A1E41"
            strokeWidth="7.24458"
          />
          <line
            x1="100.73"
            y1="187.707"
            x2="49.7596"
            y2="238.678"
            stroke="#817D86"
            strokeLinecap="round"
            strokeDasharray="5 5"
          />
          <circle
            cx="32.1486"
            cy="258.481"
            r="27.7709"
            fill="url(#pattern3)"
            stroke="#0A1E41"
            strokeWidth="7.24458"
          />
          <circle
            cx="143.558"
            cy="162.108"
            r="83.3127"
            fill="url(#pattern4)"
            stroke="#0A1E41"
            strokeWidth="7.24458"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <image
                href={ImgProfile}
                width="1"
                height="1"
                preserveAspectRatio="none"
              />
            </pattern>
            <pattern
              id="pattern1"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <image
                href={ImgProfile}
                width="1"
                height="1"
                preserveAspectRatio="none"
              />
            </pattern>
            <pattern
              id="pattern2"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <image
                href={ImgProfile}
                width="1"
                height="1"
                preserveAspectRatio="none"
              />
            </pattern>
            <pattern
              id="pattern3"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <image
                href={ImgProfile}
                width="1"
                height="1"
                preserveAspectRatio="none"
              />
            </pattern>
            <pattern
              id="pattern4"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <image
                href={ImgProfile}
                width="1"
                height="1"
                preserveAspectRatio="none"
              />
            </pattern>
          </defs>
        </svg>
      </MuiBox>
    </>
  );
};

export default InviteSvgIcon;
