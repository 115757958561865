const checkAccess = (access) => {
  const user = JSON.parse(localStorage.getItem("aboutcare-admin"));
  let tempArray = [];
  user?.access?.forEach((acc) => {
    let type = acc.type;
    acc.permissions?.forEach((perm) => {
      tempArray?.push(`${perm.toLowerCase()}_${type.toLowerCase()}`);
    });
  });
  let hasAccess = tempArray?.includes(access);
  return hasAccess
};

export default checkAccess;
