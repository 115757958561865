import { MuiBox, MuiTypography } from "components/atoms";
import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import CalanderBlack from "assets/images/SVG/CalendarBlack.svg";
import DollarBlue from "assets/images/SVG/DollarBlue.svg";
import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import PhoneIcon from "assets/images/SVG/Smartphone.svg";
const MyJobsCard = ({ type }) => {
  const typeProfessional = type === "professional";
  const [applied, setApplied] = useState(false);
  const handleApplyClick = () => {
    setApplied(!applied);
  };
  return (
    <MuiBox
      component={Link}
      to={
        typeProfessional
          ? "/professional/application-details"
          : "/hospital/hospital/application-details"
      }
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        borderLeft: "2px solid #0CA8DF",
      }}
    >
      <MuiTypography variant="h6" color="text.main">
        RN’S
      </MuiTypography>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <MuiBox
            sx={{
              height: "42px",
              width: "42px",
              bgcolor: "#E8F0F3",
              flexShrink: 0,
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={CalanderBlack} />
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              gap: "1px",
              maxWidth: "8em",
              flexDirection: "column",
            }}
          >
            <MuiTypography color="text.main" fontSize="15px" fontWeight="bold">
              07/03/2024
            </MuiTypography>
            <MuiTypography
              color="text.contrastText"
              fontSize="13px"
              variant="baseFont"
            >
              07:00 AM - 11:00 AM
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox
          sx={{
            maxWidth: "11em",
            display: "flex",
            gap: "5px",
            mt: 1,
            alignItems: "center",
          }}
        >
          <img
            src={DollarBlue}
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          <MuiTypography color="text.main" variant="baseFont">
            20.00/hr
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: 1,
            flexWrap: "wrap",
            p: 1,
          }}
        >
          <MuiBox
            sx={{
              width: "fit-content",
              p: "5px 12px",
              bgcolor: "#007AFF26",
              borderRadius: "30px",
            }}
          >
            <MuiTypography
              color="#2D2635"
              fontSize="15px"
              sx={{ whiteSpace: "nowrap" }}
            >
              Patient safety
            </MuiTypography>
          </MuiBox>
          <MuiBox
            sx={{
              width: "fit-content",
              p: "5px 12px",
              bgcolor: "#007AFF26",
              borderRadius: "30px",
            }}
          >
            <MuiTypography
              color="#2D2635"
              fontSize="15px"
              sx={{ whiteSpace: "nowrap" }}
            >
              Full Time
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        {type === "professional" ? (
          <Button
            component={Link}
            variant={applied ? "darkButton" : "lightButton"}
            sx={{ width: "fit-content", p: "5px 12px" }}
            onClick={handleApplyClick}
          >
            {applied ? "Applied" : "Apply"}
          </Button>
        ) : (
          <MuiBox sx={{ display: "flex", gap: "12px", width: "fit-content" }}>
            <IconButton
              disableRipple
              component={Link}
              aria-label="phone"
              sx={{
                height: "30px",
                width: "30px",
                bgcolor: "darkButton.main",
                transition: "all 0.3s",
                borderRadius: "6px",
                overflow: "hidden",
                p: "5px",
                "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
              }}
            >
              <img src={PhoneIcon} />
            </IconButton>
            <IconButton
              disableRipple
              component={Link}
              aria-label="phone"
              sx={{
                height: "30px",
                width: "30px",
                bgcolor: "darkButton.main",
                transition: "all 0.3s",
                borderRadius: "6px",
                overflow: "hidden",
                p: "5px",
                "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
              }}
            >
              <img src={ChatIcon} />
            </IconButton>
          </MuiBox>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default MyJobsCard;
