import React from "react";

function index() {
  return (
    <span className="px-2.5 py-0.5 inline-block text-[11px] text-center font-medium rounded border bg-red-100 border-transparent text-red-500">
      No data Found
    </span>
  );
}

export default index;
