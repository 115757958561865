import { createSlice } from "@reduxjs/toolkit";
import { GetJobDetails, GetJobsAction, GetProfessionalJobsAction } from "store/middlewares/jobs";
const initialState = {
  jobs: [],
  jobsLoading: false,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers(builder) {
    builder
      // For Get all jobs
      .addCase(GetJobsAction.pending, (state) => {
        state.jobs = [];
        state.jobsLoading = true;
      })
      .addCase(GetJobsAction.fulfilled, (state, action) => {
        state.jobs = action.payload.response;
        state.jobsLoading = false;
      })
      .addCase(GetJobsAction.rejected, (state) => {
        state.jobs = [];
        state.jobsLoading = false;
      })

      // For Get Professionl jobs
      .addCase(GetProfessionalJobsAction.pending, (state) => {
          state.jobs = [];
          state.jobsLoading = true;
      })
      .addCase(GetProfessionalJobsAction.fulfilled, (state, action) => {
          state.jobs = action.payload.response;
          state.jobsLoading = false;
      })
      .addCase(GetProfessionalJobsAction.rejected, (state) => {
          state.jobs = [];
          state.jobsLoading = false;
      })

      // For Get jobDetails
      .addCase(GetJobDetails.pending, (state) => {
          state.jobs = [];
          state.jobsLoading = true;
      })
      .addCase(GetJobDetails.fulfilled, (state, action) => {
          state.jobs = action.payload.response;
          state.jobsLoading = false;
          
      })
      .addCase(GetJobDetails.rejected, (state) => {
          state.jobs = [];
          state.jobsLoading = false;
      })
    },
});

export default jobsSlice.reducer;
