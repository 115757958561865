import React, { useEffect, useMemo, useRef, useState } from "react";
import BlankImg from "assets/images/blank.png";
import { useDispatch } from "react-redux";
import StarFill from "../../../../../assets/images/starFill.svg";
import {
  AddChatAction,
  GetUserMessagesAction,
} from "store/middlewares/HelpAndSupport";

function ChatList({ chatList }) {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const filteredChats = useMemo(
    () =>
      chatList?.filter((chat) =>
        chat.userName?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [chatList, searchTerm]
  );

  const userChatOpen = (user) => {
    dispatch(AddChatAction({ id: user.id }));
    setSelectedUser(user);
    dispatch(GetUserMessagesAction({ id: user.id }));
  };
  const intervalRef = useRef(null);

  useEffect(() => {
    if (selectedUser) {
      // Clear any existing interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (selectedUser.isChatFinished === false) {
        dispatch(GetUserMessagesAction({ id: selectedUser?.id }));
        // Set new interval for fetching messages
        intervalRef.current = setInterval(() => {
          dispatch(GetUserMessagesAction({ id: selectedUser?.id }));
        }, 3000);
      }

      // Cleanup on component unmount or when selectedUser changes
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }
  }, [dispatch, selectedUser]);

  return (
    <div className="block w-full xl:block xl:w-80 shrink-0 menu-content">
      <div className="h-[calc(100vh_-_theme('spacing.10')_*_6)] xl:min-h-[calc(100vh_-_theme('height.header')_*_2.4)] card [&.show]:block [&.active]:xl:block">
        <div className="flex flex-col h-full">
          <div className="card-body">
            <div className="flex items-center gap-3">
              <h6 className="text-15 grow font-bold">Users</h6>
            </div>
            <div className="relative mt-5">
              <input
                type="text"
                className="ltr:pl-8 search form-input border-slate-200 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300 disabled:text-slate-500 placeholder:text-slate-400"
                placeholder="Search for ..."
                autoComplete="off"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <ul
            className="flex flex-col gap-1 chat-scroll h-[calc(100vh_-_0px)] xl:h-[calc(100vh_-_300px)] overflow-scroll"
            id="chatList"
          >
            {(filteredChats || []).map((item, key) => (
              <React.Fragment key={key}>
                <li>
                  <div
                    className={`flex items-center justify-between px-5 py-2 [&.active]:bg-slate-50 group/item ${
                      item.status
                    } ${selectedUser?.userName === item.userName && "active"}`}
                    onClick={() => userChatOpen(item)}
                  >
                    <div className="flex items-center gap-2">
                      <div className="w-10 max-w-10 h-10">
                        <img
                          src={
                            item?.userProfileImageUrl
                              ? item?.userProfileImageUrl
                              : BlankImg
                          }
                          alt=""
                          className="rounded-full w-full h-full object-cover pointer-events-none"
                        />
                      </div>
                      <div className="max-w-[180px]">
                        <p className="mb-1 text-ellipsis overflow-hidden">
                          {item.userName}
                        </p>
                        <p className="text-xs text-gray-400 text-ellipsis overflow-hidden ">
                          {item?.lastMessage}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <div className="flex items-center">
                        <img src={StarFill} />
                        <h6 className="">
                          {item?.expeirence === null ? "0" : item?.expeirence}
                        </h6>
                      </div>
                    </div>
                  </div>
                </li>
              </React.Fragment>
            ))}

            {!filteredChats?.length && (
              <li className="px-5">
                <p className="mb-1 text-slate-500">No Users Found</p>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ChatList;
