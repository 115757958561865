import React from "react";

const StatusPill = ({ status, statusText }) => {
  const colorObj = {
    accepted: "border-green-400 text-green-500",
    true: "border-sky-400 text-sky-500",
    rejected: "border-red-400 text-red-500",
    pending: "border-orange-400 text-orange-500",
  };
  const bgcolorObj = {
    accepted: "bg-green-500",
    rejected: "bg-red-500",
    pending: "bg-orange-500",
    true: "bg-sky-500",
  };
  return (
    <div
      className={`flex items-center px-2.5 py-0.5 text-md capitalize font-medium rounded border bg-white  ${colorObj[status]}`}
    >
      <div
        className={`size-1.5 mr-2 rounded-full inline-block ${bgcolorObj[status]} `}
      ></div>
      {statusText}
    </div>
  );
};

export default StatusPill;
