import axiosClient from "utils/api";
const URI = "/admin/roles";

export const getRoles = async (payload) => {
  const URL = `${URI}/roles`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Roles:", error);
    throw error;
  }
};

export const deleteRole = async (payload) => {
  const URL = `${URI}/role/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Role:", error);
    throw error;
  }
};

export const updateRole = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/role/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Role:", error);
    throw error;
  }
};

export const addRole = async (payload) => {
  const URL = `${URI}/role`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Role:", error);
    throw error;
  }
};

export const updateRoleVisibilityAction = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Visibility Roles:", error);
    throw error;
  }
};
