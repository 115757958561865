import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { description, Title } from "utils/validation/AddJobTitle";
import { AddJobTitleAction } from "store/middlewares/jobTitle";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";

function ModalAddJobTitle() {
  const dispatch = useDispatch();

  const intitalVal = {
    title: "",
    description: "",
  };

  const AddJobTitleSchema = Yup.object({
    title: Title,
    description: description,
  });

  const handleSubmit = (values) => {
    dispatch(AddJobTitleAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Job Title"}
        type="text"
        name="title"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.title && formik.touched.title}
        errorMsg={formik.errors.title}
        value={formik.values.title}
        placeholder="Enter Title"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        type="text"
        label={"Tooltip"}
        name="description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.description && formik.touched.description}
        errorMsg={formik.errors.description}
        value={formik.values.description}
        placeholder="Enter tooltip"
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddJobTitle;
