import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { Fragment, useEffect, useState } from "react";
import GlobalPagination from "utils/GlobalPagination";

const PaginationComponent = ({
  totalCount,
  PaginationClassName,
  // currentPage,
  limit,
  changeLimit,
  changePage,
  totalPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(3);

  const handleSetCurrentPage = (page) => {
    changePage(page);
    setCurrentPage(page);
  };

  const previousPage = () => {
    handleSetCurrentPage(currentPage - 1);
    if (currentPage - 1 === lowerLimit) {
      setUpperLimit(upperLimit - 1);
      setLowerLimit(lowerLimit - 1);
    }
  };

  const nextPage = () => {
    handleSetCurrentPage(currentPage + 1);
    if (currentPage === upperLimit) {
      setUpperLimit(upperLimit + 1);
      setLowerLimit(lowerLimit + 1);
    }
  };

  const handleSelect = (val) => {
    changeLimit(val);
    handleSetCurrentPage(1);
  };

  useEffect(() => {
    if (totalPage <= 1) {
      setPrevDisabled(true);
    } else if (currentPage === 1) {
      setPrevDisabled(true);
    } else {
      setPrevDisabled(false);
    }
  }, [currentPage, totalPage]);

  useEffect(() => {
    if (totalPage <= 1) {
      setNextDisabled(true);
    } else if (currentPage === totalPage) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [currentPage, totalPage]);
  return (
    <div className={PaginationClassName}>
      <div className="mb-4 grow md:mb-0 flex items-center">
        <div className="mr-3">
          <label>
            Show
            <select
              name="basic_tables_length"
              aria-controls="basic_tables"
              value={limit}
              className="px-3 py-2 form-select border rounded ml-2 border-slate-200  focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500  placeholder:text-slate-400  inline-block w-auto"
              onChange={(event) => handleSelect(Number(event.target.value))}
            >
              {GlobalPagination?.limitOptions?.map((opt) => {
                return (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
        <div className="text-slate-500">
          of
          <b> {totalCount} </b> Results
        </div>
      </div>
      <ul className="flex flex-wrap items-center gap-2 shrink-0">
        <li>
          <button
            className={`inline-flex items-center justify-center bg-white  h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200  text-slate-500  hover:text-custom-500  hover:bg-custom-50  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-custom-500  [&.active]:bg-custom-50 [&.active]:border-custom-50  [&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto ${
              prevDisabled && "disabled"
            }`}
            disabled={prevDisabled}
            onClick={previousPage}
          >
            <ChevronLeft className="size-4 mr-1"></ChevronLeft> Prev
          </button>
        </li>
        {[...Array(totalPage).keys()]
          .map((i) => i + 1)
          ?.slice(lowerLimit, upperLimit)
          ?.map((item, key) => (
            <Fragment key={key}>
              <li>
                <button
                  className={`inline-flex items-center justify-center bg-white  size-8 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500  hover:bg-custom-100  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-white  [&.active]:bg-custom-500  [&.active]:border-custom-500  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
                ${currentPage === item && "active"}`}
                  onClick={() => {
                    if (item === lowerLimit + 1 && lowerLimit > 0) {
                      setLowerLimit(lowerLimit - 1);
                      setUpperLimit(upperLimit - 1);
                    }
                    handleSetCurrentPage(item);
                  }}
                >
                  <p
                    style={{
                      color: currentPage === item ? "#fff" : "inherit",
                    }}
                  >
                    {item}
                  </p>
                </button>
              </li>
            </Fragment>
          ))}
        {totalPage > upperLimit && (
          <li>
            <button
              className={`inline-flex items-center justify-center bg-white  size-8 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500  hover:bg-custom-100  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-white  [&.active]:bg-custom-500  [&.active]:border-custom-500  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
                `}
              onClick={() => {
                setUpperLimit(upperLimit + 1);
                setLowerLimit(lowerLimit + 1);
                handleSetCurrentPage(currentPage + 1);
              }}
            >
              <p
                style={{
                  color: "inherit",
                }}
              >
                ...
              </p>
            </button>
          </li>
        )}
        <li>
          <button
            className={`inline-flex items-center justify-center bg-white  h-8 px-3 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500 hover:bg-custom-50  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-custom-500  [&.active]:bg-custom-50  [&.active]:border-custom-50  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
          ${!nextDisabled && ""}`}
            onClick={nextPage}
            disabled={nextDisabled}
          >
            Next <ChevronRight className="size-4 ml-1"></ChevronRight>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default PaginationComponent;
