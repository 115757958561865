import { createSlice } from "@reduxjs/toolkit";
import { GetDashboardAction } from "store/middlewares/dashboard";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardLoading: false,
    dashboard: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // For get dashboard data
      .addCase(GetDashboardAction.pending, (state) => {
        state.dashboardLoading = true;
        state.dashboard = [];
      })
      .addCase(GetDashboardAction.fulfilled, (state, action) => {
        state.dashboardLoading = false;
        state.dashboard = action.payload.response;
      })
      .addCase(GetDashboardAction.rejected, (state) => {
        state.dashboardLoading = false;
        state.dashboard = [];
      })
  },
});

export default dashboardSlice.reducer;
