import React from 'react';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';

const MuiContainer = ({ children, fullWidth = true, padding = false, ...props }) => {
    return (
        <Container
            maxWidth={fullWidth ? false : '1400px'}
            disableGutters={padding ? false : true}
            {...props}
        >
            {children}
        </Container>
    );
};

MuiContainer.propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    padding: PropTypes.bool
};

export default MuiContainer;
