import { MuiBox, MuiTypography, Textfield } from 'components/atoms'
import React from 'react'
import { Button, Checkbox, FormControl, FormControlLabel } from '@mui/material'

import Cancel from 'assets/images/SVG/Cancel.svg'
import CheckboxBorder from 'assets/images/SVG/checkbox border.svg'
import CheckboxIcon from 'assets/images/SVG/check-square.svg'
const CancelModal = () => {
  return (
    <MuiBox sx={{ px: { xs: "10px", sm: "20px", md: "30px" }, py: 5, display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <MuiBox sx={{ textAlign: 'center' }}>
        <MuiBox sx={{ maxWidth: '240px', m: '0 auto' }}>
          <img src={Cancel} />
        </MuiBox>
        <MuiTypography variant="h4" fontWeight={"bold"} align={"center"}>
          Cancel
        </MuiTypography>
      </MuiBox>

      <MuiBox sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl>
          <FormControlLabel control={
            <Checkbox
              defaultChecked
              icon={<img src={CheckboxBorder} className='checkbox-icon' />}
              checkedIcon={<img src={CheckboxIcon} className='checkbox-icon' />}
            />
          } label="Low Patient Census" />
        </FormControl>
        <FormControl>
          <FormControlLabel control={
            <Checkbox
              icon={<img src={CheckboxBorder} className='checkbox-icon' />}
              checkedIcon={<img src={CheckboxIcon} className='checkbox-icon' />}
            />
          } label="Health and Safety Concerns" />
        </FormControl>
        <FormControl>
          <FormControlLabel control={
            <Checkbox
              icon={<img src={CheckboxBorder} className='checkbox-icon' />}
              checkedIcon={<img src={CheckboxIcon} className='checkbox-icon' />}
            />
          } label="Emergency Situations" />
        </FormControl>
        <FormControl>
          <FormControlLabel control={
            <Checkbox
              icon={<img src={CheckboxBorder} className='checkbox-icon' />}
              checkedIcon={<img src={CheckboxIcon} className='checkbox-icon' />}
            />
          } label="Other" />
        </FormControl>
      </MuiBox>

      <MuiBox>
        <Textfield label={'Additional Notes'} className={'cancel-modal-textfield'} rows={6} />
      </MuiBox>

      <MuiBox sx={{ display: 'flex', gap: 4, mt: 3 }}>
        <Button variant='darkButton'>
          Cancel
        </Button>
        <Button variant='lightButton'>
          Submit
        </Button>
      </MuiBox>
    </MuiBox>
  )
}

export default CancelModal