import { ArrowBack } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetForgetPass,
  resetResetPass,
} from "store/slices/admin/session/auth";

const BackToLoginBtn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { forgotPass, resetPass } = useSelector((state) => state.auth);

  return (
    <button
      onClick={() => {
        if (forgotPass) {
          dispatch(resetForgetPass());
        }
        if (resetPass) {
          dispatch(resetResetPass());
        }
        navigate("/admin");
      }}
      className="flex justify-center text-vertical-menu 
                hover:text-custom-500 
                hover:bg-vertical-menu-dark mx-auto items-center gap-2 text-lg px-3 bg-vertical-bg hover:text-white  
                p-2 rounded-lg"
    >
      <ArrowBack />
      <p>Back To Login</p>
    </button>
  );
};

export default BackToLoginBtn;
