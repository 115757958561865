import { createSlice } from "@reduxjs/toolkit";
import {
  ForgotPasswordAction,
  LoginAdminAction,
  LoginWebAction,
  LogOutAdminAction,
  LogOutWebAction,
  ResetPasswordAction,
  SendOtpAction,
  ValidateTokenAdmin,
  VerifyOtpAction,
} from "store/middlewares/session/auth";
import LocalstorageService from "utils/localstorage-services";
import { encryptAndStoreToken } from "utils/CryptoJS";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginMessage: "",
    forgotPass: false,
    resetPass: false,
    forgotPassWeb: null,
    isTokenValid: true,
    registerEmail: "",
    isOtpVerififed: false,
    registerUser: null,
  },
  reducers : {
    resetForgetPass : (state) => {
      state.forgotPass = false
    },
    resetResetPass : (state) => {
      state.resetPass = false
    }
  },
  extraReducers(builder) {
    builder
      .addCase(LoginAdminAction.pending, (state) => {
        state.loginMessage = "";
      })
      .addCase(LoginAdminAction.fulfilled, (state, action) => {
        let { response } = action.payload;
        const localStorage = {
          user: { ...response, token: encryptAndStoreToken(response?.token) },
        };
        LocalstorageService.loginUser(localStorage);
        state.loginMessage = action.payload.message;
        window.location = "/admin/dashboard";
      })
      .addCase(LoginAdminAction.rejected, (state, action) => {
        state.loginMessage = action.payload.message;
      })
      .addCase(LogOutAdminAction.fulfilled, (state) => {
        state.loginMessage = "";  
        LocalstorageService.removeFromLocalStorage("aboutcare-admin");
        window.location = "/admin";
      })
      .addCase(LogOutWebAction.fulfilled, (state) => {
        state.loginMessage = "";
        LocalstorageService.removeFromLocalStorage("aboutcare-web");
        window.location = "/";
      })
      .addCase(LoginWebAction.pending, (state) => {
        state.loginMessage = "";
      })
      .addCase(LoginWebAction.fulfilled, (state, action) => {
        let { response } = action.payload;
        const localStorage = {
          user: {
            ...response.finalResponse,
            token: encryptAndStoreToken(response?.token),
          },
        };
        LocalstorageService.webUser(localStorage);
        state.loginMessage = action.payload.message;
        window.location = `/${response.finalResponse.role}/home`;
      })
      .addCase(LoginWebAction.rejected, (state, action) => {
        state.loginMessage = action.payload.message;
      })
      .addCase(ForgotPasswordAction.fulfilled, (state) => {
        state.forgotPass = true;
        state.resetPass = false;
      })
      .addCase(ResetPasswordAction.fulfilled, (state) => {
        state.forgotPass = false;
        state.resetPass = true;
      })
      .addCase(ValidateTokenAdmin.fulfilled, (state, action) => {
        state.isTokenValid = !action.payload.response.isTokenExpired;
      })
      .addCase(ValidateTokenAdmin.rejected, (state) => {
        state.isTokenValid = false;
      })
      .addCase(SendOtpAction.fulfilled, (state, action) => {
        state.isOtpVerififed = false;
        if (action.payload.type === "forgotPassword") {
          state.forgotPassWeb = action.payload;
        } else {
          state.registerUser = action.payload;
        }
      })
      .addCase(VerifyOtpAction.fulfilled, (state, action) => {
        let { type, otp, ...rest } = action.payload;
        console.log(otp, "otp")
        if (type === "forgotPassword") {
          state.forgotPassWeb = { email: rest.email };
        } else {
          state.registerUser = { ...rest };
        }
        state.isOtpVerififed = true;
      });
  },
});

export const { resetForgetPass, resetResetPass } = authSlice.actions
 
export default authSlice.reducer;
