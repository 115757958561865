import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  AddAddressForWebAction,
  DeleteAddressForWebAction,
  EditAddressForWebAction,
  GetAddressForWebAction,
  SetAddressDefault,
} from "store/middlewares/address";
import {
  AddExperienceAction,
  DeleteExperienceAction,
  EditExperienceAction,
  GetExperienceWebAction,
} from "store/middlewares/experience";
import {
  AddHolidaysAction,
  DeleteHolidaysAction,
  EditHolidaysAction,
  GetHolidaysWebAction,
} from "store/middlewares/holidays";
import {
  DeleteAccountAction,
  GetMeAction,
  GetMeForWebAction,
  UpdateMeAction,
  UpdateMeForWebAction,
  UpdateMileageAction,
} from "store/middlewares/me";
import {
  GetNotificationSettings,
  UpdateNotificationSettings,
} from "store/middlewares/notifications";
import LocalstorageService from "utils/localstorage-services";

// isDeleteMeFulFilled
export const meSlice = createSlice({
  name: "me",
  initialState: {
    meLoading: false,
    me: null,
    address: [],
    addressLoading: false,
    experiences: [],
    totalExperience: 0,
    experiencesLoading: false,
    notifications: null,
    notificationsLoading: false,
    holidays: [],
    holidaysLoading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetMeAction.pending, (state) => {
        state.meLoading = true;
        state.me = null;
      })
      .addCase(GetMeAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload.response;
      })
      .addCase(GetMeAction.rejected, (state) => {
        state.meLoading = false;
        state.me = null;
      })
      .addCase(GetMeForWebAction.pending, (state) => {
        state.meLoading = true;
        state.me = null;
      })
      .addCase(GetMeForWebAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload.response;
      })
      .addCase(GetMeForWebAction.rejected, (state) => {
        state.meLoading = false;
        state.me = null;
      })
      .addCase(UpdateMeForWebAction.fulfilled, (state, action) => {
        state.meLoading = false;
        let tempObj = { ...state.me };
        if (tempObj.role === "hospital") {
          if (action.payload.response.updatedProfile) {
            tempObj = { ...tempObj, ...action.payload.response.updatedProfile };
          }
          if (action.payload.response.updateMainPerson) {
            tempObj = {
              ...tempObj,
              mainPersonDetails: action.payload.response.updateMainPerson,
            };
          }
        }
        if (tempObj?.role === "professional") {
          tempObj = { ...tempObj, ...action.payload.response };
        }
        state.me = tempObj;
      })
      .addCase(GetExperienceWebAction.pending, (state) => {
        state.experiencesLoading = true;
        state.totalExperience = 0;
        state.experiences = [];
      })
      .addCase(GetExperienceWebAction.fulfilled, (state, action) => {
        state.totalExperience = action.payload.response.totalYearOfExperience;
        state.experiencesLoading = false;
        state.experiences = action.payload.response.experiences;
      })
      .addCase(GetExperienceWebAction.rejected, (state) => {
        state.totalExperience = 0;
        state.experiencesLoading = false;
        state.experiences = [];
      })
      .addCase(AddExperienceAction.fulfilled, (state, action) => {
        state.experiencesLoading = false;
        let tempArray = [action.payload.response, ...state.experiences];
        let newTotal = 0;
        tempArray.forEach((item) => {
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
        });
        state.totalExperience = newTotal;
        state.experiences = tempArray;
      })
      .addCase(EditExperienceAction.fulfilled, (state, action) => {
        state.experiencesLoading = false;
        let newTotal = 0;
        state.experiences = state.experiences.map((item) => {
          if (item.id === action.payload.response.id) {
            newTotal += Math.abs(
              dayjs(action.payload.response.startDate).diff(
                dayjs(action.payload.response.endDate),
                "years"
              )
            );
            return { ...item, ...action.payload.response };
          }
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
          return { ...item };
        });
        state.totalExperience = newTotal;
      })
      .addCase(DeleteExperienceAction.fulfilled, (state, action) => {
        console.log(action.payload, "test");
        state.experiencesLoading = false;
        let newTotal = 0;
        let tempArray = state.experiences?.filter(
          (item) => item.id !== action.payload.id
        );
        tempArray?.forEach((item) => {
          newTotal += Math.abs(
            dayjs(item.startDate).diff(dayjs(item.endDate), "years")
          );
        });
        state.experiences = tempArray;
        state.totalExperience = newTotal;
      })
      .addCase(GetAddressForWebAction.pending, (state) => {
        state.addressLoading = true;
        state.address = [];
      })
      .addCase(GetAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = action.payload.response;
      })
      .addCase(GetAddressForWebAction.rejected, (state) => {
        state.addressLoading = false;
        state.address = [];
      })
      .addCase(AddAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = [action.payload.response, ...state.address];
      })
      .addCase(EditAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = state.address.map((item) => {
          if (item.id === action.payload.response.id) {
            return { ...item, ...action.payload.response };
          }
          return { ...item };
        });
      })
      .addCase(SetAddressDefault.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = state.address.map((item) => {
          if (item.id === action.payload.id) {
            return { ...item, isDefault: true };
          }
          return { ...item, isDefault: false };
        });
      })
      .addCase(UpdateMileageAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = { ...state.me, mileage: action.payload.response.mileage };
      })
      .addCase(DeleteAccountAction.fulfilled, (state) => {
        state.meLoading = false;
        LocalstorageService.removeFromLocalStorage("aboutcare-web");
        state.me = null;
        window.location = "/";
      })
      .addCase(DeleteAddressForWebAction.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.address = state.address.filter(
          (item) => item?.id !== action.payload.id
        );
      })
      .addCase(GetNotificationSettings.pending, (state) => {
        state.notificationsLoading = true;
        state.notifications = null;
      })
      .addCase(GetNotificationSettings.fulfilled, (state, action) => {
        state.notificationsLoading = false;
        state.notifications = action.payload.response;
      })
      .addCase(GetNotificationSettings.rejected, (state) => {
        state.notificationsLoading = false;
        state.notifications = null;
      })
      .addCase(UpdateNotificationSettings.fulfilled, (state, action) => {
        state.addressLoading = false;
        state.notifications = { ...state.notifications, ...action.payload };
      })
      .addCase(UpdateMeAction.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        let { me } = state;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
      })
      .addCase(GetHolidaysWebAction.pending, (state) => {
        state.holidaysLoading = true;
        state.holidays = [];
      })
      .addCase(GetHolidaysWebAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = action.payload.response;
      })
      .addCase(GetHolidaysWebAction.rejected, (state) => {
        state.holidaysLoading = false;
        state.holidays = [];
      })
      .addCase(AddHolidaysAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = [action.payload.response, ...state.holidays];
      })
      .addCase(EditHolidaysAction.fulfilled, (state, action) => {
        state.holidaysLoading = false;
        state.holidays = state.holidays.map((item) => {
          if (item.id === action.payload.response.id) {
            return { ...item, ...action.payload.response };
          }
          return { ...item };
        });
      })
      .addCase(DeleteHolidaysAction.fulfilled, (state, action) => {
        let tempArray = state.holidays?.filter(
          (item) => item.id !== action.payload.id
        );
        state.holidays = tempArray;
      });
  },
});

export default meSlice.reducer;
