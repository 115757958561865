import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";

const IncidentReportsFilter = () => {
  const { handleClose } = useContext(FilterPopoverContext);
  // const { access } = useSelector((state) => state.access);

  const intitalVal = {
    visiblity: "",
    accessName: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];

  const IncidentReportsFilterSchema = Yup.object().shape({
    visiblity: Yup.string().optional(),
    accessName: Yup.string().optional(),
  });

  const handleSubmit = (values) => {
    console.log(values, "values");
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: IncidentReportsFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        formik.resetForm()
        handleClose();
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"visiblity"}
          value={formik.values.visiblity}
          error={formik.errors.visiblity && formik.touched.visiblity}
          errorMsg={formik.errors.visiblity}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "visiblity",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        {/* <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Access"}
          isRequired={false}
          options={access?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
          placeholder="Select Access"
          name={"accessName"}
          value={formik?.values?.accessName}
          error={formik.errors.accessName && formik.touched.accessName}
          errorMsg={formik.errors.accessName}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "accessName",
              selectedOption ? selectedOption.value : ""
            );
          }}
        /> */}
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default IncidentReportsFilter;
