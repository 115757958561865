import { Typography } from "@mui/material";
import { CustomLoader, TableContainer } from "components/atoms";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSubscriptionHospitalAction } from "store/middlewares/users/hospitals";

const HospitalSubscriptionTab = ({ id }) => {
  const { Subscription, getSubscriptionLoading } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(GetSubscriptionHospitalAction({ id }));
    }
  }, [dispatch, id]);


  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Plan Title",
        accessorKey: "plan.planTitle",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Plan Type",
        accessorKey: "plan.planType",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Plan Price",
        accessorKey: "plan.price",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Plan Duration",
        accessorKey: "plan.duration",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Start Date",
        accessorKey: "startDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const date = new Date(getValue());
          return date.toLocaleDateString("en-GB"); // Format as DD/MM/YYYY
        },
      },
      {
        header: "End Date",
        accessorKey: "endDate",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const date = new Date(getValue());
          return date.toLocaleDateString("en-GB"); // Format as DD/MM/YYYY
        },
      },
    ],
    []
  );
  return (
    <div className="Hospital-User-page h-full w-full">
      {!getSubscriptionLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={Subscription}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </div>
  );
};

export default HospitalSubscriptionTab;
