import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    background: {
      // Dark blue background
      dark: "#0CA8DF",
      // White background
      main: "#E8F0F3",
      // Light blue background
      light: "#41BCE8",
      // Black background
      contrastText: "#2D2635",
    },
    button: {
      dark: "#0A1E41",
      main: "#0CA8DF",
      light: "#817D86",
      contrastText: "#CCEEFA",
    },
    error: {
      main: "#FF3636",
      light: "#FF575A",
      dark: "#FF4A4D",
    },
    successButton: {
      main: "#00A838",
      dark: "#07B67C",
      light: "#C0F8E5",
    },
    statusPill: {
      light: "#FFDFC3",
      main: "#007AFF26",
    },
    lightButton: {
      main: "#CCEEFA",
    },
    darkButton: {
      main: "#000000",
      light: "#FFFFFF",
    },
    reviewBackground: {
      main: "#EEA617",
      light: "#FFDE85",
      dark: "#FFBB3C",
      contrastText: "#FFF7E7",
    },
    input: {
      dark: "#33691E",
      main: "#8BC34A",
      light: "#AED581",
      contrastText: "#fff",
    },
    inputBorder: {
      main: "#ABA8AE",
    },
    text: {
      dark: "#0A1E41",
      main: "#000000",
      light: "#FFFFFF",
      contrastText: "#ABA8AE",
      blue: "#0CA8DF",
    },
    sliderText: {
      main: "#000000",
      light: "#817D86",
    },
  },
  typography: {
    fontFamily: "Proxima Nova",
    fontWeightBold: 600,
    fontWeightLight: 400,
    h1: {
      fontSize: 36,
      fontWeight: 600,
    },
    h2: {
      fontSize: 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 28,
    },
    h4: {
      fontSize: 26,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 21,
    },
    subtitle1: {
      fontSize: 18,
    },
    baseFont: {
      fontSize: 16,
    },
    errorMsg: {
      fontSize: 14,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
          },
          "&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button":
            {
              WebkitAppearance: "none", // Chrome, Safari, Edge
              margin: 0,
            },
        },
        root: {
          "& .MuiInputBase-input": {
            marginLeft: "8px",
          },
          variants: [
            {
              props: { variant: "basic" },
              style: {
                borderRadius: "12px",
                marginTop: "8px",
                background: "#fff",
              },
            },
            {
              props: { variant: "otpField" },
              style: {
                border: "none !important",
                outline: "none !important",
                width: "45px",
                height: "45px",
                borderRadius: "9px",
                textAlign: "center",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  marginLeft: "0px",
                },
                "& .MuiOutlinedInput-input": {
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 600,
                },
                "& .MuiInputBase-root": {
                  backgroundColor: "#FFF !important",
                },
              },
            },
            {
              props: { variant: "plain" },
              style: {
                borderRadius: "12px",
                width: "100%",
                backgroundColor: "#FFF",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              },
            },
          ],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          marginTop: "8px",
          borderRadius: "12px !important", // fallback border radius if no variant is matched
          "&[data-variant='basic']": {
            borderRadius: "12px",
          },
          "&[data-variant='plain']": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #000000",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #1976d2",
            },
            borderRadius: "5px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          variants: [
            {
              props: { variant: "lightButton" },
              style: {
                width: "100%",
                backgroundColor: "#0CA8DF",
                color: "#FFF",
                borderRadius: "12px",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#225f75",
                },
              },
            },
            {
              props: { variant: "newsLetterButton" },
              style: {
                width: "100%",
                backgroundColor: "#0CA8DF",
                color: "#FFF",
                borderRadius: "12px",
                paddingTop: "10px",
                paddingBottom: "10px",
                "&:hover": {
                  backgroundColor: "#225f75",
                },
              },
            },
            {
              props: { variant: "darkButton" },
              style: {
                width: "100%",
                backgroundColor: "#0A1E41",
                color: "#FFF",
                borderRadius: "12px",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#1d3050",
                },
              },
            },
          ],
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&.MuiTextField-root": {
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
            },
          },
        },
      },
    },
  },
});
