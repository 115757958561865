import axiosClient from "utils/api";
const URI = "/admin/city";

export const getCities = async () => {
  const URL = `${URI}/city`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All cities:", error);
    throw error;
  }
};

export const deleteCity = async (payload) => {
  const URL = `${URI}/city/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete city:", error);
    throw error;
  }
};

export const updateCity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/city/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update city:", error);
    throw error;
  }
};

export const updateVisiblityCity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Visiblity city:", error);
    throw error;
  }
};


export const addCity = async (payload) => {
  const URL = `${URI}/city`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add city:", error);
    throw error;
  }
};
