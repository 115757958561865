import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { setFaqFilterProps } from "store/slices/admin/helpAndSupportManagement";

const PlanFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { plansFilterProps } = useSelector(
    (state) => state.subscriptionManagement
  );

  const intitalVal = {
    isVisible: "",
    type: "all",
    // min: "",
    // max: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];
  const typeOptions = [
    { label: "ALL", value: "all" },
    { label: "Hospital", value: "hospital" },
    { label: "Professional", value: "professional" },
  ];

  const FaqFilterSchema = Yup.object().shape({
    isVisible: Yup.string().nullable(),
    planType: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setFaqFilterProps({
        ...values,
        isVisible: values.isVisible === "" ? null : values.isVisible,
        planType: values.planType === "" ? null : values.planType,
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...plansFilterProps },
    validationSchema: FaqFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setFaqFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Plan Type"}
          isRequired={false}
          options={typeOptions}
          placeholder="Select plan type"
          name={"type"}
          value={formik.values.type}
          error={formik.errors.type && formik.touched.type}
          errorMsg={formik.errors.type}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "type",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <Grid container spacing={1} className="">
          <Grid item md={6} xs={12}>
            <Input
              inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
              label={"Min"}
              type="number"
              name="min"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.min && formik.touched.min}
              errorMsg={formik.errors.min}
              value={formik.values.min}
              placeholder="Enter min"
              isRequired={false}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
              label={"Max"}
              type="number"
              name="max"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.max && formik.touched.max}
              errorMsg={formik.errors.max}
              value={formik.values.max}
              placeholder="Enter max"
              isRequired={false}
            />
          </Grid>
        </Grid>

        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default PlanFilter;
