import React from "react";
import { MuiBox, MuiTypography } from "components/atoms";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

import Eye from "assets/images/SVG/Eye blue.svg";
const EarningCard = () => {
  return (
    <MuiBox
      component={Link}
      to="/professional/earning-details"
      sx={{
        p: { xs: "15px 10px", sm: 2 },
        bgcolor: "#fff",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Facility
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            General Hospital
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Approved on
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            03/02/2023
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Shift ID
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            XYZ
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Shift Date and Hours
          </MuiTypography>
        </MuiBox>
        <MuiBox
          sx={{
            width: "fit-content",
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <MuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <MuiTypography
              variant="baseFont"
              fontWeight="bold"
              sx={{
                display: "flex",
                gap: "6px",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color={"#000000"}
            >
              <span>07/03/2024</span>
              <span>To</span>
              <span>07/07/2024</span>
            </MuiTypography>
            <MuiTypography
              variant="baseFont"
              fontWeight="bold"
              sx={{
                display: "flex",
                gap: "6px",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color={"#000000"}
            >
              <span>07:00 AM</span>
              <span>To</span>
              <span>12:00 AM</span>
            </MuiTypography>
          </MuiBox>
          <MuiBox sx={{ width: "fit-content" }}>
            <IconButton
              sx={{ bgcolor: "#E8F0F3", borderRadius: "6px" }}
              //   onClick={handleScheduleBtn}
            >
              <img src={Eye} />
            </IconButton>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Status
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography
            variant="baseFont"
            fontWeight="bold"
            color="#fff"
            sx={{
              bgcolor: "#FF686B",
              p: "4px 15px",
              borderRadius: "13px",
            }}
          >
            Unpaid
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Hours Worked
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            12.0
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Rate
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            $20.00/hr
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Deductions
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            $58.31
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" color="#817D86">
            Earnings
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: "fit-content" }}>
          <MuiTypography variant="baseFont" fontWeight="bold" color={"#000000"}>
            $181.69
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default EarningCard;
