import React from "react";
import MuiBox from "../MuiBox";
import { useSelector } from "react-redux";
import blankImg from "assets/images/blank.png";
import MuiTypography from "../MuiTypography";
import dayjs from "dayjs";

const AdminMessageItemWeb = ({ chatSelected, item }) => {
  let date = dayjs(item?.createdAt).format("HH:mm A");

  const {
    helpAndSupport: { chatList },
  } = useSelector((store) => store);

  return (
    <MuiBox className="web-sender-msg-wrapper">
      <MuiBox className="web-msg-profile-pic">
        <img
          src={
            chatList?.find((user) => user?.id === chatSelected)
              ?.adminProfileUrl ?? blankImg
          }
          className="img-cover"
        />
      </MuiBox>
      <MuiBox className="web-msg-box">
        <MuiBox className="msg-content">
          <MuiTypography fontSize="14px" className="msg-text">
            {item.message}
          </MuiTypography>
        </MuiBox>
        <MuiBox className="msg-time">
          <MuiTypography fontSize="14px">{date}</MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default AdminMessageItemWeb;
