import React from "react";

// Button For Adding Items
const AddButton = ({ onClick, title, style }) => {
  return (
    <button
      onClick={onClick}
      className={`text-white btn px-3 ${style} bg-[#FF5C5C] hover:text-white hover:bg-[#ff4444] p-2 rounded-lg`}
    >
      {title}
    </button>
  );
};

export default AddButton;
