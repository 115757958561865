import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CustomDatePicker,
  MuiBox,
  MuiTypography,
  Textfield,
} from "components/atoms";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  AddHolidaysAction,
  EditHolidaysAction,
} from "store/middlewares/holidays";
import { reason } from "utils/validation/web/HolidayForm";
import { endDate, startDate } from "utils/validation/web/ExperienceForm";
import { useFormik } from "formik";
import dayjs from "dayjs";

const AddHolidaysModal = ({ holiday }) => {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    let tempObj = {
      ...values,
      startDate: dayjs(values?.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(values?.endDate).format("YYYY-MM-DD"),
    };
    if (holiday) {
      dispatch(EditHolidaysAction({ id: holiday?.id, ...tempObj }));
    } else {
      dispatch(AddHolidaysAction(tempObj));
    }
  };

  const AddExperienceSchema = Yup.object().shape({
    reason: reason,
    startDate: startDate,
    endDate: endDate,
  });

  const formik = useFormik({
    initialValues: {
      startDate: holiday ? holiday?.startDate : null,
      endDate: holiday ? holiday?.endDate : null,
      reason: holiday ? holiday?.reason : "",
    },
    validationSchema: AddExperienceSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          p: { xs: "10px", sm: "20px", md: "30px" },
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <MuiTypography
          variant="h6"
          fontWeight={"bold"}
          align={"center"}
          sx={{ mb: 4 }}
        >
          {holiday ? "Edit Holidays" : "Add Holidays"}
        </MuiTypography>

        <Grid container spacing={3}>
          <Grid xs={6}>
            <CustomDatePicker
              label={"Start Date"}
              maxDate={
                formik.values?.endDate ? dayjs(formik.values?.endDate) : null
              }
              disablePast={true}
              onChange={(e) => formik.setFieldValue("startDate", dayjs(e))}
              error={formik.touched.startDate && formik.errors.startDate}
              value={
                formik.values?.startDate
                  ? dayjs(formik.values?.startDate)
                  : null
              }
              errorMsg={formik.errors.startDate}
            />
          </Grid>
          <Grid xs={6}>
            <CustomDatePicker
              label={"End Date"}
              disablePast={true}
              minDate={
                formik.values?.startDate && dayjs(formik.values?.startDate)
              }
              onChange={(e) => {
                formik.setFieldValue("endDate", dayjs(e));
              }}
              value={
                formik.values?.endDate ? dayjs(formik.values?.endDate) : null
              }
              error={formik.touched.endDate && formik.errors.endDate}
              errorMsg={formik.errors.endDate}
            />
          </Grid>
        </Grid>

        <Textfield
          label={"Notes"}
          required={true}
          bgcolor="#fff"
          name={"reason"}
          rows={5}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values?.reason}
          errorMsg={formik.errors.reason}
          error={formik.touched.reason && formik.errors.reason}
        />

        <Button type="submit" variant="lightButton">
          {holiday ? "Update" : "Add"}
        </Button>
      </MuiBox>
    </form>
  );
};

export default AddHolidaysModal;
