import { AuthLayout } from "components/molecules";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "utils/ScrollToTop";

// Pages
const Login = lazy(() => import("pages/Web/Login"));
const SignUp = lazy(() => import("pages/Web/SignUp"));
const ResetPassword = lazy(() => import("pages/Web/ResetPassword"));
const ProfessionalSignUp = lazy(() => import("pages/Web/ProfessionalSignUp"));
const OTP = lazy(() => import("pages/Web/otp"));
const Subscription = lazy(() => import("pages/Web/Subscription"));
const AdminLogin = lazy(() => import("pages/Login/Login"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};
const adminRoutes = [
  {
    path: generatePath("admin"),
    element: <AdminLogin />,
  },
  {
    path: generatePath(""),
    element: <Login />,
    hasSlider: false,
  },
  {
    path: generatePath("signup"),
    element: <SignUp />,
  },
  {
    path: generatePath("otp"),
    element: <OTP />,
  },
  {
    path: generatePath("reset-password"),
    element: <ResetPassword />,
  },
  {
    path: generatePath("professional-sign-up"),
    element: <ProfessionalSignUp />,
  },
  {
    path: generatePath("subscription"),
    element: <Subscription />,
  },
];
const authNavigation = () => {
  // const { pathname } = useLocation();

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        {/* <Route
                path=""
                element={!user || !isTokenValid ? <Login /> : <Navigate to="dashboard" />}
            /> */}
        {/* if user is not present show unauthorized ui */}
        <Route element={<AuthLayout />}>
          {adminRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
        </Route>
        {/* <Route element={<SubscriptionLayout />}> */}
        {/* <Route path={"subscription"} element={<Subscription />} /> */}
        {/* </Route> */}
      </Routes>
    </>
  );
};

export default authNavigation;
