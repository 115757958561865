import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import Professional from "assets/images/PNG/professional.png";
import Beg from "assets/images/SVG/beg.svg";
import Star from "assets/images/SVG/Star.svg";
import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import PhoneIcon from "assets/images/SVG/Smartphone.svg";
const ProfessionalCard = ({
  description = true,
  to = "/hospital/direct-hire/details",
}) => {
  return (
    <MuiBox
      component={Link}
      to={to}
      sx={{
        background: "#fff",
        borderRadius: "12px",
        p: { xs: 1, sm: 2 },
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <MuiBox sx={{ borderRadius: "9px", overflow: "hidden", height: "206px" }}>
        <img
          src={Professional}
          alt="professional"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </MuiBox>
      <MuiBox>
        <MuiTypography color="text.main" variant="subtitle1" fontWeight="bold">
          Madelyn Lipshutz
        </MuiTypography>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          <MuiTypography
            color="text.contrastText"
            sx={{ fontSize: "14px", width: "6em", overflow: "hidden" }}
          >
            Renee Dunn
          </MuiTypography>
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <MuiBox
                sx={{
                  width: "26px",
                  height: "26px",
                  borderRadius: "6px",
                  bgcolor: "#E8F0F3",
                  p: "5px",
                }}
              >
                <img
                  src={Beg}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.main"
                fontWeight="bold"
                sx={{ fontSize: "14px" }}
              >
                2 years
              </MuiTypography>
            </MuiBox>
            <MuiBox
              sx={{
                display: "flex",
                width: "fit-content",
                alignItems: "center",
                gap: "5px",
                bgcolor: "#FFF7E7",
                borderRadius: "6px",
                p: "5px",
              }}
            >
              <MuiBox sx={{ width: "18px", height: "18px" }}>
                <img
                  src={Star}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </MuiBox>
              <MuiTypography
                color="text.contrastText"
                sx={{ fontSize: "14px" }}
              >
                4.5 (111)
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      {description && (
        <MuiBox>
          <MuiTypography fontSize="14px" lineHeight="20px" color="text.dark">
            Lorem ipsum dolor sit amet consectetur. Cras adipiscing faucibus
            faucibus Varius faucibus nunc dignissim quisque tortor.
          </MuiTypography>
        </MuiBox>
      )}

      <MuiBox
        sx={{
          bgcolor: "#CCEEFA",
          borderRadius: "12px",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <MuiBox sx={{ display: "flex", gap: 2, width: "fit-content" }}>
          <IconButton
            disableRipple
            aria-label="phone"
            sx={{
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={PhoneIcon} />
          </IconButton>
          <IconButton
            disableRipple
            aria-label="phone"
            sx={{
              bgcolor: "darkButton.main",
              transition: "all 0.3s",
              borderRadius: "6px",
              overflow: "hidden",
              "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
            }}
          >
            <img src={ChatIcon} />
          </IconButton>
        </MuiBox>
        <MuiBox
          sx={{
            width: "fit-content",
            bgcolor: "#FFFFFF",
            p: "4px 6px",
            borderRadius: "12px",
          }}
        >
          <MuiTypography
            color="text.dark"
            fontWeight="bold"
            sx={{ fontSize: "14px" }}
          >
            30+ Miles
          </MuiTypography>
        </MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default ProfessionalCard;
