import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { MuiBox, MuiTypography } from "components/atoms";
import { Link } from "react-router-dom";

import Eye from "assets/images/SVG/Eye blue.svg";
import Calender from "assets/images/SVG/CalendarBlue.svg";
import EditIcon from "assets/images/SVG/delete.svg";
import Clock from "assets/images/SVG/ClockBlue.svg";
import { openDrawer } from "store/slices/common/drawer.slice";

import { useDispatch } from "react-redux";
import SuccessModal from "../SuccessModal";
const HireMeModal = () => {
  const dispatch = useDispatch();
  const costCutting = [
    { title: "Federal Income Taxes", charge: "-$24.00" },
    { title: "State Income Taxes", charge: "-$24.00" },
    { title: "Social Security Taxes", charge: "-$24.00" },
    { title: "Medicare Taxes", charge: "-$24.00" },
    { title: "401(k)", charge: "-$24.00" },
    { title: "Workers’ Compensation", charge: "-$24.00" },
    { title: "General Liability", charge: "-$24.00" },
    { title: "Wisely Paycard Processing", charge: "-$24.00" },
    { title: "Background and Drug Testing", charge: "-$24.00" },
    { title: "Agency Fee", charge: "-$24.00" },
  ];

  const [scheduleOpen, setScheduleOpen] = useState(false);
  const handleScheduleBtn = () => setScheduleOpen(!scheduleOpen);
  return (
    <MuiBox
      sx={{
        px: { xs: "10px", sm: "20px", md: "30px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Hire Me
      </MuiTypography>

      <MuiBox
        sx={{
          p: { xs: "15px 10px", sm: 2 },
          bgcolor: "#fff",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="baseFont" color="#817D86">
              Shift ID
            </MuiTypography>
          </MuiBox>
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="baseFont" fontWeight="bold">
              XYZ
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="baseFont" color="#817D86">
              Shift Date and Hours
            </MuiTypography>
          </MuiBox>
          <MuiBox
            sx={{
              width: "fit-content",
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <MuiBox
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <MuiTypography
                variant="baseFont"
                fontWeight="bold"
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                }}
              >
                <span>07/03/2024</span>
                <span>To</span>
                <span>07/07/2024</span>
              </MuiTypography>
              <MuiTypography
                variant="baseFont"
                fontWeight="bold"
                sx={{
                  display: "flex",
                  gap: "6px",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                }}
              >
                <span>07:00 AM</span>
                <span>To</span>
                <span>12:00 AM</span>
              </MuiTypography>
            </MuiBox>
            <MuiBox sx={{ width: "fit-content" }}>
              <IconButton
                sx={{ bgcolor: "#E8F0F3", borderRadius: "6px" }}
                onClick={handleScheduleBtn}
              >
                <img src={Eye} />
              </IconButton>
            </MuiBox>
          </MuiBox>
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="baseFont" color="#817D86">
              Rate
            </MuiTypography>
          </MuiBox>
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="baseFont" fontWeight="bold">
              $20.00/hr
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      {scheduleOpen && (
        <MuiBox
          sx={{
            borderRadius: "12px",
            bgcolor: "#fff",
            p: { xs: "15px 10px", sm: 2 },
            mt: 2,
          }}
        >
          <MuiTypography variant="subtitle1">
            Schedule Date & Time
          </MuiTypography>
          <MuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              height: "23em",
              overflowY: "auto",
              pr: 1,
            }}
          >
            {Array.from({ length: 5 }).map((_, index) => (
              <MuiBox
                key={index}
                sx={{
                  border: "1px solid #D5D4D7",
                  borderRadius: "12px",
                  p: { xs: 1, sm: 2 },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <MuiBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap-reverse",
                    gap: 2,
                  }}
                >
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <img src={Calender} />
                    <MuiTypography
                      fontSize="14px"
                      fontWeight="bold"
                      sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                    >
                      <span>07/03/2024</span>
                      <span>To</span>
                      <span>07/05/2024</span>
                    </MuiTypography>
                  </MuiBox>
                  <IconButton
                    component={Link}
                    aria-label="edit"
                    sx={{
                      bgcolor: "#0A1E41",
                      transition: "all 0.3s",
                      borderRadius: "6px",
                      overflow: "hidden",
                      "&:hover": { bgcolor: "#112c5abb", borderRadius: "50%" },
                    }}
                  >
                    <img src={EditIcon} />
                  </IconButton>
                </MuiBox>
                <MuiBox
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    mt: "15px",
                  }}
                >
                  <img src={Clock} />
                  <MuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                  >
                    <span>07:00 AM</span>
                    <span>To</span>
                    <span>12:00 AM</span>
                  </MuiTypography>
                </MuiBox>
                <MuiBox
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    mt: "15px",
                  }}
                >
                  <img src={Clock} />
                  <MuiTypography
                    fontSize="14px"
                    fontWeight="bold"
                    sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                  >
                    <span>07:00 AM</span>
                    <span>To</span>
                    <span>12:00 AM</span>
                  </MuiTypography>
                </MuiBox>
              </MuiBox>
            ))}
          </MuiBox>
        </MuiBox>
      )}

      <MuiBox sx={{ bgcolor: "#fff", borderRadius: "12px" }}>
        <MuiBox
          sx={{
            background:
              "linear-gradient(91.68deg, #0CA8DF 7.75%, #413BE3 107.71%)",
            p: { xs: "15px 10px", sm: "20px 26px" },
            borderRadius: "12px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MuiTypography variant="h6" fontWeight={"bold"} color="#fff">
            Total Spend hours
          </MuiTypography>
          <MuiBox sx={{ width: "fit-content" }}>
            <MuiTypography variant="h6" fontWeight={"bold"} color="#fff">
              42/hrs
            </MuiTypography>
            <MuiTypography fontSize="14px" color="#fff">
              $20.00/hr
            </MuiTypography>
          </MuiBox>
        </MuiBox>
        <MuiBox
          sx={{
            p: { xs: "13px 10px", sm: "20px 26px" },
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {costCutting.map((item, index) => (
            <MuiBox
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont">{item.title}</MuiTypography>
              </MuiBox>
              <MuiBox sx={{ width: "fit-content" }}>
                <MuiTypography variant="baseFont" fontWeight="bold">
                  {item.charge}
                </MuiTypography>
              </MuiBox>
            </MuiBox>
          ))}
        </MuiBox>
        <MuiBox
          sx={{
            background: "#0CA8DF",
            p: { xs: "13px 10px", sm: "20px 26px" },
            borderRadius: "12px",
          }}
        >
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <MuiBox sx={{ width: "fit-content" }}>
              <MuiTypography variant="subtitle1" color="#fff">
                Total Deductions
              </MuiTypography>
            </MuiBox>
            <MuiBox sx={{ width: "fit-content" }}>
              <MuiTypography variant="subtitle1" fontWeight="bold" color="#fff">
                $84.11
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <MuiBox sx={{ width: "fit-content" }}>
              <MuiTypography variant="subtitle1" color="#fff">
                Estimated Net Pay
              </MuiTypography>
            </MuiBox>
            <MuiBox sx={{ width: "fit-content" }}>
              <MuiTypography variant="subtitle1" fontWeight="bold" color="#fff">
                $352.75
              </MuiTypography>
            </MuiBox>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox sx={{ textAlign: "center", mt: "48px" }}>
        <Button
          variant="lightButton"
          sx={{ maxWidth: "350px" }}
          onClick={() =>
            dispatch(
              openDrawer({
                content: (
                  <SuccessModal
                    tempRedirect={true}
                    btnText="View Map"
                    title={"Congratulations"}
                    message={
                      <>
                        Your Hire request has been send <br /> successfully to{" "}
                        <strong>Aspen Press.</strong>
                      </>
                    }
                  />
                ),
              })
            )
          }
        >
          Pay Now
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default HireMeModal;
