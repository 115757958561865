import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { AddDynamicMenuAction } from "store/middlewares/dynamicMenu";
import { menuName } from "utils/validation/AddDynamicMenu";

function ModalAddDynamicMenu() {
  const dispatch = useDispatch();

  const intitalVal = {
    name: "",
  };

  const AddDynamicMenuSchema = Yup.object({  name: menuName });

  const handleSubmit = (values) => {
    dispatch(AddDynamicMenuAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddDynamicMenuSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Menu Name"}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Menu Name"
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddDynamicMenu;
