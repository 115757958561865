import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

const SwitchComponent = ({
  labelText,
  checked,
  onChange,
  onBlur,
  disabled = false,
  name,
  place,
  labelClassName,
  className,
  ...rest
}) => {
  return (
    <FormControlLabel
      value="top"
      sx={{ margin: "0 !important" }}
      control={
        <Switch
          checked={checked}
          name={name}
          disabled={disabled}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          {...rest}
        />
      }
      className={`${className}`}
      label={
        <label htmlFor={name} className={`font-medium text-sm ${labelClassName}`}>
          {labelText}
        </label>
      }
      labelPlacement={place}
    />
  );
};

export default SwitchComponent;
