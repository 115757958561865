import { Button, FormControl, Slider } from '@mui/material'
import { CustomTimePicker, WebInput, MuiBox, MuiTypography, Select } from 'components/atoms'
import React, { useState } from 'react'

import HospitalIcon from 'assets/images/SVG/hospitalName blue.svg'
import Dollar from 'assets/images/SVG/dollar.svg'
import Grid from '@mui/material/Unstable_Grid2'

const CalenderFilterModal = () => {
  const [value, setValue] = useState(30);
  const handleSliderChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <MuiBox sx={{ p: { xs: '16px 10px', sm: '16px 32px' } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter
      </MuiTypography>
      <MuiBox
        component={'form'}
        sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <FormControl>
          <MuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <MuiTypography variant="baseFont" fontWeight={"bold"}>
              Radius
            </MuiTypography>
            <MuiBox sx={{ width: "fit-content", borderRadius: '6px', border: '1px solid #000000', p: '0 6px' }}>
              <MuiTypography sx={{ fontSize: '14px' }}>
                {value}+ Miles
              </MuiTypography>
            </MuiBox>
          </MuiBox>
          <MuiBox sx={{ bgcolor: '#fff', p: '12px 25px 6px 25px', borderRadius: '12px', mt: '8px' }}>
            <Slider
              className='distance-slider'
              min={5}
              step={5}
              onChange={handleSliderChange}
              // onChangeCommitted={handleSliderChangeCommitted}
              value={value}
              aria-labelledby="distance-slider"
            />
          </MuiBox>
        </FormControl>
        <MuiBox>
          <Select
            label={'Facility'}
            icon={<img src={HospitalIcon} />}
          />
        </MuiBox>
        <MuiBox>
          <CustomTimePicker label={'Time'} />
        </MuiBox>
        <Grid container spacing={2} >
          <Grid xs={12} sm={6}>
            <WebInput
              variant='plain'
              placeholder={'Min'}
              label={'Salary (min)'}
              icon={<img src={Dollar} alt="Location" />}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <WebInput
              variant='plain'
              placeholder={'Max'}
              label={'Salary (max)'}
              icon={<img src={Dollar} alt="Location" />}
            />
          </Grid>
        </Grid>
        <Button variant='lightButton'>
          Apply
        </Button>
      </MuiBox>
    </MuiBox>
  )
}

export default CalenderFilterModal