import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { SubmitBtn, InputTextarea, SelectComponent } from "components/atoms";
import { UpdateFaqAction } from "store/middlewares/HelpAndSupport";
import { Type, Answer, Question } from "utils/validation/AddFAQ";

function ModalEditFaq({ row }) {
  const dispatch = useDispatch();

  const EditJobTitleSchema = Yup.object({
    question: Question,
    answer: Answer,
    type: Type,
  });

  const handleSubmit = (values) => {
    dispatch(UpdateFaqAction({ ...values, id: row.id }));
  };

  const formik = useFormik({
    initialValues: {
      question: row?.question,
      answer: row?.answer,
      type: row?.type,
    },
    validationSchema: EditJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputTextarea
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar"
        label={"Question"}
        name="question"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.question && formik.touched.question}
        errorMsg={formik.errors.question}
        value={formik.values.question}
        placeholder="Enter Question"
      />
      <InputTextarea
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar"
        label={"Answer"}
        name="answer"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.answer && formik.touched.answer}
        errorMsg={formik.errors.answer}
        value={formik.values.answer}
        placeholder="Enter Answer"
      />
      <SelectComponent
        className={"px-0"}
        label={"Type"}
        options={[
          { label: "Hospital", value: "hospital" },
          { label: "Professional", value: "professional" },
        ]}
        placeholder="Select Type"
        name={"type"}
        value={formik.values.type}
        error={formik.errors.type && formik.touched.type}
        errorMsg={formik.errors.type}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "type",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditFaq;
