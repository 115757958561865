import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Dot, MapPinned } from "lucide-react";
import React from "react";
import EditIcon from "assets/images/editpenpaperblack.svg";
import StatusPill from "../StatusPill";
import { useNavigate } from "react-router-dom";

const AddressItem = ({ address }) => {
  const navigate = useNavigate();

  const editAddress = (AddId) => {
    navigate(`/admin/address/edit/${AddId}`);
  };

  return (
    <div className="card rounded-xl bg-[#eff6ff]">
      <div className="card-body break-all">
        <div className="flex items-center mb-4">
          {address?.isDefault && (
            <div className="flex-shrink-0">
              <StatusPill status={address?.isDefault} statusText={"Default"} />
            </div>
          )}
          <div className="w-full"></div>
          <div className="flex-shrink-0">
            <IconButton
              sx={{
                background: "#CCEEFA",
                p: "8px",
                borderRadius: "8px",
                width: "content-fit",
              }}
              onClick={() => editAddress(address.id)}
            >
              <img src={EditIcon} />
            </IconButton>
          </div>
        </div>

        <div className="flex items-start">
          <div>
            <MapPinned className="size-6 shrink-0 mr-3" />
          </div>
          <div>
            <h5 className="break-all text-16 font-medium">
              {[
                address.firstAddress,
                address.secondAddress,
                address.landmark,
                address.cityName,
                address.stateName,
                address.countryName,
                address.zipCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </h5>
          </div>
        </div>
        {address?.floorDetails?.length > 0 && (
          <div className="my-4">
            <h5 className="break-all text-16 font-semibold underline">
              Floor Details
            </h5>
            <List
              sx={{
                width: "100%",
                padding: "0 !important",
                rowGap: "20px",
              }}
              component="div"
            >
              {address?.floorDetails?.map((floor) => {
                return (
                  <ListItem
                    key={floor?.id}
                    sx={{
                      padding: "0",
                      marginTop: "1rem",
                    }}
                    alignItems="flex-start"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                        marginTop: "5px",
                      }}
                    >
                      <Dot className="text-vertical-bg text-20" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<h5>Floor Name: {floor?.name}</h5>}
                      secondary={
                        <>
                          {floor?.floorSpecialities?.length > 0 && (
                            <div className="flex items-center flex-wrap gap-x-2">
                              {floor?.floorSpecialities?.map((item) => {
                                return (
                                  <div
                                    key={item?.speciality}
                                    className={`flex items-center w-auto px-2.5 py-0.5 text-md rounded-xl capitalize font-medium rounded border border-vertical-menu-dark bg-vertical-menu-dark text-vertical-menu`}
                                  >
                                    {item?.specialityName}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
        {/* 
        <div className="flex justify-end">
          <IconButton
            sx={{
              background: "#CCEEFA",
              p: "8px",
              borderRadius: "8px",
              width: "content-fit",
            }}
            onClick={() => editAddress(address.id)}
          >
            <img src={EditIcon} />
          </IconButton>
        </div> */}
      </div>
    </div>
  );
};

export default AddressItem;
