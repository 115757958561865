import { WarningRounded } from "@mui/icons-material";
import { Box, OutlinedInput } from "@mui/material";
import React from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
// import { Link } from 'react-router-dom';
// import { languages } from 'utils/languages';

const OtpFields = ({
  handleSubmit,
  inputRefs,
  length,
  error = false,
  errorMsg = "",
}) => {
  const handleChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, "");

    e.target.value = value;

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      //focus on the next one when a digit is entered
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      // focus on the previous one when a digit is removed
      inputRefs.current[index - 1].focus();
    }
  };

  // --------------- resend countdown timer ---------------
  // const handleResendOTP = () => {
  //     if (timeLeft === 0) {
  //         setTimeLeft(initialTime);
  //     }
  // };

  return (
    <Box
      sx={{
        backgroundColor: "background.main",
      }}
    >
      <form onSubmit={handleSubmit}>
        <div className="flex gap-3 otp-input">
          {[...Array(length)].map((_, index) => (
            <OutlinedInput
              key={index}
              fullWidth
              inputRef={(el) => (inputRefs.current[index] = el)}
              placeholder="0"
              variant="otpField"
              error={error}
              name={`otp-${index}`}
              inputProps={{ maxLength: 1, type: "text", pattern: "[0-9]*" }}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
        </div>
        {error && (
          <MuiBox sx={{ marginTop: "8px" }}>
            <WarningRounded color="error" size={20} />
            <MuiTypography
              component={"span"}
              sx={{ marginLeft: "5px" }}
              variant="error"
              color="error"
            >
              {errorMsg}
            </MuiTypography>
          </MuiBox>
        )}
      </form>
    </Box>
  );
};

export default OtpFields;
