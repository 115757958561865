import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDynamicMenu,addDynamicMenu,deleteDynamicMenu,updateDynamicMenu } from "store/services/dynamicMenu";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

export const GetAllDynamicMenu = createAsyncThunk(
  "dynamicMenu/getAllDynamicMenu",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getDynamicMenu();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteDynamicMenuAction = createAsyncThunk(
  "dynamicMenu/deleteDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteDynamicMenu(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({...res, ...payload});
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateDynamicMenuAction = createAsyncThunk(
  "dynamicMenu/updateDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateDynamicMenu(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddDynamicMenuAction = createAsyncThunk(
  "dynamicMenu/addDynamicMenu",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addDynamicMenu(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// export const UpdateVisiblitySpecialityAction = createAsyncThunk(
//   "dynamicMenu/updateSpecialityVisiblityAction",
//   async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
//     try {
//       const res = await updateVisiblitySpeciality(payload);
//       if (res.status === 1) {
//         // if any async operation need to be done on response data, do it here,
//         // then full fill data
//         return fulfillWithValue({...res, ...payload});
//       }
//       dispatch(openToast({ type: "error", message: res?.message }));
//       return rejectWithValue(res);
//     } catch (error) {
//       dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
//       handleAuthError(error?.response?.status);
//       return rejectWithValue(error);
//     }
//   }
// );
