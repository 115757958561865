import React from "react";

const SubmitBtn = ({ title, className, type = "submit", buttonClassName ,onClickFunc }) => {
  return (
    <div className={`py-2 text-end ${className}`}>
      <button
        type={type}
        className={`text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 ml-3 rounded-lg ${buttonClassName}`}
        onClick={onClickFunc ?  onClickFunc  : undefined}
      >
        {title}
      </button>
    </div>
  );
};

export default SubmitBtn;
