import { MuiBox, MuiTypography, SearchInput } from "components/atoms";
import React, { Fragment, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InfoIcon from "assets/images/SVG/info.svg";
const FloorsModal = () => {
  const titles = [
    { title: "Telemetry", isInfo: false },
    { title: "ICU", isInfo: false },
    { title: "Labor and Delivery", isInfo: true },
    { title: "Med Surg", isInfo: true },
    { title: "ER", isInfo: true },
    { title: "Step Down", isInfo: true },
    { title: "Radiology", isInfo: true },
    { title: "Imagery", isInfo: true },
    { title: "Blood Bank", isInfo: true },
    { title: "Labs", isInfo: true },
    { title: "Pysch", isInfo: true },
  ];
  const [listStyle, setListStyle] = useState(titles[0]);

  const handleToggleStyle = (title) => {
    setListStyle(title);
  };

  return (
    <MuiBox sx={{ px: "30px" }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Floors
      </MuiTypography>
      <SearchInput
        type="text"
        variant="plain"
        name={"search"}
        placeholder="Search here..."
      />
      <MuiBox sx={{ mt: 2 }}>
        <List>
          {titles?.map((item, index) => {
            return (
              <Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 0 }}
                    onClick={() => handleToggleStyle(item.title)}
                  >
                    <ListItemText
                      primary={item.title}
                      sx={{
                        flexGrow: 0,
                        color:
                          listStyle === item.title
                            ? "text.main"
                            : "button.light",
                        fontWeight: listStyle === item.title ? "600" : "400",
                      }}
                    />
                    {item.isInfo && (
                      <ListItemIcon sx={{ minWidth: "auto", ml: 1 }}>
                        <IconButton
                          disableRipple
                          sx={{
                            color:
                              listStyle === item.title
                                ? "text.main"
                                : "button.light",
                          }}
                        >
                          <img src={InfoIcon} />
                        </IconButton>
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </MuiBox>
    </MuiBox>
  );
};

export default FloorsModal;
