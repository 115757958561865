import { Box, IconButton } from "@mui/material";
import { LucideBan, LucideCheckCircle2 } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
import { UpdateProfessionalUserResume } from "store/middlewares/credential";
import { TooltipProvider } from "components/atoms";

const ResumeTab = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box className="flex h-full items-center justify-end gap-3">
      <IconButton
        color="error"
        disableRipple
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to Reject this Resume?",
            imageUrl: DeleteIconSVGModel,
            imageWidth: 100,
            imageHeight: 100,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
            customClass: {
              title: "Swal-title-class",
              popup: "custom-swal-popup",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(
                UpdateProfessionalUserResume({ id: row.id, status: "rejected" })
              );
            }
          });
        }}
      >
        <TooltipProvider title="Reject">
          <LucideBan />
        </TooltipProvider>
      </IconButton>
      <IconButton
        color="success"
        disableRipple
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to Approve This Resume?",
            imageUrl: DeleteIconSVGModel,
            imageWidth: 100,
            imageHeight: 100,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
            customClass: {
              title: "Swal-title-class",
              popup: "custom-swal-popup",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(
                UpdateProfessionalUserResume({ id: row.id, status: "accepted" })
              );
            }
          });
        }}
      >
        <TooltipProvider title="Approve">
          <LucideCheckCircle2 />
        </TooltipProvider>
      </IconButton>
    </Box>
  );
};

export default ResumeTab;
