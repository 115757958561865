import { MuiBox, MuiTypography, SearchInput } from "components/atoms";
import React, { Fragment, useEffect, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetAllJobTitleWebAction } from "store/middlewares/jobTitle";

const JobTitleModal = ({ onClick, selected }) => {
  const dispatch = useDispatch();
  const {
    jobTitles: { jobTitles },
    // specialities: { specialities },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(GetAllJobTitleWebAction());
    // dispatch(GetAllSpecialitiesWebAction());
  }, [dispatch]);

  const [listStyle, setListStyle] = useState(selected ? selected : jobTitles[0]?.id);

  const handleToggleStyle = (title) => {
    setListStyle(title);
    onClick(title)
  };

  return (
    <MuiBox sx={{ px: "30px" }}>
      <MuiTypography
        variant="h6"
        fontWeight={"bold"}
        align={"center"}
        sx={{ mb: "15px" }}
      >
        Job Title
      </MuiTypography>
      <SearchInput
        type="text"
        variant="plain"
        name={"search"}
        placeholder="Search here..."
      />
      <MuiBox sx={{ mt: 2 }}>
        <List>
          {jobTitles?.map((title) => {
            return (
              <Fragment key={title?.id}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 0 }}
                    onClick={() => handleToggleStyle(title?.id)}
                  >
                    <ListItemText
                      primary={title?.title}
                      sx={{
                        color:
                          listStyle === title?.id
                            ? "text.main"
                            : "button.light",
                        fontWeight: listStyle === title?.id ? "600" : "400",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </MuiBox>
    </MuiBox>
  );
};

export default JobTitleModal;
