import axiosClient from "utils/api";

export const getHolidaysForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/holidayData/holidays",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Holidays", error);
    throw error;
  }
};

export const addHolidays = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/holidayData/holidays",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Add Holidays", error);
    throw error;
  }
};

export const editHolidays = async (payload) => {
  try {
    let { id, ...rest} = payload
    const response = await axiosClient().request({
      url: `/holidayData/holidays/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Edit Holidays", error);
    throw error;
  }
};

export const deleteHolidays = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/holidayData/holidays/${payload.id}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Delete Holidays", error);
    throw error;
  }
};
