import axiosClient from "utils/api";
const URI = "/admin/feature";

export const getFeatures = async () => {
  const URL = `${URI}/feature`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Plans:", error);
    throw error;
  }
};

export const deleteFeatures = async (payload) => {
  const URL = `${URI}/feature/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Plans:", error);
    throw error;
  }
};

export const updateFeatures = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/feature/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Plan:", error);
    throw error;
  }
};

export const addFeatures = async (payload) => {
  const URL = `${URI}/feature`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Plan:", error);
    throw error;
  }
};

export const updateVisiblityFeatures = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update visiblity Features:", error);
    throw error;
  }
};
