import axiosClient from "utils/api";
const URI = "/admin/tax/";

export const getAllTax = async () => {
  const URL = `${URI}/taxes`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during tax:", error);
    throw error;
  }
};

export const updateTax = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/taxes/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during tax update:", error);
    throw error;
  }
};
