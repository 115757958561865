import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, InputTextarea, SubmitBtn } from "components/atoms";
import { Charge, Description, Type } from "utils/validation/AddPaymentPeriods";
import { UpdatePaymentPeriodAction } from "store/middlewares/paymentPeriods";
import { Percent } from "lucide-react";

function ModalEditPaymentPeriods({ row }) {
  const dispatch = useDispatch();
  const AddCategorySchema = Yup.object({
    charges: Charge,
    type: Type,
    description: Description,
  });

  const handleSubmit = (values) => {
    dispatch(
      UpdatePaymentPeriodAction({
        ...values,
        id: row?.id,
        charges: values?.charges ? values?.charges : 0,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      type: row?.type,
      charges: row?.charges ? String(row?.charges) : 0,
      description: row?.description,
    },
    validationSchema: AddCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Type"}
        type="text"
        name="type"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.type && formik.touched.type}
        errorMsg={formik.errors.type}
        value={formik.values.type}
        placeholder="Enter Type"
      />
      <InputTextarea
        label={"Disclaimer"}
        type="text"
        name="description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.description && formik.touched.description}
        errorMsg={formik.errors.description}
        value={formik.values.description}
        placeholder="Enter disclaimer"
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar min-h-[100px]"
      />
      <Input
        inputIcon={
          <Percent className="absolute size-[39px] bg-[#f5f8fa] right-0 top-2 p-2 text-[#0ca8df] rounded-e-lg border border-solid border-zinc-400 " />
        }
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 pr-12 mt-2"
        label={"Charge"}
        type="number"
        name="charges"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.charges && formik.touched.charges}
        errorMsg={formik.errors.charges}
        value={formik.values.charges}
        placeholder="Enter Charge"
      />

      <SubmitBtn title={"Update"} />
    </form>
  );
}
export default ModalEditPaymentPeriods;
