import { createSlice } from "@reduxjs/toolkit";
import {
  AddCityAction,
  DeleteCityAction,
  GetAllCitiesAction,
  UpdateCityAction,
  UpdateVisiblityCityAction,
} from "store/middlewares/cities";

export const citiesSlice = createSlice({
  name: "cities",
  initialState: {
    citiesLoading: false,
    cities: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllCitiesAction.pending, (state) => {
        state.citiesLoading = true;
        state.cities = [];
      })
      .addCase(GetAllCitiesAction.fulfilled, (state, action) => {
        state.citiesLoading = false;
        state.cities = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCitiesAction.rejected, (state) => {
        state.citiesLoading = false;
        state.cities = [];
      })
      // Delete Job Title
      .addCase(DeleteCityAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.cities?.filter((title) => title.id !== id);
        state.citiesLoading = false;
        state.cities = tempArray;
      })
      //Update Job Title
      .addCase(UpdateCityAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.cities?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.citiesLoading = false;
        state.cities = tempArray;
      })
      .addCase(UpdateVisiblityCityAction.fulfilled, (state, action) => {
        state.citiesLoading = false;
        let { id } = action.payload;
        let tempArray = state.cities?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.cities = tempArray;
      })
      // .addCase(UpdateDefaultCountryAction.fulfilled, (state, action) => {
      //   state.citiesLoading = false;
      //   let { id } = action.payload;
      //   let tempArray = state.cities?.map((title) => {
      //     if (title.id === id) {
      //       return { ...title, isDefault: true };
      //     }
      //     return { ...title, isDefault: false };
      //   });
      //   state.cities = tempArray;
      // })
      //Add city
      .addCase(AddCityAction.fulfilled, (state, action) => {
        state.citiesLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.cities = [...state.cities, Obj];
      });
  },
});

export default citiesSlice.reducer;
