import { Box, IconButton } from "@mui/material";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
import { ModalEditProvinces } from "components/molecules";
import { DeleteProvinceAction } from "store/middlewares/provinces";
import { TooltipProvider } from "components/atoms";

const Provinces = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box className="flex h-full items-center justify-end gap-3">
      <IconButton
        color="info"
        // sx={{ padding: "0 !important" }}
        disableRipple
        onClick={() =>
          dispatch(
            openAdminModal({
              title: "Edit Province",
              content: <ModalEditProvinces row={row} />,
            })
          )
        }
      >
        <TooltipProvider title="Edit">
          <Pencil />
        </TooltipProvider>
      </IconButton>
      <IconButton
        color="error"
        // sx={{ padding: "0 !important" }}
        disableRipple
        onClick={() => {
          Swal.fire({
            title: "Are you sure you want to delete this province?",
            imageUrl: DeleteIconSVGModel,
            imageWidth: 100,
            imageHeight: 100,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
            customClass: {
              title: "Swal-title-class",
              popup: "custom-swal-popup",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(DeleteProvinceAction({ id: row.id }));
            }
          });
        }}
      >
        <TooltipProvider title="Delete">
          <Trash2 />
        </TooltipProvider>
      </IconButton>
    </Box>
  );
};

export default Provinces;
