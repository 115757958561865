import * as Yup from "yup";
import { emailRegex, spaceRegExp } from "utils/regex";

export const email = Yup.string()
  .email("Please enter valid email address")
  .required("Please enter email address")
  .matches(emailRegex, "Please enter valid email address");

export const Password = Yup.string()
  .min(6, "Password should be at least 6 characters long")
  .matches(spaceRegExp, "Please enter valid password")
  .max(50, "Password should not contain more than 50 characters")
  .required("Please enter password");
