import React from "react";
import StarFill from "../../../../../assets/images/starFill.svg";
import StarUnfill from "../../../../../assets/images/starUnfill.svg";

function ModalChatReview({ reviewCount, note }) {
 
  return (
    <>
          <div className="flex justify-center">
            <img
              src={reviewCount >= 1 ? StarFill : StarUnfill}
              className="size-10"
              alt="star 1"
            />
            <img
              src={reviewCount >= 2 ? StarFill : StarUnfill}
              className="size-10"
              alt="star 2"
            />
            <img
              src={reviewCount >= 3 ? StarFill : StarUnfill}
              className="size-10"
              alt="star 3"
            />
            <img
              src={reviewCount >= 4 ? StarFill : StarUnfill}
              className="size-10"
              alt="star 4"
            />
            <img
              src={reviewCount >= 5 ? StarFill : StarUnfill}
              className="size-10"
              alt="star 5"
            />
          </div>
          <div>
            <h6>{note}</h6>
          </div>
    </>
  );
}

export default ModalChatReview;
