import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";
import {
  addPaymentPeriod,
  deletePaymentPeriod,
  getPaymentPeriods,
  updatePaymentPeriod,
  updateVisiblityPaymentPeriod,updateDefaultPaymentPeriod
} from "store/services/paymentPeriod";

//Get All Payment Periods
export const GetPaymentPeriodsAction = createAsyncThunk(
  "paymentPeriods/getAllPaymentPeriods",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getPaymentPeriods(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Delete Payment Period
export const DeletePaymentPeriodAction = createAsyncThunk(
  "paymentPeriods/deletePaymentPeriod",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deletePaymentPeriod(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Payment Period
export const UpdatePaymentPeriodAction = createAsyncThunk(
  "paymentPeriods/updatePaymentPeriod",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updatePaymentPeriod(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Add Payment Period
export const AddPaymentPeriodAction = createAsyncThunk(
  "paymentPeriods/addPaymentPeriod",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addPaymentPeriod(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Visiblity Payment Period
export const UpdateVisiblityPaymentPeriodAction = createAsyncThunk(
  "paymentPeriods/updateVisiblityBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblityPaymentPeriod(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Default PaymentPeriod
export const UpdateDefaultPaymentPeriodAction = createAsyncThunk(
  "paymentPeriods/updateDefaultPaymentPeriod",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await updateDefaultPaymentPeriod(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
