import { IconButton } from "@mui/material";
import React from "react";
import EditIcon from "assets/images/editpenpaper.svg";

const EditButton = ({ onClick }) => {
  return (
    <IconButton
      sx={{ padding: "0 !important" }}
      disableRipple
      onClick={onClick}
    >
      <div className="text-slate-500 btn bg-[#4716dabb] border-slate-200 hover:bg-[#7d52fd] hover:border-slate-30">
        <img src={EditIcon} className="inline-block size-5 mr-1" />
        <span className="align-middle text-white">Edit</span>
      </div>
    </IconButton>
  );
};

export default EditButton;
