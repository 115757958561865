import React, { lazy, useCallback, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import Layout from "Layout";
import { useDispatch, useSelector } from "react-redux";
import { ValidateTokenAdmin } from "store/middlewares/session/auth";
import { getDecryptedToken } from "utils/CryptoJS";
import RolesAdd from "pages/Roles/RolesAdd";
import RolesEdit from "pages/Roles/RolesEdit";

//Pages
const AdminDashbord = lazy(() => import("pages/Dashboard"));
const AdminProfile = lazy(() => import("pages/AdminProfile/Profile"));
const AdminChangePass = lazy(() => import("pages/AdminProfile/ChangePass"));
const ActiveHospitalUser = lazy(() => import("pages/Users/HospitalUser"));
const AddHospital = lazy(() => import("pages/Users/HospitalUser/addHospital"));
const EditHospital = lazy(() =>
  import("pages/Users/HospitalUser/editHospital")
);
const HospitalDetail = lazy(() =>
  import("pages/Users/HospitalUser/HospitalDetail")
);
const Professionals = lazy(() => import("pages/Users/ProfessionalUser"));
const AddProfessional = lazy(() =>
  import("pages/Users/ProfessionalUser/addProfessional")
);
const EditProfessional = lazy(() =>
  import("pages/Users/ProfessionalUser/editProfessional")
);
const ProfessionalDetail = lazy(() =>
  import("pages/Users/ProfessionalUser/ProfessionalDetail")
);
const CredentialDetail = lazy(() =>
  import("pages/Users/ProfessionalUser/Credentials/CredentialDetail")
);
const CMS = lazy(() => import("pages/CMS"));
const Faq = lazy(() => import("pages/HelpAndSupport/Faq"));
const Email = lazy(() => import("pages/HelpAndSupport/Email"));
const Chat = lazy(() => import("pages/HelpAndSupport/Chat"));
const AdminSettings = lazy(() => import("pages/Settings"));
const JobTitle = lazy(() => import("pages/DataManagement/JobTitle"));
const Facilities = lazy(() => import("pages/DataManagement/Facilities"));
const Specialities = lazy(() => import("pages/DataManagement/Specialities"));
const Skills = lazy(() => import("pages/DataManagement/Skills"));
const Countries = lazy(() => import("pages/LocationManagement/Countries"));
const Cities = lazy(() => import("pages/LocationManagement/Cities"));
const Provinces = lazy(() => import("pages/LocationManagement/Provinces"));
const Login = lazy(() => import("pages/Login/Login"));
const JobCancellation = lazy(() =>
  import("pages/DataManagement/JobCancellation")
);
const IncidentReport = lazy(() =>
  import("pages/DataManagement/IncidentReport")
);
const Access = lazy(() => import("pages/Access"));
const Roles = lazy(() => import("pages/Roles"));
const AccessManagementUsers = lazy(() => import("pages/AccessManagementUsers"));
const Features = lazy(() => import("pages/SubscriptionManagement/Features"));
const Plans = lazy(() => import("pages/SubscriptionManagement/Plans"));
const Subscriptions = lazy(() =>
  import("pages/SubscriptionManagement/Subscriptions")
);
const DeleteReason = lazy(() => import("pages/DataManagement/DeleteReason"));
const BlockReason = lazy(() => import("pages/DataManagement/BlockReason"));
const RejectReason = lazy(() => import("pages/DataManagement/RejectReason"));
const Banner = lazy(() => import("pages/DataManagement/Banner"));
const DynamicMenu = lazy(() => import("pages/DynamicMenu"));
const Badges = lazy(() => import("pages/DataManagement/Badges"));
const Tax = lazy(() => import("pages/DataManagement/Tax"));
const Language = lazy(() => import("pages/DataManagement/Language"));
const AllJobs = lazy(() => import("pages/AllJobs"));
const JobsDetails = lazy(() => import("pages/JobsDetails"));
const AllCredentials = lazy(() => import("pages/AllCredentials"));
const EditAddress = lazy(() => import("pages/EditAddress"));
const PaymentPeriod = lazy(() => import("pages/DataManagement/PaymentPeriod"));
const ForgetPassword = lazy(() => import("pages/ForgetPassword"));
const ResetPassSuccessfully = lazy(() => import("pages/ResetPassSuccessfully"));
const ResetPasswordAdmin = lazy(() => import("pages/ResetPasswordAdmin"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};

const adminRoutes = [
  {
    path: generatePath("dashboard"),
    element: <AdminDashbord />,
  },
  {
    path: generatePath("hospital/:type"),
    element: <ActiveHospitalUser />,
  },
  {
    path: generatePath("hospital/add"),
    element: <AddHospital />,
  },
  {
    path: generatePath("hospital/edit/:id"),
    element: <EditHospital />,
  },
  {
    path: generatePath("hospital/:type/:id"),
    element: <HospitalDetail />,
  },
  {
    path: generatePath("professional/:type"),
    element: <Professionals />,
  },
  {
    path: generatePath("professional/add"),
    element: <AddProfessional />,
  },
  {
    path: generatePath("professional/edit/:id"),
    element: <EditProfessional />,
  },
  {
    path: generatePath("professional/:type/:id"),
    element: <ProfessionalDetail />,
  },
  {
    path: generatePath("professional/credential/:id"),
    element: <CredentialDetail />,
  },
  {
    path: generatePath("settings"),
    element: <AdminSettings />,
  },
  {
    path: generatePath("cms/:userType/:type"),
    element: <CMS />,
  },
  {
    path: generatePath("profile"),
    element: <AdminProfile />,
  },
  {
    path: generatePath("ChangePass"),
    element: <AdminChangePass />,
  },
  {
    path: generatePath("help-and-support/faq"),
    element: <Faq />,
  },
  {
    path: generatePath("help-and-support/email"),
    element: <Email />,
  },
  {
    path: generatePath("help-and-support/chat"),
    element: <Chat />,
  },
  {
    path: generatePath("job-title"),
    element: <JobTitle />,
  },
  {
    path: generatePath("specialities"),
    element: <Specialities />,
  },
  {
    path: generatePath("facilities"),
    element: <Facilities />,
  },
  {
    path: generatePath("skills"),
    element: <Skills />,
  },
  {
    path: generatePath("countries"),
    element: <Countries />,
  },
  {
    path: generatePath("provinces"),
    element: <Provinces />,
  },
  {
    path: generatePath("payment-period"),
    element: <PaymentPeriod />,
  },
  {
    path: generatePath("cities"),
    element: <Cities />,
  },
  {
    path: generatePath("job-cancellation"),
    element: <JobCancellation />,
  },
  {
    path: generatePath("incident-report"),
    element: <IncidentReport />,
  },
  {
    path: generatePath("access"),
    element: <Access />,
  },
  {
    path: generatePath("roles"),
    element: <Roles />,
  },
  {
    path: generatePath("users"),
    element: <AccessManagementUsers />,
  },
  {
    path: generatePath("plans"),
    element: <Plans />,
  },
  {
    path: generatePath("features"),
    element: <Features />,
  },
  {
    path: generatePath("subscriptions"),
    element: <Subscriptions />,
  },
  {
    path: generatePath("delete-reason"),
    element: <DeleteReason />,
  },
  {
    path: generatePath("block-reason"),
    element: <BlockReason />,
  },
  {
    path: generatePath("reject-reason"),
    element: <RejectReason />,
  },
  {
    path: generatePath("banner"),
    element: <Banner />,
  },
  {
    path: generatePath("dynamic-menu"),
    element: <DynamicMenu />,
  },
  {
    path: generatePath("tax"),
    element: <Tax />,
  },
  {
    path: generatePath("badges"),
    element: <Badges />,
  },
  {
    path: generatePath("language"),
    element: <Language />,
  },
  {
    path: generatePath("jobs"),
    element: <AllJobs />,
  },
  {
    path: generatePath("jobsDetails/:id"),
    element: <JobsDetails />,
  },
  {
    path: generatePath("credentials"),
    element: <AllCredentials />,
  },
  {
    path: generatePath("roles/add"),
    element: <RolesAdd />,
  },
  {
    path: generatePath("roles/edit/:id"),
    element: <RolesEdit />,
  },
  {
    path: generatePath("address/edit/:id"),
    element: <EditAddress />,
  },
];

const adminAuthRoutes = [
  {
    path: generatePath("forget-password"),
    element: <ForgetPassword />,
  },
  // {
  //   path: generatePath(),
  //   element: <Login />,
  // },
  {
    path: generatePath("resetpassword/successfully"),
    element: <ResetPassSuccessfully />,
  },
  {
    path: generatePath("resetAdminPassword/:id"),
    element: <ResetPasswordAdmin />,
  },
];

const AdminNavigation = () => {
  useDocumentTitle("Vital - Admin");

  const user = JSON.parse(localStorage.getItem("aboutcare-admin"));

  const dispatch = useDispatch();
  const { isTokenValid } = useSelector((state) => state.auth);

  const checkToken = useCallback(() => {
    if (user) {
      dispatch(ValidateTokenAdmin({ token: getDecryptedToken(user?.token) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <Routes>
      {/* if user is logged in redirect to dashboard */}
      <Route
        path=""
        element={
          !user || !isTokenValid ? <Login /> : <Navigate to="dashboard" />
        }
      />
      {/* if user is not present show unauthorized ui */}
      <Route
        element={!user || !isTokenValid ? <Navigate to="/admin" /> : <Layout />}
      >
        {adminRoutes.map((route, index) => {
          return (
            <Route
              key={`${route.path}${index}`}
              path={route.path}
              element={route.element}
            />
          );
        })}
        {/* all undefined routes are handled here for admin */}
      </Route>
      {adminAuthRoutes.map((route, index) => {
        return (
          <Route
            key={`${route.path}${index}`}
            path={route.path}
            element={route.element}
          />
        );
      })}
      <Route
        path="*"
        element={
          !user || !isTokenValid ? (
            <Navigate to="/admin" />
          ) : (
            <Navigate to="dashboard" />
          )
        }
      />
    </Routes>
  );
};

export default AdminNavigation;
