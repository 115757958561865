import React from "react";
import MuiBox from "../MuiBox";
import { useSelector } from "react-redux";
import MuiTypography from "../MuiTypography";
import dayjs from "dayjs";
import blankImg from "assets/images/blank.png";

const UserMessageItemWeb = ({ item }) => {
  let date = dayjs(item?.createdAt).format("HH:mm A");

  const {
    me: { me },
  } = useSelector((store) => store);

  return (
    <MuiBox className="web-receiver-msg-wrapper">
      <MuiBox className="web-msg-box">
        <MuiBox className="msg-content">
          <MuiTypography fontSize="14px" className="msg-text">
            {item.message}
          </MuiTypography>
        </MuiBox>
        <MuiBox className="msg-time">
          <MuiTypography fontSize="14px" align="right">
            {date}
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        <MuiBox className="web-msg-profile-pic">
          <img
            src={me?.profileImageUrl ? me?.profileImageUrl : blankImg}
            className="img-cover"
          />
        </MuiBox>
        {/* <IconButton
    sx={{ p: "4px" }}
    onClick={() =>
      dispatch(
        openDrawer({
          content: (
            <MessagsMenuModal type="deleteMessage" />
          ),
          width: "500px",
        })
      )
    }
  >
    <img src={MsgDots} />
  </IconButton> */}
      </MuiBox>
    </MuiBox>
  );
};

export default UserMessageItemWeb;
