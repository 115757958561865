import React, { useEffect, useState } from "react";

//import images
import Aboutlarge from "assets/images/logo.svg";
import Aboutsmall from "assets/images/logoWithoutName.png";

import { NavLink } from "react-router-dom";

import VerticalLayout from "./Index";
import SimpleBar from "simplebar-react";
import HorizontalLayout from "./../HorizontalLayout";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import { useDispatch } from "react-redux";
import { changeLeftsidebarSizeType } from "store/slices/common/layout.slice";

const SidebarAdmin = ({ layoutType, layoutSidebarSizeType }) => {
  const dispatch = useDispatch();

  const [showIcon, setShowIcon] = useState("");

  const handleMouseEnter = () => {
    if (layoutSidebarSizeType === "sm") {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  };
  const handleMouseLeave = () => {
    setShowIcon(false);
  };

  useEffect(() => {
    if (layoutSidebarSizeType !== "sm") {
      setShowIcon(false);
    }
  }, [layoutSidebarSizeType]);

  const handleTopbarHamburgerIcon = () => {
    var windowSize = document.documentElement.clientWidth;
    let sidebarOverlay = document.getElementById("sidebar-overlay");

    if (windowSize < 768) {
      document.body.classList.add("overflow-hidden");
      if (sidebarOverlay.classList.contains("hidden")) {
        sidebarOverlay.classList.remove("hidden");
        document.querySelector(".app-menu").classList.remove("hidden");
      } else {
        sidebarOverlay.classList.add("hidden");
        document.querySelector(".app-menu").classList.add("hidden");
      }
      dispatch(changeLeftsidebarSizeType("lg"));
    } else {
      dispatch(
        changeLeftsidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm")
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className={` app-menu 
          w-vertical-menu border-r border-vertical-menu-border fixed bottom-0 
          top-0 z-[1003] transition-all duration-75 ease-linear 
          group-data-[sidebar-size=md]:w-vertical-menu-md 
          group-data-[sidebar-size=sm]:w-vertical-menu-sm 
          group-data-[sidebar-size=sm]:pt-header 
          bg-vertical-bg
          border-vertical-bg
          md:block 
          print:hidden 
          group-data-[sidebar-size=sm]:absolute 
          hidden
          `}
      >
        <div
          className="flex items-center 
          justify-center px-5 text-center 
          h-header 
          group-data-[sidebar-size=sm]:fixed 
          group-data-[sidebar-size=sm]:top-0 
        group-data-[sidebar-size=sm]:z-10 
        group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_-_1px)] 
        group-data-[sidebar-size=sm]:bg-vertical-bg
        "
        >
          {showIcon ? (
            <button
              onClick={handleTopbarHamburgerIcon}
              type="button"
              className="flex border 
              hover:bg-vertical-menu-dark
              border-white 
              items-center 
              px-3 py-5 
              text-vertical-menu 
              transition-all  
              duration-75 
              ease-linear 
              rounded-md 
              btn 
              hamburger-icon
              "
              id="topnav-hamburger-icon"
              onMouseLeave={handleMouseLeave}
            >
              <ChevronsRight className="w-5 h-5 text-vertical-menu" />
            </button>
          ) : (
            <NavLink to="#" className="w-full" onMouseEnter={handleMouseEnter}>
              <span className="hidden group-data-[sidebar-size=sm]:block ">
                <img
                  src={Aboutsmall}
                  alt=""
                  className="h-auto w-[75%] mx-auto pointer-events-none"
                />
              </span>
              <span className="group-data-[sidebar-size=sm]:hidden">
                <img
                  src={Aboutlarge}
                  alt=""
                  className="h-auto w-[75%] mx-auto pointer-events-none"
                />
              </span>
            </NavLink>
          )}
          {layoutSidebarSizeType !== "sm" && (
            <button
              onClick={handleTopbarHamburgerIcon}
              type="button"
              className="flex hover:bg-vertical-menu-dark p-5 justify-center items-center text-vertical-menu transition-all duration-75 ease-linear rounded-md btn hamburger-icon"
              id="topnav-hamburger-icon"
            >
              <ChevronsLeft className="w-5 h-5 group-data-[sidebar-size=sm]:hidden text-vertical-menu" />
            </button>
          )}
        </div>

        {layoutType === "vertical" && layoutSidebarSizeType !== "sm" ? (
          <SimpleBar
            id="scrollbar"
            className="group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)]"
          >
            <div>
              <ul id="navbar-nav">
                <VerticalLayout />
              </ul>
            </div>
          </SimpleBar>
        ) : (
          <div
            id="scrollbar"
            className="group-data-[sidebar-size=md]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)] group-data-[sidebar-size=lg]:max-h-[calc(100vh_-_theme('spacing.header')_*_1.2)]"
          >
            <div>
              <ul id="navbar-nav">
                <HorizontalLayout />
              </ul>
            </div>
          </div>
        )}
      </div>

      <div
        id="sidebar-overlay"
        className="absolute inset-0 z-[1002] bg-slate-500/30 hidden"
      ></div>
    </React.Fragment>
  );
};

export default SidebarAdmin;
