import React from "react";

// Button For Adding Items
const AddButton = ({ onClick, title }) => {
  return (
    <button
      onClick={onClick}
      className="text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 ml-3 rounded-lg"
    >
      {title}
    </button>
  );
};

export default AddButton;
