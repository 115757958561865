import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { DateInput, SelectComponent, SubmitBtn } from "components/atoms";
import {
  BannerType,
  EndDate,
  ImageFile,
  PlatformType,
  StartDate,
} from "utils/validation/AddBanner";
import { UpdateBannerAction } from "store/middlewares/banner";
import { Grid } from "@mui/material";
import { FormatDateReverce } from "utils/FormateDate";
import BlankSvg from "../../../../../assets/images/blank.png";
import VideoThumbnailExtractor from "utils/thumbnailGenerate";

function ModalEditBanner({ row }) {
  const dispatch = useDispatch();
  const [thumbnail, setThumbnail] = useState(null);
  const [imageSrc, setImageSrc] = useState(BlankSvg);
  // const [errorMessage, setErrorMessage] = useState("");
  const [videoPreview, setVideoPreview] = useState(null);

  useEffect(() => {
    if (row?.bannerUrl) {
      setImageSrc(row?.bannerUrl);
    }
    if (row?.bannerUrl) {
      setVideoPreview(row?.bannerUrl);
    }
  }, [row]);

  const AddSpecialitySchema = Yup.object({
    fileType: "",
    fileImageType: ImageFile,
    bannerType: BannerType,
    platFormType: PlatformType,
    startDate: StartDate,
    endDate: EndDate,
  });

  const handleSubmit = (values) => {
    let payload = {
      ...values,
      file:
        values.fileType === "image"
          ? values.fileImageType
          : values.fileVideoType,
      ...(thumbnail && values.fileType === "video" && { thumbnail }),
    };
    delete payload["fileVideoType"];
    delete payload["fileImageType"];
    delete payload["fileType"];

    dispatch(UpdateBannerAction({ id: row?.id, ...payload }));
  };

  const formik = useFormik({
    initialValues: {
      // fileType: "",
      fileVideoType: "",
      platFormType: row?.platFormType,
      bannerFileType: row?.fileType,
      startDate: FormatDateReverce(row.startDate),
      endDate: FormatDateReverce(row.endDate),
      fileType: row?.fileType,
      bannerType: row?.bannerType,
      oldUrl: row?.banner,
    },
    validationSchema: AddSpecialitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
  //   const reader = new FileReader();

  //   if (file) {
  //     if (file.size <= fileSizeLimit) {
  //       reader.onload = () => {
  //         setImageSrc(reader.result);
  //         formik.setFieldValue("file", e.target.files[0]);
  //         let extension = e.target.files[0]?.type;
  //         let isImage = extension?.includes("image");
  //         formik.setFieldValue("fileType", "image");
  //         setErrorMessage("");
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       // setErrorMessage(
  //       //   "Please select a valid image file with size up to 3MB."
  //       // );
  //       // formik.setFieldValue("fileType", "");
  //       formik.setFieldValue("file", null);
  //     }
  //   }
  // };

  const platformTypeOptions = [
    { value: "Web", label: "Web" },
    { value: "App", label: "App" },
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024;
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("fileImageType", file);
        };
        reader.readAsDataURL(file);
      } else {
        // formik.setFieldValue("fileImageType", "size more");
        formik.setFieldTouched("fileImageType", true);
      }
    }
  };

  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    setVideoPreview(null);

    if (file) {
      //--------------- Thumbnails --------------------
      VideoThumbnailExtractor(file, setThumbnail);
      //--------------- Thumbnails --------------------
      // Check file size (10MB)
      if (file.size > 10 * 1024 * 1024) {
        formik.setFieldError(
          "fileVideoType",
          "File size must be less than 10 MB."
        );
        // formik.setFieldTouched("fileVideoType", true);
        return;
      }

      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        if (video.duration > 120) {
          formik.setFieldError(
            "fileVideoType",
            "Video duration must be less than 2 minutes."
          );
          // formik.setFieldTouched("fileVideoType", true);
          return;
        }

        // Set video preview
        const videoUrl = URL.createObjectURL(file);
        setVideoPreview(videoUrl);
        formik.setFieldValue("fileVideoType", file);
      };

      video.src = URL.createObjectURL(file);
    }
    // else {
    //   formik.setFieldError("fileVideoType", "Video is required");
    // }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.values.fileType === "image" ? (
        <div className="">
          <label className="font-medium">
            Banner Image <span className="text-[#FF5C5C]">*</span>
          </label>
          <br />
          <div className="flex flex-col mt-2 gap-x-2.5">
            <div className="relative">
              <div className="h-48 w-full bg-white">
                <input
                  type="file"
                  name="fileImageType"
                  accept="image/png, image/jpeg,image/jpg"
                  id="editimg"
                  onChange={(e) => {
                    handleFileChange(e);
                    // formik.setFieldValue("file", e.target.files[0]);
                  }}
                  hidden
                />
                <label htmlFor="editimg" className="cursor-pointer">
                  <div className="w-full h-48 overflow-hidden">
                    <img
                      src={imageSrc}
                      alt="flag"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {formik.touched.fileImageType && formik.errors.fileImageType && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.fileImageType}
            </div>
          )}
          {/* {(errorMessage ||
            (formik.errors.fileImageType && formik.touched.fileImageType)) && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.fileImageType
                ? formik.errors.fileImageType
                : errorMessage}
            </div>
          )} */}
        </div>
      ) : (
        <div className="">
          <label className="font-medium">
            Banner Video <span className="text-[#FF5C5C]">*</span>
          </label>
          <br />
          <div className="flex flex-col mt-2 gap-x-2.5">
            <div className="relative">
              <div className="w-full bg-white">
                <input
                  type="file"
                  name="fileVideoType"
                  accept="video/mp4, video/.mov,video/.mkv,video/.wmv,video/.webm"
                  id="editVideo"
                  onChange={(e) => {
                    handleVideoFileChange(e);
                  }}
                  hidden
                />
                <label
                  htmlFor="editVideo"
                  className="text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 rounded-lg"
                >
                  Choose Video
                </label>
              </div>
            </div>
          </div>
          {/* Show Formik validation error if present */}
          {formik.errors.fileVideoType && formik.touched.fileVideoType && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.fileVideoType}
            </div>
          )}
          {videoPreview && (
            <div className="mt-2">
              <video width="100%" className="h-[300px] object-cover" controls>
                <source src={videoPreview} type="video/mp4" />
                <source src={videoPreview} type="video/quicktime" />{" "}
                {/* for .mov */}
                <source src={videoPreview} type="video/x-matroska" />{" "}
                {/* for .mkv */}
                <source src={videoPreview} type="video/x-ms-wmv" />{" "}
                {/* for .wmv */}
                <source src={videoPreview} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      )}

      {/* <div className="">
        <label className="font-medium">Banner Image</label>
        <br />
        <div className="flex flex-col mt-2 gap-x-2.5">
          <div className="relative">
            <div className="h-48 w-full bg-white">
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg,image/jpg"
                id="editimg"
                onChange={(e) => {
                  handleFileChange(e);
                  formik.setFieldValue("file", e.target.files[0]);
                }}
                hidden
              />
              <label htmlFor="editimg" className="cursor-pointer">
                <div className="w-full h-48 overflow-hidden">
                  <img
                    src={imageSrc}
                    alt="flag"
                    className="w-full h-full object-cover"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        {(errorMessage ||
          (formik.errors.fileType && formik.touched.fileType)) && (
          <div className="text-red-500 text-sm mt-1">
            {formik.errors.fileType ? formik.errors.fileType : errorMessage}
          </div>
        )}
      </div> */}
      <Grid container columnSpacing={5} rowSpacing={3}>
        <Grid item md={6} xs={12}>
          <DateInput
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"Start Date"}
            type="date"
            name="startDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.startDate && formik.touched.startDate}
            errorMsg={formik.errors.startDate}
            value={formik.values.startDate}
            placeholder="Enter Start Date"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateInput
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"End Date"}
            type="date"
            name="endDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.endDate && formik.touched.endDate}
            errorMsg={formik.errors.endDate}
            value={formik.values.endDate}
            placeholder="Enter End Date"
          />
        </Grid>
      </Grid>
      <SelectComponent
        className={"px-0"}
        label={"Platform Type"}
        options={platformTypeOptions}
        placeholder="Select Platform Type"
        name={"platFormType"}
        value={formik.values.platFormType}
        error={formik.errors.platFormType && formik.touched.platFormType}
        errorMsg={formik.errors.platFormType}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "platFormType",
            selectedOption ? selectedOption?.value : ""
          );
        }}
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditBanner;
