import CryptoJS from "crypto-js";


export const encryptAndStoreToken = (token) => {
  // eslint-disable-next-line no-undef
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const encryptedToken = CryptoJS.AES.encrypt(token, secretKey).toString();
  return encryptedToken;
};

export const getDecryptedToken = (encryptedToken) => {
  // eslint-disable-next-line no-undef
  const secretKey = process.env.REACT_APP_SECRET_KEY; // Same key used for encryption

  if (encryptedToken) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken;
  }
  return null; // Handle case where token is not available
};
