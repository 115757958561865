import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'
import { Link } from 'react-router-dom'

import Calender from 'assets/images/SVG/Calendar.svg';
import Upcoming from 'assets/images/PNG/upcoming.png';
const UpcomigScheduleCard = () => {
    return (
        <MuiBox component={Link} sx={{ background: 'linear-gradient(242.4deg, #AADEF0 9.01%, #CFD1FF 78.48%)', borderRadius: '12px', p: { xs: 1, md: 2 }, display: 'flex', flexDirection: 'column' }}>
            <MuiBox sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', gap: 2, flexWrap: 'wrap-reverse' }}>
                <MuiBox sx={{ width: 'fit-content', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <MuiTypography variant='subtitle1' fontWeight='bold' lineHeight='21px' color='text.main'>
                        Hanna Schleifer
                    </MuiTypography>
                    <MuiTypography fontSize='14px' color='rgba(129, 125, 134, 1)'>
                        MA (Medical Assistant)
                    </MuiTypography>
                    <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', bgcolor: 'rgba(255, 255, 255, 0.55)', borderRadius: '9px', border: '1px solid rgba(255, 255, 255, 1)', gap: 1, p: "4px 10px" }}>
                        <img src={Calender} alt='date' />
                        <MuiTypography fontSize='14px' color='text.dark'>
                            20/07/2024
                        </MuiTypography>
                    </MuiBox>
                </MuiBox>
                <MuiBox sx={{ width: '98px', position: 'relative' }}>
                    <MuiBox sx={{ height: '98px', width: '98px', borderRadius: '12px', overflow: 'hidden' }}>
                        <img src={Upcoming} alt='upcomig schedule' style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                    </MuiBox>
                    <MuiBox sx={{ bgcolor: '#ffff', width: 'fit-content', p: "4px 10px", borderRadius: '12px', position: 'absolute', left: '50%', transform: 'translateX(-50%)', bottom: '-10px' }}>
                        <MuiTypography fontWeight='bold' fontSize='14px' color='text.dark'>
                            #X034Y6Z
                        </MuiTypography>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <MuiBox sx={{ mt: '20px', width: '100%' }}>
                <MuiTypography variant='baseFont' color='text.dark' fontSize='14px'>
                    Lorem ipsum dolor sit amet consectetur. Cras adipiscing faucibus faucibus  Varius faucibus nunc dignissim quisque tortor.
                </MuiTypography>
            </MuiBox>
        </MuiBox>
    )
}

export default UpcomigScheduleCard