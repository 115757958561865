import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'
import { Link } from 'react-router-dom'
import { Divider } from '@mui/material';

import Shift from 'assets/images/PNG/shift.png';
import Clock from 'assets/images/SVG/clockShift.svg';
import ClockBlack from 'assets/images/SVG/clockBlack.svg';
import Beg from 'assets/images/SVG/beg.svg';
import Star from 'assets/images/SVG/Star.svg'
import Calender from 'assets/images/SVG/Calendar.svg';


const ProfessionalShiftCard = () => {
    return (
        <MuiBox component={Link} sx={{ background: '#fff', borderRadius: '12px', p: { xs: 1, md: 2 }, display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <MuiBox sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }} className='ongoing-schedule-card' >
                <MuiBox sx={{ height: '99px', width: '99px', borderRadius: '6px', overflow: 'hidden', flexShrink: 0 }}>
                    <img src={Shift} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                </MuiBox>
                <MuiBox>
                    <MuiBox sx={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap-reverse', justifyContent: 'space-between' }}>
                        <MuiBox sx={{ width: 'auto', minWidth: '170px', maxWidth: '200px' }}>
                            <MuiTypography color='text.main' variant='subtitle1' fontWeight='bold'>
                                Aspen Press
                            </MuiTypography>
                            <MuiTypography color='text.contrastText' sx={{ fontSize: '14px', overflow: 'hidden' }}>
                                MA (Medical Assistant)
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: '4px', bgcolor: '#C0F8E5', width: 'fit-content', p: '5px 10px', borderRadius: '6px' }}>
                            <img src={Clock} style={{ height: '20px', width: '24px', objectFit: 'contain' }} />
                            <MuiTypography color='#07B67C' fontSize='12px' sx={{ textWrap: 'nowrap' }}>
                                Check in
                            </MuiTypography>
                        </MuiBox>
                    </MuiBox>
                    <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: '10px', flexWrap: 'wrap' }}>
                        <MuiBox sx={{ width: 'fit-content', bgcolor: '#E8F0F3', p: '5px 10px', borderRadius: '6px' }}>
                            <MuiTypography color='text.dark' fontSize='14px' fontWeight='bold'>
                                #X034Y6Z
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', gap: '5px' }}>
                            <MuiBox sx={{ width: '26px', height: '26px', borderRadius: '6px', bgcolor: '#E8F0F3', p: '5px' }}>
                                <img src={Beg} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                            </MuiBox>
                            <MuiTypography color='text.main' fontWeight='bold' sx={{ fontSize: '14px' }}>
                                2 years
                            </MuiTypography>
                        </MuiBox>
                        <MuiBox sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', gap: '5px' }}>
                            <MuiBox sx={{ width: '18px', height: '18px' }}>
                                <img src={Star} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                            </MuiBox>
                            <MuiTypography color='text.contrastText' sx={{ fontSize: '14px' }}>
                                4.5 (111)
                            </MuiTypography>
                        </MuiBox>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
            <MuiBox>
                <MuiTypography fontSize='14px' lineHeight='20px' color='text.dark'>
                    Lorem ipsum dolor sit amet consectetur. Cras adipiscing faucibus faucibus  Varius faucibus nunc dignissim quisque tortor.
                </MuiTypography>
            </MuiBox>
            <Divider />
            <MuiBox sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, justifyContent: 'space-between', py: '5px', gap: '5px', textWrap: 'wrap' }}>
                <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={Calender} alt='date' />
                    <MuiTypography fontSize='14px' color='text.dark'>
                        20/07/2024
                    </MuiTypography>
                </MuiBox>
                <Divider orientation='vertical' flexItem />
                <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 1 }}>
                    <img src={ClockBlack} alt='clock' style={{ height: '23px', width: '23px' }} />
                    <MuiTypography fontSize='14px' color='text.dark' sx={{ textWrap: 'nowrap' }}>
                        10:00 AM - 7:30 PM
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
        </MuiBox>
    )
}

export default ProfessionalShiftCard