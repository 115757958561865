import { createSlice } from "@reduxjs/toolkit";
import {
  AddFacilityAction,
  DeleteFacilityAction,
  GetAllFacilitiesAction,
  GetAllFacilitiesWebAction,
  UpdateFacilityAction,
} from "store/middlewares/facilities";

// isDeleteMeFulFilled
export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    facilitiesLoading: false,
    facilities: [],
    facilitiesFilterProps: {},
  },
  reducers: {
    setFacilitiesFilterProps: (state, action) => {
      state.facilitiesFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllFacilitiesAction.pending, (state) => {
        state.facilitiesLoading = true;
        state.facilities = [];
      })
      .addCase(GetAllFacilitiesAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        state.facilities = action.payload.response;
      })
      .addCase(GetAllFacilitiesAction.rejected, (state) => {
        state.facilitiesLoading = false;
        state.facilities = [];
      })
      .addCase(GetAllFacilitiesWebAction.pending, (state) => {
        state.facilitiesLoading = true;
        state.facilities = [];
      })
      .addCase(GetAllFacilitiesWebAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        state.facilities = action.payload.response;
      })
      .addCase(GetAllFacilitiesWebAction.rejected, (state) => {
        state.facilitiesLoading = false;
        state.facilities = [];
      })
      // Delete Facility
      .addCase(DeleteFacilityAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.facilities?.filter((title) => title.id !== id);
        state.facilitiesLoading = false;
        state.facilities = tempArray;
      })
      //Update Facility
      .addCase(UpdateFacilityAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.facilities?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.facilitiesLoading = false;
        state.facilities = tempArray;
      })
      //Add Facility
      .addCase(AddFacilityAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        state.facilities = [action.payload.response, ...state.facilities];
      })
  },
});
export const {setFacilitiesFilterProps} = facilitiesSlice.actions;
export default facilitiesSlice.reducer;
