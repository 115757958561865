import React from "react";
import MuiBox from "../MuiBox";
import { WarningRounded } from "@mui/icons-material";
import MuiTypography from "../MuiTypography";

const FormError = ({ errorMsg }) => {
  return (
    <MuiBox sx={{ marginTop: "8px" }}>
      <WarningRounded color="error" size={20} />
      <MuiTypography
        component={"span"}
        sx={{ marginLeft: "5px" }}
        variant="error"
        color="error"
      >
        {errorMsg}
      </MuiTypography>
    </MuiBox>
  );
};

export default FormError;
