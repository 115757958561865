import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

const Input = ({
  label,
  RadioOption = [],
  name,
  value,
  onChange,
  isRequired = true,
}) => {
  return (
    <FormControl>
      {label && (
        <>
          <label className={`font-medium`}>
            {label} {isRequired && <span className="text-[#FF5C5C]">*</span>}
          </label>
        </>
      )}
      {/* <FormLabel id="demo-radio-buttons-group-label" sx={{color:"#000000de",fontFamily:"public sans",fontSize:"14px",fontWeight:"500"}}>{label}</FormLabel> */}
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name={name}
        value={value}
        onChange={onChange}
        sx={{ flexDirection: "row" }}
      >
        {RadioOption.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
            sx={{ fontSize: "10px" }}
            className="radio-label"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default Input;
