import {
  Divider,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

import DropdownIcon from "assets/images/SVG/chevron-down.svg";
import FormError from "../FormError";
const CustomDropdownIcon = () => (
  <img src={DropdownIcon} alt="dropdown" style={{ padding: "5px" }} />
);
const SelectComponent = ({
  variant = "basic",
  className,
  onChange,
  onBlur,
  value,
  error,
  errorMsg,
  placeholder = "Select Here",
  label,
  name,
  fullWidth = true,
  options = [
    { label: "Oliver Hansen", value: 1 },
    { label: "Van Henry", value: 2 },
    { label: "April Tucker", value: 3 },
    { label: "Ralph Hubbard", value: 4 },
    { label: "Omar Alexander", value: 5 },
    { label: "Carlos Abbott", value: 6 },
    { label: "Miriam Wagner", value: 7 },
    { label: "Bradley Wilkerson", value: 8 },
    { label: "Virginia Andrews", value: 9 },
    { label: "Kelly Snyder", value: 10 },
  ],
  onClickFunc,
  disabled = false,
  multiple = false,
  icon,
  required = false,
}) => {
  // let valueArray = [];
  // if (multiple && value?.length > 0) {
  //   value?.forEach((val) => {
  //     valueArray?.push(options?.find((option) => option?.value === val));
  //   });
  // }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
      },
    },
  };

  return (
    <>
      <div
        style={{ width: "100%" }}
        onClick={onClickFunc ? onClickFunc : undefined}
      >
        <>
          {label && (
            <div style={{ paddingBottom: "8px !important" }}>
              <Typography variant="baseFont" fontWeight={"bold"}>
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </Typography>
            </div>
          )}
          <Select
            data-variant={variant}
            className={className}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={multiple}
            error={error}
            value={value}
            fullWidth={fullWidth}
            disabled={disabled}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            name={name}
            displayEmpty={true}
            renderValue={(selected) => {
              if (
                !selected ||
                (Array.isArray(selected) && selected.length === 0)
              ) {
                return <p style={{ color: "#A2A2A2" }}>{placeholder}</p>;
              }
              return multiple
                ? options
                    ?.filter((option) => selected?.includes(option.value))
                    ?.map((x) => x.label)
                    .join(", ")
                : options?.find((item) => item.value === selected)?.label;
            }}
            MenuProps={MenuProps}
            sx={{ borderColor: "#ABA8AE", ".MuiSelect-select": { ml: "14px" } }}
            IconComponent={CustomDropdownIcon}
            startAdornment={
              icon && (
                <InputAdornment position="start">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      height: "43px",
                    }}
                  >
                    {icon}
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{ backgroundColor: "inputBorder.main" }}
                    />
                  </div>
                </InputAdornment>
              )
            }
          >
            {options.map((name) => (
              <MenuItem key={name?.label} value={name?.value}>
                <ListItemText primary={name?.label} />
              </MenuItem>
            ))}
          </Select>
          {error && errorMsg && <FormError errorMsg={errorMsg} />}
        </>
      </div>
    </>
  );
};

export default SelectComponent;
