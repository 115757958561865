import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React from "react";

const CheckboxGroup = ({
  label,
  name,
  options,
  onChange,
  value,
  labelPlacement = "end",
}) => {
  return (
    <FormControl sx={{ padding: "0.5rem 0", width: "100%" }}>
      {label && (
        <label htmlFor={name} className={`font-medium`}>
          {label}
        </label>
      )}
      <FormGroup aria-label={label} row>
        {options?.map((item) => {
          return (
            <FormControlLabel
              key={item?.value}
              value={item?.value}
              control={
                <Checkbox
                  value={item?.value}
                  checked={value?.includes(item?.value)}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e);
                    }
                  }}
                  // name={item?.label}
                />
              }
              label={item?.label}
              labelPlacement={labelPlacement}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
