import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Name } from "utils/validation/AddSkill";
import { UpdateSkillAction } from "store/middlewares/skills";

function ModalEditSkill({ row }) {
  const dispatch = useDispatch();

  const EditSkillSchema = Yup.object({ name: Name });

  const handleSubmit = (values) => {
    dispatch(UpdateSkillAction({ id: row.id, ...values }));
  };

  const formik = useFormik({
    initialValues: { name: row?.name },
    validationSchema: EditSkillSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditSkill;
