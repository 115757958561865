import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, Radio, SubmitBtn } from "components/atoms";
import { Reason } from "utils/validation/AddDeleteReason";
import { AddBlockReasonAction } from "store/middlewares/blockReason";
import Swal from "sweetalert2";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { closeAdminModal } from "store/slices/common/adminModal.slice";

function ModalAddDeleteReason() {
  const dispatch = useDispatch();

  const intitalVal = {
    reason: "",
    type: "hospital",
  };

  const AddSpecialitySchema = Yup.object({ reason: Reason });

  const handleSubmit = (values) => {
    dispatch(closeAdminModal());
    Swal.fire({
      title: "Are you sure you want to add this reason?",
      imageUrl: ApprovedSVGModel,
      imageWidth: 100,
      imageHeight: 100,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "Swal-title-class",
        popup: "custom-swal-popup",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(AddBlockReasonAction(values));
      }
    });
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddSpecialitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const radioOptions = [
    { value: "hospital", label: "Hospital" },
    { value: "professional", label: "Professional" },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Reason"}
        error={formik.errors.reason && formik.touched.reason}
        errorMsg={formik.errors.reason}
        value={formik.values.reason}
        type="text"
        name="reason"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Reason"
      />
      <Radio
        label="Type"
        RadioOption={radioOptions}
        name="type"
        value={formik.values.type}
        onChange={(e) => {
          formik.setFieldValue("type", e.target.value);
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddDeleteReason;
