// src/App.js
import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "@fontsource/poppins";
import "assets/scss/themes.scss";
import { AdminModal, DrawerRight, ToastWrapper } from "components/atoms";
import AdminNavigation from "navigation/adminNavigation";
import HospitalNavigation from "navigation/hospitalNavigation";
import AuthNavigation from "navigation/authNavigation";
import ProfessionalNavigation from "navigation/professionalNavigation";

const App = () => {
  return (
    <>
      <DrawerRight />
      <AdminModal />
      <ToastWrapper />
      <Routes>
        <Route path="/*" element={<AuthNavigation />} />
        <Route path="/admin/*" element={<AdminNavigation />} />
        <Route path="/hospital/*" element={<HospitalNavigation />} />
        <Route path="/professional/*" element={<ProfessionalNavigation />} />
      </Routes>
    </>
  );
};

export default App;
