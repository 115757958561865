import {
  ChatButton,
  CustomLoader,
  MuiBox,
  MuiTypography,
  NoDataFoundCard,
} from "components/atoms";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllChatListForWebAction } from "store/middlewares/HelpAndSupport";

const HelpAndSupportChatList = ({ handleOpenChat, chatSelected }) => {
  const dispatch = useDispatch();
  const {
    helpAndSupport: { chatList, chatListLoading },
  } = useSelector((store) => store);

  useEffect(() => {
    dispatch(GetAllChatListForWebAction());
  }, [dispatch]);

  return (
    <MuiBox
      sx={{
        bgcolor: "#F4FCFF",
        height: { xs: "100vh", md: "74dvh" },
        overflowY: "auto",
      }}
      className="no-scrollbar"
    >
      {/* <SimpleBar style={{ maxHeight: '72dvh', overflowX: "hidden" }}> */}
      {chatListLoading && <CustomLoader />}
      {!chatListLoading && (
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            p: "20px 10px",
          }}
        >
          <MuiTypography variant="baseFont" fontWeight="bold" color="#0A1E41">
            Inbox
          </MuiTypography>
          {chatList
            ?.filter((user) => !user?.isChatFinished)
            ?.map((user) => (
              <ChatButton
                user={user}
                key={user?.id}
                onClick={() => handleOpenChat(user?.id)}
                chatSelected={chatSelected}
              />
            ))}
          {chatList?.filter((user) => !user?.isChatFinished)?.length === 0 && (
            <NoDataFoundCard />
          )}
          <MuiTypography variant="baseFont" fontWeight="bold" color="#0A1E41">
            Archived
          </MuiTypography>
          {chatList
            ?.filter((user) => user?.isChatFinished)
            .map((user) => (
              <ChatButton
                user={user}
                key={user?.id}
                onClick={() => handleOpenChat(user?.id)}
                chatSelected={chatSelected}
              />
            ))}
          {chatList?.filter((user) => user?.isChatFinished)?.length === 0 && (
            <NoDataFoundCard />
          )}
        </MuiBox>
      )}
      {/* </SimpleBar> */}
    </MuiBox>
  );
};

export default HelpAndSupportChatList;
