import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Facility } from "utils/validation/AddFacility";
import { UpdateFacilityAction } from "store/middlewares/facilities";

function ModalEditFacility({ row }) {
  const dispatch = useDispatch();

  const EditFacilitySchema = Yup.object({ facility: Facility });

  const handleSubmit = (values) => {
    dispatch(UpdateFacilityAction({ id: row.id, ...values }));
  };

  const formik = useFormik({
    initialValues: { facility: row.facility },
    validationSchema: EditFacilitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Facility"}
        error={formik.errors.facility && formik.touched.facility}
        errorMsg={formik.errors.facility}
        value={formik.values.facility}
        type="text"
        name="facility"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Facility"
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditFacility;
