import React from "react";
import { MuiBox, MuiTypography, Textfield } from "components/atoms";
import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";

import Block from "assets/images/SVG/blockModal.svg";
import DeleteChat from "assets/images/SVG/deleteChatModal.svg";
import DeleteMessage from "assets/images/SVG/deleteMessageModal.svg";
import Report from "assets/images/SVG/reportModal.svg";
import CheckboxBorder from "assets/images/SVG/checkbox border.svg";
import CheckboxIcon from "assets/images/SVG/check-square.svg";
const MessagsMenuModal = ({ type, onClick }) => {
  return (
    <MuiBox
      sx={{
        px: { xs: "15px", sm: "25px", md: "45px" },
        py: 5,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
      }}
    >
      <MuiBox sx={{ width: "110px", height: "110px" }}>
        {type === "deleteChat" && (
          <img src={DeleteChat} className="img-contain" />
        )}
        {type === "deleteMessage" && (
          <img src={DeleteMessage} className="img-contain" />
        )}
        {type === "deleteContact" && (
          <img src={DeleteMessage} className="img-contain" />
        )}
        {type === "block" && <img src={Block} className="img-contain" />}
        {type === "report" && <img src={Report} className="img-contain" />}
      </MuiBox>

      <MuiTypography variant="h5" fontWeight="bold">
        {type === "deleteChat" && "Delete Chat"}
        {type === "deleteMessage" && "Remove message"}
        {type === "block" && "Block"}
        {type === "report" && "Incident Report"}
      </MuiTypography>

      <MuiTypography
        align="center"
        color="#817D86"
        variant="baseFont"
        lineHeight="19.49px"
      >
        {type === "deleteChat" && (
          <>
            Are you sure you want to delete this chat? <br /> This action cannot
            be undone.
            <br /> Please confirm your decision.
          </>
        )}
        {type === "deleteMessage" && (
          <>
            Are you sure you want to remove this message?
            <br /> This action is permanent and cannot be undone.
            <br /> Confirm to proceed.
          </>
        )}
        {type === "block" && (
          <>
            Are you sure you want to block this user?
            <br /> This action cannot be undone.
            <br /> Please confirm your decision.
          </>
        )}
        {type === "deleteContact" && (
          <>
            Are you sure you want to delete this Contact?
            <br /> This action cannot be undone.
            <br /> Please confirm to proceed.
          </>
        )}
      </MuiTypography>

      {type === "report" && (
        <MuiBox>
          <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                  />
                }
                label="No longer wants to communicate"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                  />
                }
                label="Verbal, mental, and/or physical abuse"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                  />
                }
                label="Hazardous workplace"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                  />
                }
                label="Will not return to facility"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <img src={CheckboxBorder} className="checkbox-icon" />
                    }
                    checkedIcon={
                      <img src={CheckboxIcon} className="checkbox-icon" />
                    }
                  />
                }
                label="Spam or unsolicited messages"
              />
            </FormControl>
          </MuiBox>
          <MuiBox sx={{ mt: "20px" }}>
            <Textfield
              label={"Additional Notes"}
              className={"cancel-modal-textfield"}
              rows={3}
            />
          </MuiBox>
        </MuiBox>
      )}

      <Button
        variant="lightButton"
        sx={{ maxWidth: "368px" }}
        onClick={() => {
          if (type === "deleteContact") {
            onClick()
          }
        }}
      >
        {type === "deleteChat" ||
        type === "deleteMessage" ||
        type === "block" ||
        type === "deleteContact"
          ? "Confirm"
          : "Submit"}
      </Button>
    </MuiBox>
  );
};

export default MessagsMenuModal;
