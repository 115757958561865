import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Reason } from "utils/validation/AddDeleteReason";
import { AddRejectReasonAction } from "store/middlewares/RejectReason";

function ModalAddRejectReason() {
  const dispatch = useDispatch();

  const intitalVal = {
    reason: "",
  };

  const AddrejectSchema = Yup.object({ reason: Reason });

  const handleSubmit = (values) => {
    dispatch(AddRejectReasonAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddrejectSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Reason"}
        error={formik.errors.reason && formik.touched.reason}
        errorMsg={formik.errors.reason}
        value={formik.values.reason}
        type="text"
        name="reason"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Reject Reason"
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddRejectReason;
