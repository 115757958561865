import { IconButton } from "@mui/material";
import React from "react";
import CredentialsIcon from "assets/images/credentialsicon.svg";

const CredentialsButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{ padding: "0 !important" }}
      disableRipple
    >
      <div className="text-white btn bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] rounded-lg">
        <img src={CredentialsIcon} className="inline-block size-5 mr-1" />
        <span className="align-middle text-white">Credentials</span>
      </div>
    </IconButton>
  );
};

export default CredentialsButton;
