import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { AddReportAction } from "store/middlewares/reports";
import { Input, SubmitBtn } from "components/atoms";
import { ReportTitle } from "utils/validation/AddReport";

function ModalAddReport() {
  const dispatch = useDispatch();

  const intitalVal = {
    title: "",
  };

  const AddReportSchema = Yup.object({ title: ReportTitle });

  const handleSubmit = (values) => {
    dispatch(AddReportAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddReportSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Report Title"}
        error={formik.errors.title && formik.touched.title}
        errorMsg={formik.errors.title}
        value={formik.values.title}
        type="text"
        name="title"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Report Title"
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddReport;
