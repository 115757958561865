import { Box } from "@mui/material";
import React from "react";

const CustomLoader = () => {
  return (
    <Box className="mt-5 py-10 w-full h-full">
      <div className="flex flex-wrap justify-center items-center gap-4">
        <div className="inline-flex size-3 rounded-full opacity-100 animate-ping bg-[#0CA8DF]"></div>
        <div className="inline-flex size-3 rounded-full opacity-100 animate-ping bg-[#0CA8DF]"></div>
        <div className="inline-flex size-3 rounded-full opacity-100 animate-ping bg-[#0CA8DF]"></div>
      </div>
    </Box>
  );
};

export default CustomLoader;
