import { createSlice } from "@reduxjs/toolkit";
import {
  AddBlockReasonAction,
  DeleteBlockReasonAction,
  GetAllBlockReasonAction,
  UpdateBlockReasonAction,
} from "store/middlewares/blockReason";

// isDeleteMeFulFilled
export const blockReasonsSlice = createSlice({
  name: "blockReason",
  initialState: {
    blockReasonsLoading: false,
    blockReasons: [],
    blockReasonsFilterProps: {},
  },
  reducers: {
    setBlockReasonsFilterProps: (state, action) => {
      state.blockReasonsFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllBlockReasonAction.pending, (state) => {
        state.blockReasonsLoading = true;
        state.blockReasons = [];
      })
      .addCase(GetAllBlockReasonAction.fulfilled, (state, action) => {
        state.blockReasonsLoading = false;
        state.blockReasons = action.payload.response;
      })
      .addCase(GetAllBlockReasonAction.rejected, (state) => {
        state.blockReasonsLoading = false;
        state.blockReasons = [];
      })
      // Delete  block Reason
      .addCase(DeleteBlockReasonAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.blockReasons?.filter((title) => title.id !== id);
        state.blockReasonsLoading = false;
        state.blockReasons = tempArray;
      })
      //Update  block Reason
      .addCase(UpdateBlockReasonAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.blockReasons?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.blockReasonsLoading = false;
        state.blockReasons = tempArray;
      })
      //Add  block Reason
      .addCase(AddBlockReasonAction.fulfilled, (state, action) => {
        state.blockReasonsLoading = false;
        state.blockReasons = [action.payload.response, ...state.blockReasons];
      });
  },
});
export const { setBlockReasonsFilterProps } = blockReasonsSlice.actions;
export default blockReasonsSlice.reducer;
