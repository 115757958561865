import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const TooltipProvider = ({ children, title, placement = "top", ...rest }) => {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: "12px !important",
      borderRadius: "10px !important",
      padding: "10px 20px !important",
    },
  }));
  return (
    <StyledTooltip
      title={title}
      placement={placement}
      arrow
      {...rest}
    >
      {children}
    </StyledTooltip>
  );
};

export default TooltipProvider;
