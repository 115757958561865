import { IconButton, LinearProgress } from '@mui/material';
import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'

import CrossRed from 'assets/images/SVG/cross highlight.svg';
const CustomProgressbar = () => {
    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 800);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <MuiBox sx={{ p: { xs: '10px', sm: '15px' }, borderRadius: '12px', border: '1px solid #E7E7E7' }}>
            <MuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                <MuiBox sx={{ display: 'flex', flexDirection: 'column' }}>
                    <MuiTypography fontSize='14px' fontWeight='bold'>
                        Uploading...
                    </MuiTypography>
                    <MuiTypography fontSize='14px' color='text.contrastText'>
                        {`${Math.round(progress)}%`}
                    </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ width: 'fit-content' }}>
                    <IconButton>
                        <img src={CrossRed} />
                    </IconButton>
                </MuiBox>
            </MuiBox>
            <MuiBox sx={{ mt: '5px' }}>
                <LinearProgress variant="determinate" value={progress} />
            </MuiBox>
        </MuiBox>
    )
}

export default CustomProgressbar