import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllCredentialAvatarAction,
  GetAllCredentialLicencesAction,
  ManageAllCredentialAvatarAction,
  ManageAllCredentialLicencesAction,
  GetAllCredentialCertificateAction,
  GetAllCredentialServiceLicenceAction,
  GetAllCredentialSocialSecurityAction,
  ManageAllCredentialCertificateAction,
  ManageAllCredentialServiceLicenceAction,
  ManageAllCredentialSocialSecurityAction,
  GetProfessionalUserResume,
  UpdateProfessionalUserResume,
  GetAllCredentialAddressAction,
  ManageAllCredentialAddressAction,
  GetAllCredentialsAction,
  GetAllCredentialJobTitle,
  ManageCredentialJobTitle,
} from "store/middlewares/credential";

export const credentialSlice = createSlice({
  name: "credential",
  initialState: {
    avatarLoading: false,
    licenceLoading: false,
    socialSecurityLoading: false,
    certificateLoading: false,
    serviceLicenceLoading: false,
    resumesLoading: false,
    resumes: [],
    avatar: [],
    licence: [],
    socialSecurity: [],
    serviceLicence: [],
    certificate: [],
    physicalAddress: [],
    physicalAddressLoading: false,
    credentials: [],
    credentialsLoading: false,
    jobTitles: null,
    jobTitlesLoading: false,
    initialLoad: true,
  },
  reducers: {},
  extraReducers(builder) {
    builder

      //get avatar
      .addCase(GetAllCredentialAvatarAction.pending, (state) => {
        state.avatarLoading = true;
        state.avatar = [];
      })
      .addCase(GetAllCredentialAvatarAction.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.avatar = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialAvatarAction.rejected, (state) => {
        state.avatarLoading = false;
        state.avatar = [];
      })

      // manage avatar status
      .addCase(ManageAllCredentialAvatarAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.avatar?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.avatarLoading = false;
        state.avatar = tempArray;
      })

      //get licence
      .addCase(GetAllCredentialLicencesAction.pending, (state) => {
        state.licenceLoading = true;
        state.licence = [];
      })
      .addCase(GetAllCredentialLicencesAction.fulfilled, (state, action) => {
        state.licenceLoading = false;
        state.licence = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialLicencesAction.rejected, (state) => {
        state.licenceLoading = false;
        state.licence = [];
      })

      // manage licence status
      .addCase(ManageAllCredentialLicencesAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.licence?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.licenceLoading = false;
        state.licence = tempArray;
      })

      //get Physical Address
      .addCase(GetAllCredentialAddressAction.pending, (state) => {
        state.physicalAddressLoading = true;
        state.physicalAddress = [];
      })
      .addCase(GetAllCredentialAddressAction.fulfilled, (state, action) => {
        state.physicalAddressLoading = false;
        state.physicalAddress = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialAddressAction.rejected, (state) => {
        state.physicalAddressLoading = false;
        state.physicalAddress = [];
      })

      // manage Physical Address
      .addCase(ManageAllCredentialAddressAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.physicalAddress?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.physicalAddress = tempArray;
      })

      //get socialSecurity
      .addCase(GetAllCredentialSocialSecurityAction.pending, (state) => {
        state.socialSecurityLoading = true;
        state.socialSecurity = [];
      })
      .addCase(
        GetAllCredentialSocialSecurityAction.fulfilled,
        (state, action) => {
          state.socialSecurityLoading = false;
          state.socialSecurity = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialSocialSecurityAction.rejected, (state) => {
        state.socialSecurityLoading = false;
        state.socialSecurity = [];
      })

      // manage socialSecurity
      .addCase(
        ManageAllCredentialSocialSecurityAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.socialSecurity?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.socialSecurityLoading = false;
          state.socialSecurity = tempArray;
        }
      )

      //get ServiceLicence
      .addCase(GetAllCredentialServiceLicenceAction.pending, (state) => {
        state.serviceLicenceLoading = true;
        state.serviceLicence = [];
      })
      .addCase(
        GetAllCredentialServiceLicenceAction.fulfilled,
        (state, action) => {
          state.serviceLicenceLoading = false;
          state.serviceLicence = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
        }
      )
      .addCase(GetAllCredentialServiceLicenceAction.rejected, (state) => {
        state.serviceLicenceLoading = false;
        state.serviceLicence = [];
      })

      // manage ServiceLicence
      .addCase(
        ManageAllCredentialServiceLicenceAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.serviceLicence?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.serviceLicenceLoading = false;
          state.serviceLicence = tempArray;
        }
      )

      //get Certificate
      .addCase(GetAllCredentialCertificateAction.pending, (state) => {
        state.certificateLoading = true;
        state.certificate = [];
      })
      .addCase(GetAllCredentialCertificateAction.fulfilled, (state, action) => {
        state.certificateLoading = false;
        state.certificate = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialCertificateAction.rejected, (state) => {
        state.certificateLoading = false;
        state.certificate = [];
      })

      // manage Certificate status
      .addCase(
        ManageAllCredentialCertificateAction.fulfilled,
        (state, action) => {
          let obj = action.payload.response.data
            ? action.payload.response.data
            : action.payload.response;
          let { id, ...rest } = obj;
          let tempArray = state.certificate?.map((title) => {
            if (title.id === id) {
              return { ...title, ...rest };
            }
            return { ...title };
          });
          state.certificateLoading = false;
          state.certificate = tempArray;
        }
      )

      // For Get Resumes Data For User
      .addCase(GetProfessionalUserResume.pending, (state) => {
        state.resumes = [];
        state.resumesLoading = true;
      })
      .addCase(GetProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = action.payload.response;
        state.resumesLoading = false;
      })
      .addCase(GetProfessionalUserResume.rejected, (state) => {
        state.resumes = [];
        state.resumesLoading = false;
      })

      // For Get All Credentials
      .addCase(GetAllCredentialsAction.pending, (state) => {
        if (state.initialLoad) {
          state.credentialsLoading = true;
        }
        state.credentials = [];
      })
      .addCase(GetAllCredentialsAction.fulfilled, (state, action) => {
        state.credentials = action.payload.response || [];
        state.credentialsLoading = false;
        state.initialLoad = false;
      })
      .addCase(GetAllCredentialsAction.rejected, (state) => {
        state.credentials = [];
        state.credentialsLoading = false;
        state.initialLoad = false;
      })

      // For Update Status Of Resume
      .addCase(UpdateProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = state.resumes?.map((resume) => {
          if (resume?.id === action.payload.id) {
            return { ...resume, status: action.payload.status };
          }
          return { ...resume };
        });
        state.resumesLoading = false;
      })

      //get Job Title
      .addCase(GetAllCredentialJobTitle.pending, (state) => {
        state.jobTitlesLoading = true;
        state.jobTitles = null;
      })
      .addCase(GetAllCredentialJobTitle.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllCredentialJobTitle.rejected, (state) => {
        state.jobTitlesLoading = false;
        state.jobTitles = null;
      })

      // manage Job Title
      .addCase(ManageCredentialJobTitle.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        state.jobTitlesLoading = false;
        state.jobTitles = { ...state.jobTitles, ...obj };
      });
  },
});

export default credentialSlice.reducer;
