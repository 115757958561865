import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { CustomDatePicker, CustomTimePicker, MuiBox, MuiTypography } from 'components/atoms';
import { Button, IconButton } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers';

import PlusIcon from 'assets/images/SVG/plus.svg'

const AddScheduleModal = () => {
    // const [value, setValue] = React.useState(null);
    return (
        <MuiBox sx={{ px: { xs: "10px", sm: "20px", md: "30px" } }}>
            <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
                Add Schedule
            </MuiTypography>

            <MuiBox sx={{ mt: 5 }}>
                <MuiTypography variant="subtitle1" fontWeight={"bold"}>
                    Choose Date
                </MuiTypography>
                <Grid container spacing={'15px'} sx={{ mt: '20px' }}>
                    <Grid xs={6}>
                        <CustomDatePicker label={'Start Date'} />
                    </Grid>
                    <Grid xs={6}>
                        <CustomDatePicker label={'End Date'} />
                    </Grid>
                </Grid>
            </MuiBox>

            <MuiBox sx={{ mt: 5 }}>
                <MuiBox sx={{ display: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                    <MuiTypography variant="subtitle1" fontWeight={"bold"}>
                        Choose Date
                    </MuiTypography>
                    <IconButton className='plus-button-02 button-hover'>
                        <img src={PlusIcon} alt='add' style={{ width: '17px', height: '17px', objectFit: 'contain' }} />
                    </IconButton>
                </MuiBox>
                <Grid container spacing={'15px'} sx={{ mt: '20px' }}>
                    <Grid xs={6}>
                        <CustomTimePicker label={'Start Time'} />
                    </Grid>
                    <Grid xs={6}>
                        <CustomTimePicker label={'End Time'} />
                    </Grid>
                </Grid>
            </MuiBox>

            <MuiBox sx={{ textAlign: 'center', mt: 5 }}>
                <Button type='submit' variant='lightButton' sx={{ maxWidth: '354px' }}>Done</Button>
            </MuiBox>
        </MuiBox>
    );
};

export default AddScheduleModal;
