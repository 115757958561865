import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const Name = Yup.string()
  .required("Please enter hospital Name")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space")
  .matches(onlySpaceRegExp, "Hospital Name should have atleast  one character");

export const Role = Yup.string()
  .required("Please enter role")
  .matches(onlySpaceRegExp, "Roles cannot start with a space")
  .matches(
    startsWithSpaceRegExp,
    "Hospital Name should have atleast  one character"
  );

export const description = Yup.string()
  .required("Please enter description")
  .matches(startsWithSpaceRegExp, "Description cannot start with a space")
  .matches(onlySpaceRegExp, "Description should have atleast one character");

export const startDate = Yup.string().required("Please enter start date");
export const endDate = Yup.string().required("Please enter end date");
