import React, { useEffect } from "react";
import * as Yup from "yup";
import { BreadCrumb, Checkbox, Input, SubmitBtn } from "components/atoms";
import { useDispatch, useSelector } from "react-redux";
import { AddRoleAction } from "store/middlewares/roles";
import { useFormik } from "formik";
import { Access, Name } from "utils/validation/AddRole";
import { GetAllAccessAction } from "store/middlewares/access";

const RolesAdd = () => {
  const dispatch = useDispatch();

  const { access } = useSelector((state) => state.access);

  useEffect(() => {
    dispatch(GetAllAccessAction());
  }, [dispatch]);

  const intitalVal = {
    name: "",
    access: [],
  };

  const AddRoleSchema = Yup.object({ name: Name, access: Access });

  const handleSubmit = (values) => {
    let tempArray = values?.access?.filter((acc) => acc.permissions.length > 0);
    dispatch(AddRoleAction({ ...values, access: tempArray }));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <BreadCrumb title="Add Role" pageTitle={"Dashboard"} />
      <div className="card mt-5">
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <Input
              inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
              label={"Name"}
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.name && formik.touched.name}
              errorMsg={formik.errors.name}
              value={formik.values.name}
              placeholder="Enter Name"
            />
            <table className="w-full mt-4 ">
              <thead>
                <tr>
                  <td colSpan={3}>Access</td>
                  <td>Permissions</td>
                </tr>
              </thead>
              <tbody>
                {access
                  ?.filter((item) => item?.permission?.length > 0)
                  ?.map((item, index) => {
                    let selectedPermissions = formik?.values?.access?.find(
                      (acc) => acc?.accessId === item?.id
                    )?.permissions;
                    return (
                      <tr key={item?.id}>
                        <td colSpan={3}>{item?.name}</td>
                        <td>
                          <Checkbox
                            options={item?.permission?.map((perm) => {
                              return {
                                label:
                                  perm.charAt(0).toUpperCase() +
                                  perm.slice(1).toLowerCase(),
                                value: perm,
                              };
                            })}
                            value={selectedPermissions}
                            onChange={(e) => {
                              let tempArray = selectedPermissions ?? [];
                              if (!e.target.checked) {
                                tempArray = tempArray?.filter(
                                  (item) => item !== e.target.value
                                );
                              } else {
                                tempArray = [...tempArray, e.target.value];
                              }
                              formik.setFieldValue(
                                `access[${index}].accessId`,
                                item?.id
                              );
                              formik.setFieldValue(
                                `access[${index}].permissions`,
                                tempArray
                              );
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <SubmitBtn title={"Add"} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default RolesAdd;
