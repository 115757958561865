import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { Grid } from "@mui/material";
import BlankSvg from "assets/images/blank.png";
import { name, Price } from "utils/validation/AddBadges";
import { UpdateBadgesAction } from "store/middlewares/badges";

function ModalEditBanner({ row }) {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(BlankSvg);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = (values) => {
    values.price = String(values.price);
    let payload = {
      price: String(values.price),
      name: values?.name,
      id: row.id,
    };
    if (values?.file) {
      payload = { ...payload, file: values.file };
    } else {
      payload = { ...payload, image: row.image };
    }
    dispatch(UpdateBadgesAction(payload));
  };

  useEffect(() => {
    setImageSrc(row?.imageUrl);
  }, [row]);

  const EditBadgesSchema = Yup.object({
    name: name,
    file: null,
    price: Price,
  });

  const intitalVal = {
    ...row,
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: EditBadgesSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024; // 3MB limit
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("file", e.target.files[0]);
          let extension = e.target.files[0]?.type;
          let isImage = extension?.includes("image");
          formik.setFieldValue("fileType", isImage ? "image" : "video");
          setErrorMessage("");
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage(
          "Please select a valid image file with size up to 3MB."
        );
        formik.setFieldValue("fileType", "");
        formik.setFieldValue("file", null);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="">
        <label className="font-medium">
          Badges Image <span className="text-[#FF5C5C]">*</span>
        </label>
        <br />
        <div className="flex flex-col mt-2 gap-x-2.5">
          <div className="relative">
            <div className="h-48 w-48 bg-white">
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg,image/jpg"
                id="editimg"
                onChange={(e) => {
                  handleFileChange(e);
                  formik.setFieldValue("file", e.target.files[0]);
                }}
                hidden
              />
              <label htmlFor="editimg" className="cursor-pointer">
                <div className="w-48 h-48 overflow-hidden">
                  <img
                    src={imageSrc}
                    alt="flag"
                    className="w-full h-full object-cover"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        {((formik.touched.file && formik.errors.file) || errorMessage) && (
          <div className="text-red-500 text-sm mt-1">
            {formik.errors.file ? formik.errors.file : errorMessage}
          </div>
        )}
      </div>
      <Grid container columnSpacing={5} rowSpacing={3}>
        <Grid item md={6} xs={12}>
          <Input
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"Name"}
            error={formik.errors.name && formik.touched.name}
            errorMsg={formik.errors.name}
            value={formik?.values?.name}
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter name"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"Price"}
            error={formik.errors.price && formik.touched.price}
            errorMsg={formik.errors.price}
            value={formik?.values?.price}
            type="number"
            name="price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter price"
          />
        </Grid>
      </Grid>

      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditBanner;
