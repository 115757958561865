import axiosClient from "utils/api";
const URI = "/admin/province";

export const getProvinces = async () => {
  const URL = `${URI}/province`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All province:", error);
    throw error;
  }
};

export const deleteProvince = async (payload) => {
  const URL = `${URI}/province/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete province:", error);
    throw error;
  }
};

export const updateProvince = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/province/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update province:", error);
    throw error;
  }
};

export const updateVisiblityProvince = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Visiblity province:", error);
    throw error;
  }
};


export const addProvince = async (payload) => {
  const URL = `${URI}/province`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add province:", error);
    throw error;
  }
};
