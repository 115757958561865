/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from "axios";
import { getDecryptedToken } from "./CryptoJS";
// import Cookies from "universal-cookie";

axios.defaults.withCredentials = false;
let apiURL = "";
let accountURL = "";
let adminURL = "";

/**
 * Configuration options for the you only environment.
 * @type {Object}
 */
const aboutCare = {
  name: "aboutCare",
  account: "",
  api: "https://dev-app.vitalmedicalstaffing.com",
  live: "https://dev-admin.vitalmedicalstaffing.com/",
  admin: "https://dev-admin.vitalmedicalstaffing.com/admin",
};

/**
 * The domain of the application. If you're running the application locally, make sure to add the domain to the .env file.
 * @type {string}
 */
// eslint-disable-next-line no-undef
export const domain = process.env.REACT_APP_DOMAIN;

/**
 * Configuration options for the localhost environment.
 * @type {Object}
 */
const localhost = {
  account: "http://localhost:3000/",
  api: "https://dev-app.vitalmedicalstaffing.com",
  admin: "http://localhost:3000/admin",
};

const path = window.location.href;
const live = !!path.match(aboutCare.live);

if (live) {
  accountURL = aboutCare.account;
  apiURL = aboutCare.api;
  adminURL = aboutCare.admin;
} else {
  accountURL = localhost.account;
  apiURL = localhost.api;
  adminURL = localhost.admin;
}

export const API_URL = apiURL;
export const ACCOUNT_URL = accountURL;

/**
 * The axios client function for making HTTP requests to api.
 * @function axiosClient
 */
export function axiosClient() {
  // gets the email and refresh cookies
  const adminToken = JSON.parse(localStorage.getItem("aboutcare-admin"))?.token;
  const webToken = JSON.parse(localStorage.getItem("aboutcare-web"))?.token;
  let token = "";

  const path = window.location.href;
  const admin = path.includes(adminURL);
  if (admin) {
    token = adminToken;
  } else {
    token = webToken;
  }

  const defaultOptions = {
    baseURL: apiURL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      // "Access-Control-Allow-Credentials": true,
      accept: "application/json",
    },
  };

  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  if (token) {
    instance.interceptors.request.use((config) => {
      config.headers.common = {
        Authorization: `Bearer ${getDecryptedToken(token)}`,
      };
      return config;
    });

    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${getDecryptedToken(token)}`;
  }

  return instance;
}

export default axiosClient;
