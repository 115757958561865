import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import {
  getFeatures,
  deleteFeatures,
  updateFeatures,
  addFeatures,
  updateVisiblityFeatures,
} from "store/services/features";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

//Get All Cities
export const GetAllFeaturesAction = createAsyncThunk(
  "features/getAllFeatures",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getFeatures();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Delete City
export const DeleteFeaturesAction = createAsyncThunk(
  "features/deleteFeatures",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteFeatures(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update City
export const UpdateFeaturesAction = createAsyncThunk(
  "features/updateFeatures",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateFeatures(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Add Features
export const AddFeaturesAction = createAsyncThunk(
  "features/addFeatures",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addFeatures(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Visiblity Features
export const UpdateVisiblityFeaturesAction = createAsyncThunk(
  "features/updateVisiblityFeatures",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblityFeatures(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
