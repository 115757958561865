import { IconButton } from "@mui/material";
import React from "react";
import Back from "assets/images/SVG/backArrow.svg";
import { useNavigate } from "react-router-dom";
const BackButton = ({ onClickFunc }) => {
  const navigate = useNavigate();
  return (
    <IconButton
      aria-label="back"
      className="back-button button-hover"
      onClick={onClickFunc ? onClickFunc : () => navigate(-1)}
    >
      <img src={Back} alt="Back" />
    </IconButton>
  );
};

export default BackButton;
