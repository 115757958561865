import axiosClient from "utils/api";
const URI = "/admin/adminList";

// For get all users
export const getAccessManagementUsers = async () => {
    const URL = `${URI}/admin`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "get",
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred Get All Access Management Users:", error);
        throw error;
    }
};

// For add users
export const addAccessManagementUsers = async (payload) => {
    const URL = `${URI}/admin`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "post",
            data: payload,
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred Add Access Management Users:", error);
        throw error;
    }
}

// For edit users
export const editAccessManagementUsers = async (payload) => {
    const { id, ...rest } = payload;
    const URL = `${URI}/admin/${id}`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "put",
            data: rest,
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred Edit Access Management Users:", error);
        throw error;
    }
}

// For delete users
export const deleteAccessManagementUsers = async (payload) => {
    const { id } = payload;
    const URL = `${URI}/admin/${id}`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "delete",
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred Delete Access Management Users:", error);
        throw error;
    }
}