import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { AddCityAction } from "store/middlewares/cities";
import { Name, Province } from "utils/validation/AddCity";

function ModalAddCities() {
  const dispatch = useDispatch();

  const { provinces } = useSelector((state) => state.provinces);

  const intitalVal = {
    name: "",
    provinceId: "",
  };

  const AddCategorySchema = Yup.object({ name: Name, provinceId: Province });

  const handleSubmit = (values) => {
    dispatch(AddCityAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SelectComponent
        className={"px-0"}
        label={"Province"}
        options={provinces?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Province"
        name={"provinceId"}
        value={formik.values.provinceId}
        error={formik.errors.provinceId && formik.touched.provinceId}
        errorMsg={formik.errors.provinceId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "provinceId",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddCities;
