import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllFaqsVotesAction } from "store/middlewares/HelpAndSupport";
import { CustomLoader, TableContainer } from "components/atoms";
import { Typography } from "@mui/material";
import blankImg from "assets/images/blank.png";
const ModalVotes = ({ row, voteType }) => {
  const { votes, votesLoading } = useSelector((state) => state.helpAndSupport);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllFaqsVotesAction({ id: row.original.id, type: voteType }));
  }, [dispatch]);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Image",
        accessorKey: "firstName",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <div className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover pointer-events-none "
              src={row.original.profileImageUrl ? row.original.profileImageUrl : blankImg}
              alt={row.original.firstName}
            />
          </div>
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  );
  return (
    <>
      <div className="h-[60vh] w-full no-scrollbar flex flex-col gap-3">
        <div className="p-2 flex flex-col gap-1 rounded-md bg-gray-100">
          <div className="flex gap-1">
            <p className="font-bold text-[1rem]">Question:</p>
            <p className="text-[1rem] font-thin">{row.original.question}</p>
          </div>
          <div className="flex gap-1">
            <p className="font-bold text-[1rem]">Answer:</p>
            <p className="text-[1rem] font-thin">{row.original.answer}</p>
          </div>
          <div className="flex gap-1">
            <p className="font-bold text-[1rem]">Total Votes:</p>
            <p className="text-[1rem] font-thin">{row.original.upVotes}</p>
          </div>
        </div>
        <div className="w-full h-full bg-gray-100 overflow-auto no-scrollbar p-2 rounded-md">
          {votesLoading && <CustomLoader />}
          {votes === undefined && (
            <div className="flex justify-center items-center w-full h-full">
              No Votes
            </div>
          )}
          {votes && (
            <TableContainer
              isPagination={false}
              isSelect={true}
              isGlobalFilter={false}
              columns={columns}
              data={votes}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ModalVotes;
