import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeToast } from "store/slices/common/toast.slice";
import { Alert, IconButton, Grow, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";

const ToastWrapper = () => {
  const dispatch = useDispatch();
  const { isOpen, message, type } = useSelector((state) => state.toast);
  const handleClose = () => {
    dispatch(closeToast());
  };

  const action = (
    <IconButton
      onClick={handleClose}
      size="small"
      aria-label="close"
      color="inherit"
    >
      <Close fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      autoHideDuration={2000}
      action={action}
      onClose={handleClose}
      TransitionComponent={Grow}
      sx={{ zIndex: 2000, mt: "2rem" }}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default ToastWrapper;
