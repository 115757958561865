import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { IconButton } from "@mui/material";
import ConfirmModal from "../ConfirmModal";
import { openDrawer } from "store/slices/common/drawer.slice";

import { useDispatch } from "react-redux";

import Certificate from "assets/images/PNG/certificate.png";
import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
const CertificateCard = ({
  isRejected = false,
  isAccepted = false,
  inReview = false,
}) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        p: "10px",
        py: { xs: "10px", sm: "20px" },
        bgcolor: "#fff",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        borderRadius: "12px",
        position: "relative",
        mt: 3,
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {isRejected || isAccepted || inReview ? (
        <MuiBox
          sx={{
            width: "fit-content",
            p: "5px 10px",
            borderRadius: "12px",
            bgcolor:
              (isRejected && "#FF5C5C") ||
              (isAccepted && "#00AE46") ||
              (inReview && "#E18308"),
            position: "absolute",
            top: "-16px",
            right: { xs: "15px", md: "50px" },
            zIndex: 1,
          }}
        >
          <MuiTypography fontSize="14px" fontWeight="bold" color="#fff">
            {isRejected && "Rejected"}
            {isAccepted && "Accepted"}
            {inReview && "In Review"}
          </MuiTypography>
        </MuiBox>
      ) : null}
      <MuiBox
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          flexWrap: { xs: "wrap", md: "nowrap" },
        }}
      >
        <MuiBox
          sx={{
            width: "126px",
            height: "80px",
            borderRadius: "12px",
            overflow: "hidden",
            flexShrink: 0,
            border: "1px solid #D5D4D7",
          }}
        >
          <img src={Certificate} className="img-cover" />
        </MuiBox>
        <MuiBox
          sx={{ display: "flex", flexDirection: "column", maxWidth: "12em" }}
        >
          <MuiTypography
            fontSize="14px"
            fontWeight="bold"
            color="text.dark"
            lineHeight="17.05px"
          >
            Certificate Of Stanford Medicine
          </MuiTypography>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          width: { xs: "100%", md: "fit-content" },
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexDirection: {
            xs: "row",
            md: "column",
            justifyContent: "flex-start",
          },
        }}
      >
        <IconButton
          sx={{
            p: "5px",
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
          }}
        >
          <img src={EditIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
        <IconButton
          sx={{
            p: "5px",
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
          }}
          onClick={() =>
            dispatch(
              openDrawer({
                content: (
                  <ConfirmModal
                    type="delete"
                    title="Delete Certificate"
                    message={
                      <>
                        Are you sure you want to delete your certificate? <br />
                        This action is permanent and cannot be undone.
                      </>
                    }
                    btnText={"Delete Certificate"}
                  />
                ),
              })
            )
          }
        >
          <img src={DeleteIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
      </MuiBox>
    </MuiBox>
  );
};

export default CertificateCard;
