import { IconButton } from "@mui/material";
import React from "react";
import { Settings } from "lucide-react";
import { useDispatch } from "react-redux";
// import { openModal } from "store/slices/common/modal.slice";
import { ProfessionalNotificationTab } from "components/molecules";
import { openAdminModal } from "store/slices/common/adminModal.slice";

const EditButton = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      sx={{ padding: "0 !important" }}
      disableRipple
      onClick={() =>
        dispatch(
          openAdminModal({
            title: "Notification Configuration",
            content: <ProfessionalNotificationTab id={id} />,
          })
        )
      }
    >
      <div className="text-slate-500 btn bg-[#4716dabb] border-slate-200 hover:bg-[#7d52fd] hover:border-slate-30">
        <Settings className="inline-block text-vertical-menu size-5 mr-1" />
        <span className="align-middle text-white">Notification Config</span>
      </div>
    </IconButton>
  );
};

export default EditButton;
