import React, { useEffect, useState } from "react";
import { AddButton, CustomLoader, NoDataFound } from "components/atoms";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllCredentialAvatarAction,
  ManageAllCredentialAvatarAction,
} from "store/middlewares/credential";
import { DeleteButton } from "components/atoms";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";
import checkAccess from "utils/checkAccess";

function Avatar({ userId }) {
  const dispatch = useDispatch();
  const { avatar, avatarLoading } = useSelector((state) => state.credential);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to open/close the modal
  const toggleModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    dispatch(GetAllCredentialAvatarAction({ id: userId }));
  }, [dispatch, userId]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">Avatar : </h6>
          {!avatarLoading ? (
            <>
              {avatar && avatar.length > 0 ? (
                <div className="grid grid-cols-2 gap-4">
                  {avatar.map((item) => (
                    <div className="card" key={item?.id}>
                      <div className="card-body px-0">
                        <div className="relative overflow-hidden rounded-md group/gallery">
                          <div className="overflow-hidden">
                            <img
                              src={item?.imageUrl}
                              alt=""
                              className="transition-all w-full h-[300px] object-cover duration-300 ease-linear group-hover/gallery:scale-110 "
                              onClick={() => toggleModal(item.imageUrl)}
                            />
                          </div>
                        </div>
                        <div className="text-end mt-2">
                          {item?.status !== "accepted" && checkAccess("update_credentials") && (
                              <AddButton
                                title="Accept"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to accept this avatar?",
                                    imageUrl: ApprovedSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialAvatarAction({
                                          id: item.id,
                                          status: "accepted",
                                        })
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          {item?.status !== "rejected" && checkAccess("update_credentials") && (
                              <DeleteButton
                                title="Reject"
                                style="ml-2"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to reject this avatar?",
                                    imageUrl: RejectSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialAvatarAction({
                                          id: item.id,
                                          status: "rejected",
                                        })
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
          {isModalOpen && selectedImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[999999999999]"
              onClick={toggleModal}
            >
              <img
                src={selectedImage}
                alt=""
                className="max-h-full max-w-full rounded-md pointer-events-none "
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Avatar;
