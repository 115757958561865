// // import React, { useRef } from 'react';

// const extractThumbnail = (file, setThumbnail) => {
//   const video = document.createElement('video');
//   const canvas = document.createElement('canvas');
  
//   const videoUrl = URL.createObjectURL(file);
//   video.src = videoUrl;

//   video.onloadedmetadata = () => {
//     // Set the time to capture the frame (e.g., 1 second)
//     video.currentTime = 1;
//   };

//   video.onseeked = () => {
//     canvas.width = video.videoWidth;
//     canvas.height = video.videoHeight;

//     const ctx = canvas.getContext('2d');
//     ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

//     // Generate thumbnail as a data URL
//     const thumbnailUrl = canvas.toDataURL('image/png');
//     setThumbnail(thumbnailUrl);

//     // Cleanup created objects
//     URL.revokeObjectURL(videoUrl);
//   };

//   video.onerror = () => {
//     console.error('Error processing the video file.');
//     URL.revokeObjectURL(videoUrl);
//   };
// };

// export default extractThumbnail;

const extractThumbnail = (file, setThumbnail) => {
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");

  const videoUrl = URL.createObjectURL(file);
  video.src = videoUrl;

  video.onloadedmetadata = () => {
    // Set the time to capture the frame (e.g., 1 second)
    video.currentTime = 1;
  };

  video.onseeked = () => {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Generate thumbnail as a Blob
    canvas.toBlob(
      (blob) => {
        if (blob) {
          // Convert Blob to File
          const thumbnailFile = new File([blob], "thumbnail.png", {
            type: "image/png",
          });
          setThumbnail(thumbnailFile);
        }
        // Cleanup created objects
        URL.revokeObjectURL(videoUrl);
      },
      "image/png",
      1 // Quality parameter
    );
  };

  video.onerror = () => {
    console.error("Error processing the video file.");
    URL.revokeObjectURL(videoUrl);
  };
};

export default extractThumbnail;
