import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'
import { Link } from 'react-router-dom'

import Professional from 'assets/images/PNG/professional2.png'
import Beg from 'assets/images/SVG/beg.svg'
import Star from 'assets/images/SVG/Star.svg'
import Calender from 'assets/images/SVG/Calendar black.svg'
import Clock from 'assets/images/SVG/clock 2.svg'
import Clock2 from 'assets/images/SVG/clockShift.svg';

const ScheduleCard = ({ type, redirectTo }) => {
    const redirectUrl = () => {
        const routes = redirectTo === 'hospital' ?
            {
                upcoming: '/hospital/schedule/upcoming/schedule-details',
                inprogress: '/hospital/schedule/inprogress/schedule-details',
                completed: '/hospital/schedule/completed/schedule-details',
                cancelled: '/hospital/schedule/cancelled/schedule-details',
            } : {
                upcoming: '/professional/schedule/upcoming/schedule-details',
                inprogress: '/professional/schedule/inprogress/schedule-details',
                completed: '/professional/schedule/completed/schedule-details',
                cancelled: '/professional/schedule/cancelled/schedule-details',
            }

        return routes[type] || '/hospital/schedule'; // Fallback to a default route if `type` is invalid
    };

    return (
        <MuiBox component={Link} to={redirectUrl()} sx={{ background: '#fff', borderRadius: '12px', p: { xs: 1, sm: 2 }, display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <MuiBox sx={{ borderRadius: '9px', overflow: 'hidden', height: '206px' }}>
                <img src={Professional} alt='professional' style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
            </MuiBox>
            <MuiBox>
                <MuiTypography color='text.main' variant='subtitle1' fontWeight='bold'>
                    Madelyn Lipshutz
                </MuiTypography>
                <MuiTypography color='text.contrastText' sx={{ fontSize: '14px', width: '6em', overflow: 'hidden' }}>
                    Renee Dunn
                </MuiTypography>
            </MuiBox>
            <MuiBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, flexWrap: 'wrap' }}>
                <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <MuiBox sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', gap: '5px' }}>
                        <MuiBox sx={{ width: '26px', height: '26px', borderRadius: '6px', bgcolor: '#E8F0F3', p: '5px' }}>
                            <img src={Beg} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                        </MuiBox>
                        <MuiTypography color='text.main' fontWeight='bold' sx={{ fontSize: '14px' }}>
                            2 years
                        </MuiTypography>
                    </MuiBox>
                    <MuiBox sx={{ display: 'flex', width: 'fit-content', alignItems: 'center', gap: '5px', bgcolor: '#FFF7E7', borderRadius: '6px', p: '5px' }}>
                        <MuiBox sx={{ width: '18px', height: '18px' }}>
                            <img src={Star} style={{ height: '100%', width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                        </MuiBox>
                        <MuiTypography color='text.contrastText' sx={{ fontSize: '14px' }}>
                            4.5 (111)
                        </MuiTypography>
                    </MuiBox>
                </MuiBox>
                <MuiBox sx={{ width: 'fit-content', bgcolor: '#E8F0F3', borderRadius: '6px', p: '5px 10px' }}>
                    <MuiTypography color='text.dark' sx={{ fontSize: '14px' }} fontWeight='bold'>
                        #X034Y6Z
                    </MuiTypography>
                </MuiBox>
            </MuiBox>

            <MuiBox sx={{ bgcolor: '#CCEEFA', borderRadius: '12px', p: '8px 16px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                <MuiBox sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                    <MuiBox sx={{ width: 'fit-content', display: 'flex', gap: 1 }}>
                        <img src={Calender} style={{ height: '20px', width: '24px', objectFit: 'contain' }} />
                        <MuiTypography fontSize='14px' color='text.main'>
                            20/07/2024
                        </MuiTypography>
                    </MuiBox>

                    {type === 'inprogress' && (
                        <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: '4px', bgcolor: '#fff', width: 'fit-content', p: '5px 10px', borderRadius: '6px' }}>
                            <img src={Clock2} style={{ height: '20px', width: '24px', objectFit: 'contain' }} />
                            <MuiTypography color='#07B67C' fontSize='12px' sx={{ textWrap: 'nowrap' }}>
                                Clock in
                            </MuiTypography>
                        </MuiBox>
                    )}
                    {type === 'completed' && (
                        <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: '4px', bgcolor: '#07B67C', width: 'fit-content', p: '5px 10px', borderRadius: '6px' }}>
                            <MuiTypography color='#fff' fontSize='12px' sx={{ textWrap: 'nowrap' }}>
                                Completed
                            </MuiTypography>
                        </MuiBox>
                    )}
                    {type === 'cancelled' && (
                        <MuiBox sx={{ display: 'flex', alignItems: 'center', gap: '4px', bgcolor: '#FF575A', width: 'fit-content', p: '5px 10px', borderRadius: '6px' }}>
                            <MuiTypography color='#fff' fontSize='12px' sx={{ textWrap: 'nowrap' }}>
                                Cancelled
                            </MuiTypography>
                        </MuiBox>
                    )}
                </MuiBox>
                <MuiBox sx={{ width: 'fit-content', display: 'flex', gap: 1 }}>
                    <img src={Clock} style={{ height: '20px', width: '24px', objectFit: 'contain' }} />
                    <MuiTypography fontSize='14px' color='text.main'>
                        10:00 AM - 7:30 PM
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
        </MuiBox>
    )
}

export default ScheduleCard