import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import ApplicantImg from "assets/images/PNG/applicant.png";
import ChatIcon from "assets/images/SVG/Bubble chat.svg";
import PhoneIcon from "assets/images/SVG/Smartphone.svg";
const ApplicantCard = () => {
  return (
    <MuiBox
      component={Link}
      sx={{
        borderRadius: "12px",
        bgcolor: "#fff",
        p: { xs: "8px", sm: "12px" },
        display: "flex",
        gap: 1,
      }}
    >
      <MuiBox sx={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
        <MuiBox
          sx={{
            width: "81px",
            height: "81px",
            borderRadius: "9px",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          <img src={ApplicantImg} alt="Applicant" className="img-cover" />
        </MuiBox>
        <MuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            maxWidth: "9em",
          }}
        >
          <MuiTypography color="text.main" variant="baseFont" fontWeight="bold">
            Aspen Press
          </MuiTypography>
          <MuiTypography color="text.contrastText" fontSize="14px">
            MA (Medical Assistant)
          </MuiTypography>
          <MuiBox
            sx={{
              width: "fit-content",
              p: "5px 12px",
              bgcolor: "#FFDFC3",
              borderRadius: "30px",
            }}
          >
            <MuiTypography color="#2D2635" fontSize="12px">
              Response Pending
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      <MuiBox
        sx={{
          display: "flex",
          gap: "12px",
          width: "fit-content",
          flexDirection: "column",
        }}
      >
        <IconButton
          component={Link}
          aria-label="phone"
          sx={{
            height: "30px",
            width: "30px",
            bgcolor: "darkButton.main",
            transition: "all 0.3s",
            borderRadius: "6px",
            overflow: "hidden",
            p: "5px",
            "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
          }}
        >
          <img src={PhoneIcon} />
        </IconButton>
        <IconButton
          component={Link}
          aria-label="phone"
          sx={{
            height: "30px",
            width: "30px",
            bgcolor: "darkButton.main",
            transition: "all 0.3s",
            borderRadius: "6px",
            overflow: "hidden",
            p: "5px",
            "&:hover": { bgcolor: "#2f3f5a", borderRadius: "50%" },
          }}
        >
          <img src={ChatIcon} />
        </IconButton>
      </MuiBox>
    </MuiBox>
  );
};

export default ApplicantCard;
