import { User } from "lucide-react";
import React from "react";
import CountUp from "react-countup";
import SimplePie from "../SimplePie";

function DashboardDetailCard({ title, dashbordData }) {
  const { plansWithSubscribers } = dashbordData;

  return (
    <div className="card mb-10">
      <div className="bg-slate-200 card-body rounded-t-md">
        <h6 className="text-15 font-bold">{`${title}`}</h6>
      </div>
      <div className="card-body">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-2 items-center">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-2 items-center">
            {plansWithSubscribers?.map((plan) => (
              <div
                className="flex flex-col gap-6 justify-between"
                key={plan.id}
              >
                <div
                  key={plan.id}
                  className={`p-6 text-center flex items-center justify-between bg-green-100 border border-green-200 rounded-md`}
                >
                  <div
                    className={`flex items-center justify-center size-12 my-auto rounded-md bg-green-50 text-15`}
                  >
                    <User className={`text-green-500`} />
                  </div>
                  <div className="flex flex-col items-end">
                    <h4 className={`mb-1 text-green-500 text-4xl`}>
                      <CountUp
                        className="counter-value"
                        end={plan._count?.Subscription}
                      />
                    </h4>
                    <p className={`mb-0 text-green-500 uppercase`}>
                      {plan.planTitle}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <SimplePie
              chartId="allPlans"
              dataSeries={plansWithSubscribers?.map(
                (plan) => plan._count?.Subscription || 0
              )}
              dataLabelsChart={plansWithSubscribers?.map(
                (plan) => plan.planTitle
              )}
              dataColorCodes={
                '["bg-green-300","bg-red-300","bg-orange-300", "bg-blue-300", "bg-yellow-300"]'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardDetailCard;
