import axiosClient from "utils/api";

export const getAddressForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/address/addresses",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Address", error);
    throw error;
  }
};

export const addAddressForWeb = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/address/address",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Add Address", error);
    throw error;
  }
};

export const editAddressForWeb = async (payload) => {
  try {
    let { id, ...rest} = payload
    const response = await axiosClient().request({
      url: `/address/address/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Edit Address", error);
    throw error;
  }
};

export const deleteAddressForWeb = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/address/address/${payload.id}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Delete Address", error);
    throw error;
  }
};

export const setDefaultAddress = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/credential/address/${payload.id}/default`,
      method: "put",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Delete Address", error);
    throw error;
  }
};
