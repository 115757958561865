import React from "react";

const DateInput = ({
  onChange,
  onBlur,
  type = "text",
  value,
  error,
  errorMsg,
  placeholder,
  label,
  className,
  name,
  inputClassName,
  labelClassName,
  isRequired = true,
  disabled = false,
}) => {
  return (
    <div className={`py-2 ${className}`}>
      {label && (
        <>
          <label htmlFor={name} className={`font-medium ${labelClassName}`}>
            {label} {isRequired && <span className="text-[#FF5C5C]">*</span>}
          </label>
          <br />
        </>
      )}
      <input
      
        disabled={disabled}
        type={type}
        name={name}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
        }}
        onKeyDown={(e) => e.preventDefault()}
        value={value}
        placeholder={placeholder}
        className={`form-input focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300 disabled:text-slate-500 placeholder:text-slate-400 ${inputClassName} ${
          error ? "border-red-500" : "border-slate-200"
        } `}
      />
      {error ? (
        <div className="mt-1 text-sm text-red-500">{errorMsg}</div>
      ) : null}
    </div>
  );
};

export default DateInput;
