import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { IconButton } from "@mui/material";
import ConfirmModal from "../ConfirmModal";
import { openDrawer } from "store/slices/common/drawer.slice";
import { useDispatch } from "react-redux";

import ResumeIcon from "assets/images/SVG/resume icon.svg";
import EditIcon from "assets/images/SVG/edit icon white.svg";
import DeleteIcon from "assets/images/SVG/delete icon black.svg";
const ResumeCard = ({
  isRejected = false,
  isAccepted = false,
  inReview = false,
}) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        p: "10px",
        py: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #D5D4D7",
        borderRadius: "12px",
        position: "relative",
        mt: 1,
      }}
    >
      {isRejected || isAccepted || inReview ? (
        <MuiBox
          sx={{
            width: "fit-content",
            p: "5px 10px",
            borderRadius: "12px",
            bgcolor:
              (isRejected && "#FF5C5C") ||
              (isAccepted && "#00AE46") ||
              (inReview && "#E18308"),
            position: "absolute",
            top: "-16px",
            right: "15px",
            zIndex: 1,
          }}
        >
          <MuiTypography fontSize="14px" fontWeight="bold" color="#fff">
            {isRejected && "Rejected"}
            {isAccepted && "Accepted"}
            {inReview && "In Review"}
          </MuiTypography>
        </MuiBox>
      ) : null}
      <img src={ResumeIcon} />
      <MuiBox sx={{ display: "flex", flexDirection: "column" }}>
        <MuiTypography variant="baseFont" fontWeight="bold">
          Resume 4
        </MuiTypography>
        <MuiTypography fontSize="14px" color="#ABA8AE">
          Last Update 5 day ago
        </MuiTypography>
      </MuiBox>
      <MuiBox
        sx={{
          width: "fit-content",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <IconButton
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
            p: "5px",
          }}
        >
          <img src={EditIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
        <IconButton
          sx={{
            width: "30px",
            height: "30px",
            borderRadius: "8px",
            bgcolor: "#E8F0F3",
            p: "5px",
          }}
          onClick={() =>
            dispatch(
              openDrawer({
                content: (
                  <ConfirmModal
                    type="delete"
                    title="Delete Resume"
                    message={
                      <>
                        Are you sure you want to delete your resume? <br />
                        This action is permanent and cannot be undone.
                      </>
                    }
                    btnText={"Delete Resume"}
                  />
                ),
              })
            )
          }
        >
          <img src={DeleteIcon} style={{ width: "16px", height: "16px" }} />
        </IconButton>
      </MuiBox>
    </MuiBox>
  );
};

export default ResumeCard;
