import { User, UserMinus, UserRoundX } from 'lucide-react'
import React from 'react'
import CountUp from 'react-countup'
import SimplePie from '../SimplePie'

function DashboardDetailCard({ title, dashbordData }) {
    const { totalActiveHospital, totalActiveProfessional, totalBannedHospital, totalBannedProfessional, totalDeletedHospital, totalDeletedProfessional } = dashbordData

    let totalActive = title === 'Hospital' ? totalActiveHospital : totalActiveProfessional;
    let totalBanned = title === 'Hospital' ? totalBannedHospital : totalBannedProfessional;
    let totalDeleted = title === 'Hospital' ? totalDeletedHospital : totalDeletedProfessional;

    return (
        <div className="card mb-10">
            <div className="bg-slate-200 card-body rounded-t-md">
                <h6 className="text-15 font-bold">{`${title} Users`}</h6>
            </div>
            <div className="card-body">
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-2 items-center">
                    <div className="flex flex-col gap-6 justify-between">
                        <div className={`p-6 text-center flex items-center justify-between bg-green-100 border border-green-200 rounded-md`}>
                            <div className={`flex items-center justify-center size-12 my-auto rounded-md bg-green-50 text-15`}>
                                <User className={`text-green-500`} />
                            </div>
                            <div className="flex flex-col items-end">
                                <h4 className={`mb-1 text-green-500 text-4xl`}>
                                    <CountUp className="counter-value" end={totalActive} />
                                </h4>
                                <p className={`mb-0 text-green-500 uppercase`}>active users</p>
                            </div>
                        </div>
                        <div className={`p-6 text-center flex items-center justify-between bg-red-100 border border-red-200 rounded-md`}>
                            <div className={`flex items-center justify-center size-12  rounded-md bg-red-50 text-15 `}>
                                <UserMinus className={`text-red-500`} />
                            </div>
                            <div className="flex flex-col items-end">
                                <h4 className={`mb-1 text-red-500 text-4xl`}>
                                    <CountUp className="counter-value" end={totalDeleted} />
                                </h4>
                                <p className={`mb-0 text-red-500 uppercase`}>deleted users</p>
                            </div>
                        </div>
                        <div className={`p-6 text-center flex items-center justify-between bg-orange-100 border border-orange-200 rounded-md`}>
                            <div className={`flex items-center justify-center size-12  rounded-md bg-orange-50 text-15 `}>
                                <UserRoundX className={`text-orange-500`} />
                            </div>
                            <div className="flex flex-col items-end">
                                <h4 className={`mb-1 text-orange-500 text-4xl`}>
                                    <CountUp className="counter-value" end={totalBanned} />
                                </h4>
                                <p className={`mb-0 text-orange-500 uppercase`}>banned users</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SimplePie
                            chartId={title}
                            dataSeries={[
                                totalActive ? totalActive : 0,
                                totalDeleted ? totalDeleted : 0,
                                totalBanned ? totalBanned : 0,
                            ]}
                            dataLabelsChart={["Active", "Deleted", "Banned"]}
                            dataColorCodes={'["bg-green-300","bg-red-300","bg-orange-300"]'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardDetailCard