import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDashboard } from "store/services/dashboard/dashboard";
import { handleAuthError } from "utils/handleAuthError";

export const GetDashboardAction = createAsyncThunk(
  "dashboard/getDashboardData",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getDashboard(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
