import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { setJobCancellationFilterProps } from "store/slices/admin/jobCancellation";

const JobCancellationFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { jobCancellationsFilterProps } = useSelector(
    (state) => state.jobCancellations
  );

  const intitalVal = {
    isVisible: "",
    type: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];

  const typeOptions = [
    { label: "Hospital", value: "hospital" },
    { label: "Professional", value: "professional" },
  ];

  const JobCancellationFilterSchema = Yup.object().shape({
    isVisible: Yup.string().nullable(),
    type: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setJobCancellationFilterProps({
        ...values,
        isVisible: values.isVisible === "" ? null : values.isVisible,
        // type: values.type === "" ? null : values.type,
      })
    );
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...jobCancellationsFilterProps },
    validationSchema: JobCancellationFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setJobCancellationFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Type"}
          isRequired={false}
          options={typeOptions?.map((item) => ({
            label: item?.label,
            value: item?.value,
          }))}
          placeholder="Select Type"
          name={"type"}
          value={formik?.values?.type}
          error={formik.errors.type && formik.touched.type}
          errorMsg={formik.errors.type}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "type",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default JobCancellationFilter;
