import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    isOpen: false,
    backButton: false,
    content: null,
    width: "",
    anchor: "right",
    borderRadiusLeft: "",
    borderRadiusRight: "",
  },
  reducers: {
    openDrawer: (state, { payload }) => {
      state.isOpen = true;
      state.backButton = payload?.backButton;
      state.content = payload?.content;
      state.width = payload?.width;
      state.anchor = payload?.anchor;
      state.borderRadiusLeft = payload?.borderRadiusLeft;
      state.borderRadiusRight = payload?.borderRadiusRight;
    },
    closeDrawer: (state) => {
      state.isOpen = false;
      state.backButton = false;
      state.content = null;
      state.width = "";
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export default drawerSlice.reducer;
