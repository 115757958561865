import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Checkbox, Input, SubmitBtn } from "components/atoms";
import { Access, Name, Type } from "utils/validation/AddAccess";
import { AddAccessAction } from "store/middlewares/access";

function ModalAddAccess() {
  const dispatch = useDispatch();

  const intitalVal = {
    name: "",
    type: "",
    permission: [],
  };

  const AddAccessSchema = Yup.object({
    name: Name,
    type: Type,
    permission: Access,
  });

  const handleSubmit = (values) => {
    // console.log(values, "values");
    dispatch(AddAccessAction(values));
  };

  let permissionsOptions = [
    { value: "create", label: "Create" },
    { value: "update", label: "Update" },
    { value: "read", label: "Read" },
    { value: "delete", label: "Delete" },
  ];

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddAccessSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Name"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Type"}
        error={formik.errors.type && formik.touched.type}
        errorMsg={formik.errors.type}
        value={formik.values.type}
        type="text"
        name="type"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Type"
      />
      {/* <Checkbox label="Access" name="permission" /> */}
      <Checkbox
        options={permissionsOptions}
        name={"access"}
        label={"Access"}
        value={formik.values.permission}
        onChange={(e) => {
          let tempArray = formik.values.permission;
          if (!e.target.checked) {
            tempArray = tempArray?.filter((item) => item !== e.target.value);
          } else {
            tempArray = [...tempArray, e.target.value];
          }
          formik.setFieldValue("permission", tempArray);
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddAccess;
