import { createSlice } from "@reduxjs/toolkit";
import {
  AddAccessManagementUsers,
  DeleteAccessManagementUsers,
  EditAccessManagementUsers,
  GetAllAccessManagementUsers,
} from "store/middlewares/accessManagementUsers";

export const accessUsersSlice = createSlice({
  name: "accessUsers",
  initialState: {
    accessUsersLoading: false,
    accessUsers: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // For get all access management users
      .addCase(GetAllAccessManagementUsers.pending, (state) => {
        state.accessUsersLoading = true;
        state.accessUsers = [];
      })
      .addCase(GetAllAccessManagementUsers.fulfilled, (state, action) => {
        state.accessUsersLoading = false;
        state.accessUsers = action.payload.response;
      })
      .addCase(GetAllAccessManagementUsers.rejected, (state) => {
        state.accessUsersLoading = false;
        state.accessUsers = [];
      })

      // For add new access management user
      .addCase(AddAccessManagementUsers.fulfilled, (state, action) => {
        state.accessUsers.unshift(action.payload.response);
      })

      // For add new access management user
      .addCase(DeleteAccessManagementUsers.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.accessUsers?.filter((title) => title.id !== id);
        state.accessUsers = tempArray;
      })

      // For edit access management user
      .addCase(EditAccessManagementUsers.fulfilled, (state, action) => {
        state.accessUsersLoading = false;
        state.accessUsers = state.accessUsers.map((user) =>
          user.id === action.payload.response.id
            ? action.payload.response
            : user
        );
      });
  },
});

export default accessUsersSlice.reducer;
