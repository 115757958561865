import { Button } from "@mui/material";
import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import { useDispatch } from "react-redux";
import { LogOutWebAction } from "store/middlewares/session/auth";

const ConfirmModal = ({ type = "confirm", title, message, btnText, onClick }) => {
  const dispatch = useDispatch();
  return (
    <MuiBox
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "25px",
        alignItems: "center",
      }}
    >
      <MuiTypography
        color={type === "delete" ? "#FF5C5C" : "#000000"}
        fontSize="24px"
        fontWeight="bold"
      >
        {title}
      </MuiTypography>

      <MuiTypography fontSize="16px" align="center">
        {message}
      </MuiTypography>

      {type === "delete" && ""}

      {type === "delete" ? (
        <Button
          variant="lightButton"
          sx={{
            maxWidth: "338px",
            bgcolor: "#FF5C5C",
            "&:hover": { bgcolor: "#b51e1e" },
          }}
          onClick={onClick}
        >
          {btnText}
        </Button>
      ) : (
        <Button
          variant="lightButton"
          sx={{ maxWidth: "338px" }}
          onClick={() => dispatch(LogOutWebAction())}
        >
          {btnText}
        </Button>
      )}
    </MuiBox>
  );
};

export default ConfirmModal;
