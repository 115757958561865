import { IconButton, Popover } from "@mui/material";
import { Filter } from "lucide-react";
import React, { createContext, useState } from "react";
export const FilterPopoverContext = createContext();

const FilterPopoverProvider = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <FilterPopoverContext.Provider
      value={{
        handleClick,
        handleClose,
      }}
    >
      <IconButton
        disableRipple
        sx={{ backgroundColor: "#0a1e40", borderRadius: "8px" }}
        onClick={handleClick}
      >
        <Filter className="text-white text-sm" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div style={{ width: 280 }}>{children}</div>
      </Popover>
    </FilterPopoverContext.Provider>
  );
};

export default FilterPopoverProvider;
