// import { ChevronLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ title, pageTitle }) => {
  // const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex flex-col gap-2 py-4 md:flex-row md:items-center print:hidden">
        <div className="grow flex">
          {/* <h5
            className="text-16 font-bold mr-3 cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft />
          </h5> */}
          <h5 className="text-16 font-bold capitalize" style={{ color: "black" }}>
            {title}
          </h5>
        </div>
        <ul className="flex items-center gap-2 text-sm font-normal shrink-0">
          <li className="relative before:content-['\ea54'] before:font-remix before:-right-1 before:absolute before:text-[18px] before:-top-[3px] pr-4 before:text-slate-400 ">
            <Link to="/admin/dashboard" className="text-slate-400 ">
              {pageTitle}
            </Link>
          </li>
          <li className="text-slate-700 capitalize">{title}</li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BreadCrumb;
