import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getJobDetailsAction,
  getJobs,
  getProfessionalJobs,
} from "store/services/jobs";
import { handleAuthError } from "utils/handleAuthError";

// For Get All Jobs
export const GetJobsAction = createAsyncThunk(
  "jobs/getAllJobs",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobs(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Professional Jobs
export const GetProfessionalJobsAction = createAsyncThunk(
  "jobs/getProfessionalJobs",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalJobs(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Professional Jobs
export const GetJobDetails = createAsyncThunk(
  "jobs/getJobDetails",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getJobDetailsAction(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
