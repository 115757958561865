import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { description, Speciality } from "utils/validation/AddSpeciality";
import { AddSpecialityAction } from "store/middlewares/specialities";

function ModalAddSpeciality() {
  const dispatch = useDispatch();

  const intitalVal = {
    speciality: "",
    description: "",
  };

  const AddSpecialitySchema = Yup.object({
    speciality: Speciality,
    description: description,
  });

  const handleSubmit = (values) => {
    dispatch(AddSpecialityAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddSpecialitySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Speciality"}
        error={formik.errors.speciality && formik.touched.speciality}
        errorMsg={formik.errors.speciality}
        value={formik.values.speciality}
        type="text"
        name="speciality"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Speciality"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Tooltip"}
        name="description"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.description && formik.touched.description}
        errorMsg={formik.errors.description}
        value={formik.values.description}
        placeholder="Enter tooltip"
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddSpeciality;
