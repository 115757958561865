import axiosClient from "utils/api";
const URI = "/admin/access";

export const getAccess = async () => {
  const URL = `${URI}/access`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Access:", error);
    throw error;
  }
};

export const deleteAccess = async (payload) => {
  const URL = `${URI}/access/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Access:", error);
    throw error;
  }
};

export const updateAccess = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/access/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Access:", error);
    throw error;
  }
};

export const addAccess = async (payload) => {
  const URL = `${URI}/access`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Access:", error);
    throw error;
  }
};
