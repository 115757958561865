import { Box, IconButton } from "@mui/material";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import { ModalEditSkill } from "components/molecules";
import { DeleteSkillAction } from "store/middlewares/skills";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";

const Skills = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box className="flex h-full items-center justify-end gap-3">
      {checkAccess("update_skills") && (
        <IconButton
          color="info"
          // sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() =>
            dispatch(
              openAdminModal({
                title: "Edit Skills",
                content: <ModalEditSkill row={row} />,
              })
            )
          }
        >
          <TooltipProvider title="Edit" className="tooltip">
            <Pencil />
          </TooltipProvider>
        </IconButton>
      )}
      {checkAccess("delete_skills") && (
        <IconButton
          color="error"
          // sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to delete this skill?",
              imageUrl: DeleteIconSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(DeleteSkillAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Delete">
            <Trash2 />
          </TooltipProvider>
        </IconButton>
      )}
    </Box>
  );
};

export default Skills;
