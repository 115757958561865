import React from "react";
import { NavItem as Item } from "./NavItem";
const Nav = ({ children, className, as: Component = "ul" }) => {
  return (
    <>
      <Component className={className}>{children}</Component>
    </>
  );
};

Nav.Item = Item;

export default Nav ;
