import { createSlice } from "@reduxjs/toolkit";
import {
  AddSpecialityAction,
  DeleteSpecialityAction,
  GetAllSpecialitiesAction,
  GetAllSpecialitiesWebAction,
  UpdateSpecialityAction,
} from "store/middlewares/specialities";

// isDeleteMeFulFilled
export const specialitiesSlice = createSlice({
  name: "specialities",
  initialState: {
    specialitiesLoading: false,
    specialities: [],
    specialitiesFilterProps: {},
  },
  reducers: {
    setSpecialitiesFilterProps: (state, action) => {
      state.specialitiesFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllSpecialitiesAction.pending, (state) => {
        state.specialitiesLoading = true;
        state.specialities = [];
      })
      .addCase(GetAllSpecialitiesAction.fulfilled, (state, action) => {
        state.specialitiesLoading = false;
        state.specialities = action.payload.response;
      })
      .addCase(GetAllSpecialitiesAction.rejected, (state) => {
        state.specialitiesLoading = false;
        state.specialities = [];
      })
      .addCase(GetAllSpecialitiesWebAction.pending, (state) => {
        state.specialitiesLoading = true;
        state.specialities = [];
      })
      .addCase(GetAllSpecialitiesWebAction.fulfilled, (state, action) => {
        state.specialitiesLoading = false;
        state.specialities = action.payload.response;
      })
      .addCase(GetAllSpecialitiesWebAction.rejected, (state) => {
        state.specialitiesLoading = false;
        state.specialities = [];
      })
      // Delete Job Title
      .addCase(DeleteSpecialityAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.specialities?.filter((title) => title.id !== id);
        state.specialitiesLoading = false;
        state.specialities = tempArray;
      })
      //Update Job Title
      .addCase(UpdateSpecialityAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.specialities?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.specialitiesLoading = false;
        state.specialities = tempArray;
      })
      //Add Job Title
      .addCase(AddSpecialityAction.fulfilled, (state, action) => {
        state.specialitiesLoading = false;
        state.specialities = [action.payload.response,...state.specialities];
      })
  },
});

export const { setSpecialitiesFilterProps } = specialitiesSlice.actions;

export default specialitiesSlice.reducer;
