import axiosClient from "utils/api";
const URI = "/admin/skills";

export const getSkills = async (payload) => {
  const URL = `${URI}/skills`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Skills:", error);
    throw error;
  }
};


export const deleteSkill = async (payload) => {
  const URL = `${URI}/skill/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Skill:", error);
    throw error;
  }
};

export const updateSkill = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/skill/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Skill:", error);
    throw error;
  }
};

export const updateVisiblitySkill = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Skill:", error);
    throw error;
  }
};

export const addSkill = async (payload) => {
  const URL = `${URI}/skill`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Skill:", error);
    throw error;
  }
};
