import React, { useRef, useState } from 'react';
import { MuiBox } from 'components/atoms';
import { IconButton } from '@mui/material';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import required modules
import { Thumbs } from 'swiper/modules';

import Hospital1 from 'assets/images/PNG/hospital1.png'
import Hospital2 from 'assets/images/PNG/hospital2.png'
import Hospital3 from 'assets/images/PNG/hospital3.png'
import LeftSlideBlue from 'assets/images/SVG/leftSlideBlue.svg'
import LeftSlideDark from 'assets/images/SVG/leftSlideDark.svg'
import RightSlideBlue from 'assets/images/SVG/rightSlideBlue.svg'
import RightSlideDark from 'assets/images/SVG/rightSlideDark.svg'
const HospitalDetailSwiper = () => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    // For change slide buttons
    const swiperRef = useRef(null);
    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const handleNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };
    const handlePrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };
    const handleSlideChange = () => {
        const swiperInstance = swiperRef.current.swiper;
        setIsBeginning(swiperInstance.isBeginning);
        setIsEnd(swiperInstance.isEnd);
    };
    return (
        <MuiBox sx={{ px: 1 }}>
            {/* Main Swiper */}
            <MuiBox sx={{ position: 'relative' }}>
                <Swiper
                    spaceBetween={10}
                    thumbs={thumbsSwiper ? { swiper: thumbsSwiper } : undefined} // Add condition here
                    modules={[Thumbs]}
                    className="hospital-detail-swiper"
                    ref={swiperRef}
                    onSlideChange={handleSlideChange}
                    onSwiper={(swiper) => handleSlideChange(swiper)}
                >
                    <SwiperSlide>
                        <MuiBox className='hospital-swiper-slide'>
                            <img src={Hospital1} className='img-cover' />
                        </MuiBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MuiBox className='hospital-swiper-slide'>
                            <img src={Hospital2} className='img-cover' />
                        </MuiBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MuiBox className='hospital-swiper-slide'>
                            <img src={Hospital3} className='img-cover' />
                        </MuiBox>
                    </SwiperSlide>
                </Swiper>
                <IconButton sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: '-23px', zIndex: 1 }} aria-label="delete" onClick={handlePrev} disabled={isBeginning}>
                    <img src={isBeginning ? LeftSlideDark : LeftSlideBlue} />
                </IconButton>
                <IconButton sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-23px', zIndex: 1 }} aria-label="delete" onClick={handleNext} disabled={isEnd}>
                    <img src={isEnd ? RightSlideDark : RightSlideBlue} />
                </IconButton>
            </MuiBox>
            {/* Thumbnail Swiper */}
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={5}
                watchSlidesProgress={true}
                modules={[Thumbs]}
                className="hospital-detail-swiper-thumbnail"
            >
                <SwiperSlide>
                    <MuiBox className='hospital-swiper-thumbnail-slide'>
                        <img src={Hospital1} className='img-cover' />
                    </MuiBox>
                </SwiperSlide>
                <SwiperSlide>
                    <MuiBox className='hospital-swiper-thumbnail-slide'>
                        <img src={Hospital2} className='img-cover' />
                    </MuiBox>
                </SwiperSlide>
                <SwiperSlide>
                    <MuiBox className='hospital-swiper-thumbnail-slide'>
                        <img src={Hospital3} className='img-cover' />
                    </MuiBox>
                </SwiperSlide>
            </Swiper>
        </MuiBox>
    );
};

export default HospitalDetailSwiper;
