import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";
import * as Yup from "yup";

export const Name = Yup.string()
  .required("Name is required")
  .matches(onlySpaceRegExp, "Name should contain at least one character")
  .matches(startsWithSpaceRegExp, "Name cannot start with a space");
export const Type = Yup.string().nullable();
export const Access = Yup.array()
  .of(Yup.string().required("Access is required"))
  .min(1, "Please select Access")
  .required("Access is required");
