import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSkill,
  deleteSkill,
  getSkills,
  updateSkill,
  updateVisiblitySkill,
} from "store/services/skills";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

export const GetAllSkillsAction = createAsyncThunk(
  "skills/getAllSkills",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getSkills(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteSkillAction = createAsyncThunk(
  "skills/deleteSkill",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteSkill(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({...res, ...payload});
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateSkillAction = createAsyncThunk(
  "skills/updateSkill",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateSkill(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateVisiblitySkillAction = createAsyncThunk(
  "skills/updateVisiblitySkill",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblitySkill(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        return fulfillWithValue({...res, ...payload});
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddSkillAction = createAsyncThunk(
  "skills/addSkill",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addSkill(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
