import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllHospitals,
  addHospital,
  editHospital,
  getHospitalById,
  deleteHospital,
  blockHospital,
  unblockHospital,
  restoreHospital,
  activeHospital,
  getSubscriptionHospital,
  getAddressHospital,
  editAddressHospital,
} from "store/services/users/hospitals.js";
import { addUploadFile } from "store/services/users/professionals.js";
import { closeAdminModal } from "store/slices/common/adminModal.slice.js";
import { handleAuthError } from "utils/handleAuthError";
import { openToast } from "store/slices/common/toast.slice.js";

export const GetAllHospitalsAction = createAsyncThunk(
  "hospitals/getAllHospitals",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllHospitals(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetHospitalByIdAction = createAsyncThunk(
  "hospitals/getHospitalById",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getHospitalById(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetSubscriptionHospitalAction = createAsyncThunk(
  "hospitals/getSubscriptionHospital",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getSubscriptionHospital(payload);
      
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddHospitalAction = createAsyncThunk(
  "hospitals/addHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      const uploadFileResponse = await addUploadFile({ file, uploadFor });

      if (uploadFileResponse.status === 1) {
        const url = uploadFileResponse?.response?.urls[0];
        const response = await addHospital({
          profileImage: url,
          ...rest,
        });
        if (response.status === 1) {
          window.location = "/admin/hospital/active";
          return fulfillWithValue(response);
        }
        dispatch(openToast({ type: "error", message:  response?.message }));
        return rejectWithValue({ message: response?.message });
      }
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const EditHospitalAction = createAsyncThunk(
  "hospitals/editHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      let url = "";
      if (typeof file !== "string" && file !== null) {
        const uploadFileResponse = await addUploadFile({ file, uploadFor });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      let editPayload = { ...rest };
      if (url) {
        editPayload = { ...editPayload, profileImage: url };
      }
      const response = await editHospital(editPayload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.history.go(-1)
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const ActiveHospitalAction = createAsyncThunk(
  "hospitals/activeHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await activeHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.location = "/admin/hospital/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
export const DeleteHospitalAction = createAsyncThunk(
  "hospitals/deleteHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.location = "/admin/hospital/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const BlockHospitalAction = createAsyncThunk(
  "hospitals/blockHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await blockHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UnblockHospitalAction = createAsyncThunk(
  "hospitals/unblockHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await unblockHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const RestoreHospitalAction = createAsyncThunk(
  "hospitals/restoreHospitalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await restoreHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetHospitalAddressAction = createAsyncThunk(
  "hospitals/getHospitalAddressAction",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAddressHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const EditHospitalAddressAction = createAsyncThunk(
  "hospitals/editHospitalAddressAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await editAddressHospital(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.history.go(-1)
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

