import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const MuiTypography = ({ children, variant = 'body1', ...props }) => {
    return (
        <Typography
            textOverflow={'ellipsis'}
            variant={variant}
            {...props}
        >
            {children}
        </Typography>
    );
};

MuiTypography.propTypes = {
    children: PropTypes.node,
    variant: PropTypes.string,
};

export default MuiTypography;
