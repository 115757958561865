import axiosClient from "utils/api";
const URI = "/admin/incidentReport";

export const getReports = async () => {
  const URL = `${URI}/incidentReport`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Reports:", error);
    throw error;
  }
};

export const deleteReport = async (payload) => {
  const URL = `${URI}/incidentReport/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Report:", error);
    throw error;
  }
};

export const updateReport = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/incidentReport/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Report:", error);
    throw error;
  }
};

export const updateVisiblityReport = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Report:", error);
    throw error;
  }
};

export const addReport = async (payload) => {
  const URL = `${URI}/incidentReport`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Report:", error);
    throw error;
  }
};
