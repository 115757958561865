import React from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { MuiBox } from "components/atoms";
import { closeDrawer } from "store/slices/common/drawer.slice";

import ToggleSwitch from "assets/images/SVG/Toggle switch.svg";
import ToggleSwitchOn from "assets/images/SVG/Toggle switch on.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNotificationSettings } from "store/middlewares/notifications";
const ProfileMobileMenu = ({
  filteredTabsBasedOnType,
  handleProfileTabClick,
  activeProfileTab,
}) => {
  const dispatch = useDispatch();

  const {
    me: { notifications },
  } = useSelector((state) => state);

  console.log(notifications, "test")

  return (
    <MuiBox sx={{ bgcolor: "#fff" }}>
      <List>
        {filteredTabsBasedOnType.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              disableRipple={item.toggleSwitch ? true : false}
              onClick={() => {
                if (item.onCLickFunc === undefined) {
                  return (
                    <>
                      {handleProfileTabClick(item.title)}
                      {dispatch(closeDrawer())}
                    </>
                  );
                } else {
                  item.onCLickFunc();
                }
              }}
            >
              <ListItemIcon>
                <img
                  src={item.icon}
                  className={
                    item.title === activeProfileTab
                      ? ""
                      : "img-filter-brighness"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  color: item.iconIschangeable
                    ? item.title === activeProfileTab
                      ? "#0CA8DF"
                      : "#000000"
                    : "#000000",
                }}
              />
              {item.toggleSwitch && (
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    icon={<img src={ToggleSwitch} />}
                    checkedIcon={<img src={ToggleSwitchOn} />}
                    checked={notifications?.pushNotification}
                    onChange={(e) =>
                      dispatch(
                        UpdateNotificationSettings({
                          pushNotification: e.target.checked,
                        })
                      )
                    }
                  />
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </MuiBox>
  );
};

export default ProfileMobileMenu;
