import { MuiBox, MuiTypography } from "components/atoms";
import React from "react";
import CongratulationsIcon from "assets/images/SVG/CongratulationsIcon.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeDrawer, openDrawer } from "store/slices/common/drawer.slice";
import MapModal from "../MapModal";
// const MapModal = React.lazy(() => import('../MapModal'));

const SuccessModal = ({
  imgsrc = CongratulationsIcon,
  title,
  message,
  goToLogin = false,
  btnText = "Go To Login",
  goToJobs = false,
  viewMyJobs = false,
  tempRedirect = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <MuiBox className="success-container">
      <MuiBox sx={{ maxWidth: "266px", flexShrink: 1, margin: "68px auto 0" }}>
        <img src={imgsrc} alt="about-care" className="img-contain" />
      </MuiBox>
      <MuiTypography variant="h4" fontWeight={"bold"} align={"center"}>
        {title}
      </MuiTypography>
      {message && (
        <MuiTypography
          variant="baseFont"
          align={"center"}
          sx={{ color: "button.light", marginTop: "15px" }}
        >
          {message}
        </MuiTypography>
      )}
      {goToLogin && (
        <MuiBox sx={{ padding: "14px 75px" }}>
          <Button variant="lightButton">
            <MuiTypography
              variant="baseFont"
              fontWeight={"bold"}
              textTransform={"capitalize"}
            >
              {btnText}
            </MuiTypography>
          </Button>
        </MuiBox>
      )}
      <MuiBox sx={{ padding: "30px 75px", display: 'flex', alignItems: 'center', gap: '16px' }}>
        <Button variant="darkButton" onClick={() => {
          dispatch(closeDrawer())
        }}>
          <MuiTypography
            variant="baseFont"
            fontWeight={"bold"}
            textTransform={"capitalize"}
          >
            Cancel
          </MuiTypography>
        </Button>
        {goToJobs && (
          <Button variant="lightButton" onClick={() => {
            navigate('/hospital/jobs')
            dispatch(closeDrawer())
          }}>
            <MuiTypography
              variant="baseFont"
              fontWeight={"bold"}
              textTransform={"capitalize"}
            >
              {btnText}
            </MuiTypography>
          </Button>
        )}
        {viewMyJobs && (
          <Button variant="lightButton" onClick={() => {
            navigate('/hospital/jobs')
            dispatch(closeDrawer())
          }}>
            <MuiTypography
              variant="baseFont"
              fontWeight={"bold"}
              textTransform={"capitalize"}
            >
              {btnText}
            </MuiTypography>
          </Button>
        )}
        {tempRedirect && (
          <Button variant="lightButton" onClick={() => {
            dispatch(openDrawer({
              content: (
                  <MapModal />
              ),
            }));

          }}>
            <MuiTypography
              variant="baseFont"
              fontWeight={"bold"}
              textTransform={"capitalize"}
            >
              {btnText}
            </MuiTypography>
          </Button>
        )}
      </MuiBox>
    </MuiBox>
  );
};

export default SuccessModal;
