import axiosClient from "utils/api";
const URI = "/admin/deleteReason";

export const getDeleteReasons = async (payload) => {
  const URL = `${URI}/deleteReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Delete Reasons:", error);
    throw error;
  }
};

export const getDeleteReasonsForWeb = async (payload) => {
  const URL = `/deleteReason/deleteReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Delete Reasons:", error);
    throw error;
  }
};

export const deleteDeleteReason = async (payload) => {
  const URL = `${URI}/deleteReason/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Delete Reason :", error);
    throw error;
  }
};

export const updateDeleteReason = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/deleteReason/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Delete Reason :", error);
    throw error;
  }
};

export const addDeleteReason = async (payload) => {
  const URL = `${URI}/deleteReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Delete Reason :", error);
    throw error;
  }
};

export const updateDeleteReasonVisiblity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Delete Reason:", error);
    throw error;
  }
};
