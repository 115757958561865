import axiosClient from "utils/api";
const URI = "/admin/facilities";
const WEB = "/facilities";

export const getFacilities = async (payload) => {
  const URL = `${URI}/facilities`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Facilities:", error);
    throw error;
  }
};

export const getFacilitiesWeb = async (payload) => {
  const URL = `${WEB}/facilities`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Facilities:", error);
    throw error;
  }
};

export const deleteFacility = async (payload) => {
  const URL = `${URI}/facility/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Facility:", error);
    throw error;
  }
};

export const updateFacility = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/facility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Facility:", error);
    throw error;
  }
};

export const updateVisiblityFacility = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Facility:", error);
    throw error;
  }
};

export const addFacility = async (payload) => {
  const URL = `${URI}/facility`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Facility:", error);
    throw error;
  }
};
