import React from "react";

const InputTextarea = ({
  onChange,
  onBlur,
  value,
  error,
  errorMsg,
  placeholder,
  label,
  className,
  name,
  textareaClassName,
  labelClassName,
  isRequired = true,
}) => {
  return (
    <div className={`py-2 ${className}`}>
      <label htmlFor={name} className={`font-medium ${labelClassName}`}>
        {label} {isRequired && <span className="text-[#FF5C5C]">*</span>}
      </label>
      <br />
      <textarea
        name={name}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
        }}
        value={value}
        placeholder={placeholder}
        className={`form-input focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300 disabled:text-slate-500 placeholder:text-slate-400 min-h-[100px] ${textareaClassName} ${
          error ? "border-red-500" : "border-slate-200"
        } `}
      />
      {error ? (
        <div className="mt-1 text-sm text-red-500">{errorMsg}</div>
      ) : null}
    </div>
  );
};

export default InputTextarea;
