import React from "react";
import { useTabContext } from "./TabContext";

const TabPanel = ({ children, className, eventKey, as: Component = "div" }) => {
  const { activeTab } = useTabContext();
  return (
    <>
      <Component
        className={`tab-pane ${className ? className : ""} ${
          eventKey === activeTab ? "block" : "hidden"
        }`}
      >
        {children}
      </Component>
    </>
  );
};

export { TabPanel };
