import React from "react";
import { useTabContext } from "./TabContext";

const NavItem = ({ as: Component = "li", children, className, eventKey }) => {
  const { activeTab, changeTab } = useTabContext();

  return (
    <>
      <Component
        className={`${className} ${eventKey === activeTab ? "active" : ""}`}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // Define the props for the child component
            const tabProps = {
              onClick: () => changeTab(eventKey), // Use the provided eventKey
            };

            return React.cloneElement(child, tabProps);
          }
          return null;
        })}
      </Component>
    </>
  );
};

export { NavItem };
