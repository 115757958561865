import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination } from 'swiper/modules';

import Selfie from 'assets/images/PNG/selfie.png'
const InOutSelfieModals = () => {
    return (
        <MuiBox sx={{ px: { xs: "15px", sm: "25px", md: "45px" }, py: 5, display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
                Selfie Photo
            </MuiTypography>

            <MuiBox>
                <Swiper
                    pagination={{
                        type: 'fraction',
                    }}
                    // navigation={true}
                    modules={[Pagination]}
                    className="selfie-swiper"
                >
                    <SwiperSlide>
                        <MuiBox sx={{ height: '751px', overflow: 'hidden', borderRadius: "5px" }}>
                            <img src={Selfie} className='img-cover' />
                        </MuiBox>
                    </SwiperSlide>
                    <SwiperSlide>
                        <MuiBox sx={{ height: '751px', overflow: 'hidden', borderRadius: "5px" }}>
                            <img src={Selfie} className='img-cover' />
                        </MuiBox>
                    </SwiperSlide>
                </Swiper>
            </MuiBox>

            <MuiBox sx={{p:'0 16px'}}>
                <MuiBox>
                    <MuiTypography variant='h6' fontWeight='bold' color='#0CA8DF'>
                        Clock in selfie picture
                    </MuiTypography>
                    <MuiTypography variant='baseFont'>
                        Mar 1, 2023, 7:00 AM - 7:30 AM
                    </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ mt: 2 }}>
                    <MuiTypography variant='h6' fontWeight='bold' color='#0CA8DF'>
                        Clock in selfie picture
                    </MuiTypography>
                    <MuiTypography variant='baseFont'>
                        Mar 1, 2023, 7:00 AM - 7:30 AM
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
        </MuiBox>
    )
}

export default InOutSelfieModals