import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Answer, Question, Type } from "utils/validation/AddFAQ";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import InputTextarea from "components/atoms/InputTextarea";
import { AddFaqAction } from "store/middlewares/HelpAndSupport";

function ModalAddFaq() {
  const dispatch = useDispatch();

  const intitalVal = {
    question: "",
    answer: "",
    type: "",
  };

  const AddJobTitleSchema = Yup.object({
    question: Question,
    answer: Answer,
    type: Type,
  });

  const handleSubmit = (values) => {
    dispatch(AddFaqAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputTextarea
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar min-h-[100px]"
        label={"Question"}
        name="question"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.question && formik.touched.question}
        errorMsg={formik.errors.question}
        value={formik.values.question}
        placeholder="Enter Question"
      />
      <InputTextarea
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar min-h-[100px]"
        label={"Answer"}
        name="answer"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.answer && formik.touched.answer}
        errorMsg={formik.errors.answer}
        value={formik.values.answer}
        placeholder="Enter Answer"
      />
      <SelectComponent
        className={"px-0"}
        label={"Type"}
        options={[
          { label: "Hospital", value: "hospital" },
          { label: "Professional", value: "professional" },
        ]}
        placeholder="Select Type"
        name={"type"}
        value={formik.values.type}
        error={formik.errors.type && formik.touched.type}
        errorMsg={formik.errors.type}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "type",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddFaq;
