import { Box, IconButton } from "@mui/material";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
// import { openAdminModal } from "store/slices/common/adminModal.slice";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
// import { ModalEditRoles } from "components/molecules";
import { DeleteRoleAction } from "store/middlewares/roles";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";
import { useNavigate } from "react-router-dom";

const Roles = ({ row }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box className="flex items-center justify-end gap-3">
      {checkAccess("update_roles") && (
        <IconButton
          color="info"
          disableRipple
          onClick={() => navigate(`edit/${row.id}`)}
        >
          <TooltipProvider title="Edit">
            <Pencil />
          </TooltipProvider>
        </IconButton>
      )}
      {checkAccess("delete_roles") && (
        <IconButton
          color="error"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to delete this Role?",
              imageUrl: DeleteIconSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(DeleteRoleAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Delete">
            <Trash2 />
          </TooltipProvider>
        </IconButton>
      )}
    </Box>
  );
};

export default Roles;
