// import { Typography } from "@mui/material";
// import { CustomLoader, TableContainer } from "components/atoms";
import { NoDataFound } from "components/atoms";
import React, { useState } from "react";
import blankImg from "assets/images/blank.png";

const HospitalGalleryTab = ({ imgArray = [] }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const toggleModal = (imageSrc) => {
    setModalOpen(!isModalOpen);
    setImageSrc(imageSrc);
  };
  return (
    <div className="Hospital-User-page h-full w-full">
      <div className="mt-5">
        {imgArray.length > 0 ? (
          <div className="grid grid-cols-3 gap-4 mt-5">
            {imgArray.map((item) => (
              <div className="card" key={item?.id}>
                <div className="">
                  <div className="relative overflow-hidden rounded-md group/gallery">
                    <div className="overflow-hidden">
                      <img
                        src={item?.imageUrl}
                        alt="Gallery Item"
                        className="transition-all w-full h-[300px] object-cover duration-300 ease-linear group-hover/gallery:scale-110"
                        onClick={() => toggleModal(item.imageUrl)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[999999999999] w-full h-full"
          onClick={toggleModal}
        >
          <img
            src={imageSrc ? imageSrc : blankImg}
            alt=""
            className="max-h-full max-w-full rounded-md object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default HospitalGalleryTab;
