import { createSlice } from "@reduxjs/toolkit";
import {
  AddRoleAction,
  DeleteRoleAction,
  GetAllRolesAction,
  UpdateRoleAction,
} from "store/middlewares/roles";
import updateAccessInLocalStorage from "utils/updateAccess";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    rolesLoading: false,
    roles: [],
    rolesFilterProps: {},
  },
  reducers: {
    setRolesFilterProps: (state, action) => {
      state.rolesFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllRolesAction.pending, (state) => {
        state.rolesLoading = true;
        state.roles = [];
      })
      .addCase(GetAllRolesAction.fulfilled, (state, action) => {
        state.rolesLoading = false;
        state.roles = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllRolesAction.rejected, (state) => {
        state.rolesLoading = false;
        state.roles = [];
      })
      // Delete Job Title
      .addCase(DeleteRoleAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.roles?.filter((title) => title.id !== id);
        state.rolesLoading = false;
        state.roles = tempArray;
      })
      //Update Job Title
      .addCase(UpdateRoleAction.fulfilled, (state, action) => {
        let { response, update } = action.payload;
        let newAccess = response?.access?.map((item) => {
          return { type: item.type, permissions: item?.permissions };
        });
        if (update) {
          updateAccessInLocalStorage(newAccess);
        }
        state.rolesLoading = false;
      })
      //Add city
      .addCase(AddRoleAction.fulfilled, (state, action) => {
        state.rolesLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.roles = [Obj, ...state.roles];
      });
  },
});
export const { setRolesFilterProps } = rolesSlice.actions;

export default rolesSlice.reducer;
