import axiosClient from "utils/api";
const URI = "/admin";

// For get all jobs
export const getJobs = async (payload) => {
    let tempURL = payload ? `admin/jobs/hospitalId/${payload.id}` : "admin/jobs/jobs"
    try {
        const res = await axiosClient().request({
            url: tempURL,
            method: "GET",
        });

        return res.data;
    } catch (error) {
        console.error("Error occuured Get All Jobs:", error.message);
        throw new Error(error.message);
    }
};

// For get professional jobs
export const getProfessionalJobs = async (payload) => {
    const URL = `${URI}/jobs/professionalId/${payload.id}`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "get",
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred during Get Professional Jobs:", error);
        throw error;
    }
}

// For get professional jobs
export const getJobDetailsAction = async (payload) => {
    const URL = `${URI}/jobs/jobId/${payload.id}`;
    try {
        const response = await axiosClient().request({
            url: URL,
            method: "get",
        });
        return response.data;
    } catch (error) {
        console.error("Error occurred during Get Job Detail:", error);
        throw error;
    }
}