import { handleAuthError } from "utils/handleAuthError";
import {
  updateMe,
  getMe,
  changePassword,
  getMeForWeb,
  updateMeForWeb,
  updateMileage,
  deleteAccount,
} from "store/services/me.services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "store/slices/common/toast.slice";
import { uploadFile } from "store/services/utilities";
import { closeDrawer } from "store/slices/common/drawer.slice";

export const GetMeAction = createAsyncThunk(
  "me/getMe",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getMe(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetMeForWebAction = createAsyncThunk(
  "me/getMeForWeb",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getMeForWeb(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateMileageAction = createAsyncThunk(
  "me/upadateMileage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateMileage(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteAccountAction = createAsyncThunk(
  "me/deleteAccount",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteAccount(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeDrawer());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateMeForWebAction = createAsyncThunk(
  "me/updateMeForWeb",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      if (file) {
        const uploadFilesResponse = await uploadFile({
          file,
          uploadFor: "profile",
        });
        rest = {
          ...rest,
          profileImage: uploadFilesResponse?.response?.urls?.[0],
        };
      }
      const response = await updateMeForWeb(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateMeAction = createAsyncThunk(
  "me/updateMe",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateMe(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ ...response, data: payload });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const ChangePasswordAction = createAsyncThunk(
  "me/changePasswordAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await changePassword(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
