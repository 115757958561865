import { createSlice } from "@reduxjs/toolkit";
import { GetAllCmsAction, GetCmsForWebAction, UpdateCmsAction } from "store/middlewares/cms";

export const cmsSlice = createSlice({
  name: "cms",
  initialState: {
    cmsLoading: false,
    cms: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllCmsAction.pending, (state) => {
        state.cmsLoading = true;
        state.cms = [];
      })
      .addCase(GetAllCmsAction.fulfilled, (state, action) => {
        state.cmsLoading = false;
        state.cms = action.payload.response;
      })
      .addCase(GetAllCmsAction.rejected, (state) => {
        state.cmsLoading = false;
        state.cms = null;
      })

      .addCase(GetCmsForWebAction.pending, (state) => {
        state.cmsLoading = true;
        state.cms = [];
      })
      .addCase(GetCmsForWebAction.fulfilled, (state, action) => {
        state.cmsLoading = false;
        state.cms = action.payload.response;
      })
      .addCase(GetCmsForWebAction.rejected, (state) => {
        state.cmsLoading = false;
        state.cms = null;
      })

      //Update
      .addCase(UpdateCmsAction.fulfilled, (state, action) => {
        state.cmsLoading = false;
        state.cms = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      });
  },
});

export default cmsSlice.reducer;
