import { WebLayout } from "components/molecules";
import React, { lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { validateTokenWeb } from "store/middlewares/session/auth";
import { getDecryptedToken } from "utils/CryptoJS";
import ScrollToTop from "utils/ScrollToTop";

// Pages
const HomePage = lazy(() => import("pages/Web/HomePage"));
const JobsPage = lazy(() => import("pages/Web/JobsPage"));
const SchedulePage = lazy(() => import("pages/Web/SchedulePage"));
const ProfessionalnearBy = lazy(() => import("pages/Web/ProfessionalNearBy"));
const ProfessionalDetails = lazy(() => import("pages/Web/ProfessionalDetails"));
const ProfessionalResume = lazy(() => import("pages/Web/ProfessionalResume"));
const ProfessionalHire = lazy(() => import("pages/Web/ProfessionalHire"));
const ApplicationDetails = lazy(() => import("pages/Web/ApplicationDetails"));
const PostJob = lazy(() => import("pages/Web/PostJob"));
const TotalApplicants = lazy(() => import("pages/Web/TotalApplicants"));
const ScheduleDetails = lazy(() => import("pages/Web/ScheduleDetails"));
const MessagePage = lazy(() => import("pages/Web/MessagePage"));
const RevenuePage = lazy(() => import("pages/Web/RevenuePage"));
const ProfilePage = lazy(() => import("pages/Web/ProfilePage"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};
const hospitalRoutes = [
  {
    path: generatePath("home"),
    element: <HomePage />,
  },
  {
    path: generatePath("jobs"),
    element: <JobsPage />,
  },
  {
    path: generatePath("professional-near-by"),
    element: <ProfessionalnearBy />,
  },
  {
    path: generatePath(":type/details"),
    element: <ProfessionalDetails />,
  },
  {
    path: generatePath("resume"),
    element: <ProfessionalResume />,
  },
  {
    path: generatePath("hire"),
    element: <ProfessionalHire />,
  },
  {
    path: generatePath(":type/application-details"),
    element: <ApplicationDetails />,
  },
  {
    path: generatePath("jobs/post-job"),
    element: <PostJob />,
  },
  {
    path: generatePath("total-application"),
    element: <TotalApplicants />,
  },
  {
    path: generatePath("schedule"),
    element: <SchedulePage />,
  },
  {
    path: generatePath("schedule/:type/schedule-details"),
    element: <ScheduleDetails />,
  },
  {
    path: generatePath("message"),
    element: <MessagePage />,
  },
  {
    path: generatePath("revenue"),
    element: <RevenuePage />,
  },
  {
    path: generatePath("profile"),
    element: <ProfilePage type="hospitals" />,
  },
];
const hospitalNavigation = () => {
  const user = JSON.parse(localStorage.getItem("aboutcare-web"));

  const dispatch = useDispatch();

  const { isTokenValid } = useSelector((state) => state.auth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({ token: getDecryptedToken(user?.token) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <Navigate to="home" />
            )
          }
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.role === "professional" ? (
                  <Navigate to="/professional/home" />
                ) : (
                  <WebLayout />
                )}
              </>
            )
          }
        >
          {hospitalRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Route>
        {/* all undefined routes are handled here for admin */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default hospitalNavigation;
