import { startsWithSpaceRegExp } from "utils/regex";
import * as Yup from "yup";

export const ImageFile = Yup.mixed()
.required("Badges image is required")
.test("fileSize", "Please select a valid image file with size up to 3MB", (value) => {
  return value && value.size <= 3 * 1024 * 1024; // 3MB limit
});
export const Price = Yup.string().required("Price is required");
export const name = Yup.string().required("Name is required").matches(startsWithSpaceRegExp, "Name cannot start with a space");
