import React from "react";
// import { TableContainer } from "components/atoms";
// import { Typography } from "@mui/material";
// import dayjs from "dayjs";

const PaymentPeriodTab = ({ pmtDetail }) => {
  return (
    <>
      {/* {!resumesLoading ? ( */}
      <div className="card mt-5">
        <div className="card-body p-3">
          {pmtDetail && (
            <>
              <div className="mb-2">
                <h6 className="mb-1 font-semibold">Payment Type</h6>
                <p className="text-slate-500 dark:text-zink-200">
                  {pmtDetail?.type}
                </p>
              </div>
              <div className="mb-2">
                <h6 className="mb-1 font-semibold">Charges</h6>
                <p className="text-slate-500 dark:text-zink-200">
                  {pmtDetail?.charges
                  }
                </p>
              </div>
              <div className="mb-2">
                <h6 className="mb-1 font-semibold">Description</h6>
                <p className="text-slate-500 dark:text-zink-200">
                  {pmtDetail?.description}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentPeriodTab;
