import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleAuthError } from "utils/handleAuthError";
import { getSubscriptions } from "store/services/subscriptions";

//Get All Cities
export const GetAllSubscriptionsAction = createAsyncThunk(
  "subscriptions/getAllSubscriptions",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getSubscriptions();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
