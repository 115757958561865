import { createSlice } from "@reduxjs/toolkit";
import {GetAllTaxAction,UpdateTaxAction } from "store/middlewares/tax";

export const taxSlice = createSlice({
  name: "tax",
  initialState: {
    taxLoading: false,
    tax: null,
  },
  extraReducers(builder) {
    builder
      // Get All Settings
      .addCase(GetAllTaxAction.pending, (state) => {
        state.taxLoading = true;
        state.tax = null;
      })
      .addCase(GetAllTaxAction.fulfilled, (state, action) => {
        state.taxLoading = false;
        state.tax = action.payload.response;
      })
      .addCase(GetAllTaxAction.rejected, (state) => {
        state.taxLoading = false;
        state.tax = null;
      })
      // Update Settings
      .addCase(UpdateTaxAction.fulfilled, (state, action) => {
        let { tax } = state;
        state.tax = { ...tax, ...action.payload.response };
      });
  },
});

export default taxSlice.reducer;
