import axiosClient from "utils/api";
const URI = "/admin/dynamicMenu";

export const getDynamicMenu = async () => {
  const URL = `${URI}/dynamicMenu`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Dynamic Menu:", error);
    throw error;
  }
};

export const deleteDynamicMenu = async (payload) => {
  const URL = `${URI}/dynamicMenu/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete DynamicMenu :", error);
    throw error;
  }
};

export const updateDynamicMenu  = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/dynamicMenu/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update DynamicMenu :", error);
    throw error;
  }
};

export const addDynamicMenu  = async (payload) => {
  const URL = `${URI}/dynamicMenu`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add DynamicMenu :", error);
    throw error;
  }
};

// export const updateVisiblitySpeciality = async (payload) => {
//   let { id, ...rest } = payload;
//   const URL = `${URI}/visibility/${id}`;
//   try {
//     const response = await axiosClient().request({
//       url: URL,
//       method: "put",
//       data: rest,
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error occurred Update Facility:", error);
//     throw error;
//   }
// };
