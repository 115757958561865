import React from "react";
import ReactApexChart from "react-apexcharts";
import useChartColors from "hooks/useChartColors";

const SimplePie = ({ chartId, dataSeries = [], dataLabelsChart, dataColorCodes }) => {
  const chartColors = useChartColors(chartId);

  const series = dataSeries.length > 0 ? dataSeries : [0, 0, 0, 0];
  var options = {
    chart: {
      height: 500,
      type: "pie",
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000000'], // Set the text color here
      },
      dropShadow: {
        enabled: false, // Optional, disable drop shadow for text
      },
    },
    labels: dataLabelsChart,
    colors: chartColors,
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        size: 6,
        shape: "line",
        strokeWidth: 10,
        radius: 2,
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series || []}
        data-chart-colors={dataColorCodes}
        id={chartId}
        className="apex-charts"
        height={options.chart.height}
        type="pie"
      />
    </React.Fragment>
  );
};

export default SimplePie;
