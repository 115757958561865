function loginUser({ user }) {
  localStorage.setItem("aboutcare-admin", JSON.stringify(user));
}

function webUser({ user }) {
  localStorage.setItem("aboutcare-web", JSON.stringify(user));
}

function removeFromLocalStorage(name) {
  localStorage.removeItem(`${name}`);
}

const LocalstorageService = {
  loginUser,
  removeFromLocalStorage,
  webUser,
};

export default LocalstorageService;
