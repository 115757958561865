import { WebLayout } from "components/molecules";
import React, { lazy, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { validateTokenWeb } from "store/middlewares/session/auth";
import { getDecryptedToken } from "utils/CryptoJS";
import ScrollToTop from "utils/ScrollToTop";

// Pages
const HomePage = lazy(() => import("pages/Web/ProfessionalHomePage"));
const HospitalNearBy = lazy(() => import("pages/Web/HospitalNearBy"));
const HospitalDetails = lazy(() => import("pages/Web/HospitalDetails"));
const JobsPage = lazy(() => import("pages/Web/JobsPage"));
const JobsListPage = lazy(() => import("pages/Web/JobsListPage"));
const HospitalApplicationDetails = lazy(() =>
  import("pages/Web/HospitalApplicationDetails")
);
const DirectHirePage = lazy(() => import("pages/Web/DirectHirePage"));
const DirectHireDetails = lazy(() => import("pages/Web/DirectHireDetails"));
const CredentialsPage = lazy(() => import("pages/Web/CredentialsPage"));
const ProfessionalSchedulePage = lazy(() =>
  import("pages/Web/ProfessionalSchedulePage")
);
const MessagePage = lazy(() => import("pages/Web/MessagePage"));
const ProfilePage = lazy(() => import("pages/Web/ProfilePage"));
const ScheduleDetails = lazy(() => import("pages/Web/ScheduleDetails"));
const EarningDetails = lazy(() => import("pages/Web/EarningDetails"));

const basePath = "";
const generatePath = (slug) => {
  return `${basePath}/${slug}`;
};
const professionalRoutes = [
  {
    path: generatePath("home"),
    element: <HomePage />,
  },
  {
    path: generatePath("jobs"),
    element: <JobsPage />,
  },
  {
    path: generatePath("hospitals-near-by"),
    element: <HospitalNearBy />,
  },
  {
    path: generatePath("details"),
    element: <HospitalDetails />,
  },
  {
    path: generatePath("jobs-list"),
    element: <JobsListPage />,
  },
  {
    path: generatePath("application-details"),
    element: <HospitalApplicationDetails />,
  },
  {
    path: generatePath("direct-hire"),
    element: <DirectHirePage />,
  },
  {
    path: generatePath("direct-hire/details"),
    element: <DirectHireDetails />,
  },
  {
    path: generatePath("credentials"),
    element: <CredentialsPage />,
  },
  {
    path: generatePath("schedule"),
    element: <ProfessionalSchedulePage />,
  },
  {
    path: generatePath("message"),
    element: <MessagePage />,
  },
  {
    path: generatePath("profile"),
    element: <ProfilePage type="professional" />,
  },
  {
    path: generatePath("schedule/:type/schedule-details"),
    element: <ScheduleDetails />,
  },
  {
    path: generatePath("earning-details"),
    element: <EarningDetails />,
  },
];
const ProfessionalNavigation = () => {
  // const { pathname } = useLocation();
  const user = JSON.parse(localStorage.getItem("aboutcare-web"));

  const dispatch = useDispatch();

  const { isTokenValid } = useSelector((state) => state.auth);
  const checkToken = useCallback(() => {
    if (user) {
      dispatch(validateTokenWeb({ token: getDecryptedToken(user?.token) }));
    }
  }, [dispatch, user]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* if user is logged in redirect to dashboard */}
        <Route
          path=""
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <Navigate to="home" />
            )
          }
        />
        {/* if user is not present show unauthorized ui */}
        <Route
          element={
            !user || !isTokenValid ? (
              <Navigate to="/" />
            ) : (
              <>
                {/* //Protection for when a User tries to access a Creator Page  */}
                {user?.role === "hospital" ? (
                  <Navigate to="/hospital/home" />
                ) : (
                  <WebLayout type="professional" />
                )}
              </>
            )
          }
        >
          {professionalRoutes.map((route, index) => {
            return (
              <Route
                key={`${route.path}${index}`}
                path={route.path}
                element={route.element}
              />
            );
          })}
          {/* all undefined routes are handled here for admin */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
};

export default ProfessionalNavigation;
