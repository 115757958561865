import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import { MuiBox, MuiTypography } from 'components/atoms';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import Hero from 'assets/images/PNG/hero.png';
import Hero2 from 'assets/images/PNG/hero2.png';
import Hero3 from 'assets/images/PNG/hero3.png';

const heroSlider = [
    { img: Hero, title: 'Exclusive Offer for Nurses!' },
    { img: Hero2, title: 'Exclusive Offer for Nurses!' },
    { img: Hero3, title: 'Exclusive Offer for Nurses!' },
]
const HeroSwiper = () => {
    return (
        <Swiper spaceBetween={20} pagination={true} modules={[Pagination]} className="hero-swiper">
            {heroSlider.map((item, index) => (
                <SwiperSlide className='hero-swiper-slide' key={index}>
                    <MuiBox className='hero-slide-box'>
                        <Grid container rowSpacing={1}>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <MuiBox sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <MuiTypography variant={'h1'} color='white' fontWeight='600' sx={{ textAlign: 'center' }}>
                                        {item.title}
                                    </MuiTypography>
                                </MuiBox>
                            </Grid>
                            <Grid xs={12} sm={12} md={6} lg={6}>
                                <MuiBox className='hero-slide-img'>
                                    <img src={item.img} className='img-cover' />
                                </MuiBox>
                            </Grid>
                        </Grid>
                    </MuiBox>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default HeroSwiper