import * as Yup from "yup";
import { onlySpaceRegExp, startsWithSpaceRegExp } from "utils/regex";

export const reason = Yup.string()
  .required("Please enter notes")
  .matches(startsWithSpaceRegExp, "Reason cannot start with a space")
  .matches(onlySpaceRegExp, "Please enter notes");

export const startDate = Yup.string().required("Please enter start date");
export const endDate = Yup.string().required("Please enter end date");
