import axiosClient from "utils/api";

export const getExperienceForWeb = async () => {
  try {
    const response = await axiosClient().request({
      url: "/experience/experience",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get Experience", error);
    throw error;
  }
};

export const addExperience = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/experience/experience",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Add Experience", error);
    throw error;
  }
};

export const editExperience = async (payload) => {
  try {
    let { id, ...rest} = payload
    const response = await axiosClient().request({
      url: `/experience/experience/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Edit Address", error);
    throw error;
  }
};

export const deleteExperience = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `/experience/experience/${payload.id}`,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Delete Experience", error);
    throw error;
  }
};
