import React, { useEffect, useState } from "react";
import { WebInput, MuiBox, MuiTypography, SearchInput } from "components/atoms";
import { Link, NavLink, Outlet } from "react-router-dom";
import {
  Badge,
  IconButton,
  Drawer,
  Toolbar,
  AppBar,
  Divider,
  Button,
  Popover,
  Tabs,
  Tab,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TabContext, TabPanel } from "@mui/lab";

import CloseIcon from "@mui/icons-material/Close";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AboutCareLogo from "assets/images/PNG/aboutcare-logo.png";
import AboutCareLogoWhite from "assets/images/PNG/aboutcare-logo-white.png";
import AboutcareSmall from "assets/images/PNG/aboutcare.png";
import BellIcon from "assets/images/SVG/bellicon.svg";
import ProfileIcon from "assets/images/PNG/profile.png";
import SearchIcon from "assets/images/SVG/SearchIconBlack.svg";
import Calender from "assets/images/SVG/CalendarBlue.svg";
import Clock from "assets/images/SVG/clock white.svg";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { GetMeForWebAction } from "store/middlewares/me";
import { GetNotificationSettings } from "store/middlewares/notifications";

const footerLinks = [
  { path: "about", name: "About" },
  { path: "careers", name: "Careers" },
  { path: "customercare", name: "Customer Care" },
  { path: "services", name: "Services" },
];

const privacyLinks = [
  { name: "Terms & Conditions", path: "/terms" },
  { name: "Privacy Policy", path: "/privacy" },
];

const WebLayout = ({ type }) => {
  const dispatch = useDispatch();

  const { me } = useSelector((state) => state.me)

  useEffect(() => {
    dispatch(GetMeForWebAction());
    dispatch(GetNotificationSettings());
  }, []);

  const typeProfessional = type === "professional";
  const navLinks = typeProfessional
    ? [
        { path: "home", name: "Home", icon: <HomeIcon /> },
        { path: "direct-hire", name: "Direct Hire", icon: <WorkIcon /> },
        {
          path: "credentials",
          name: "Credentials",
          icon: <EventAvailableIcon />,
        },
        { path: "schedule", name: "Schedule", icon: <ModeCommentIcon /> },
        {
          path: "message",
          name: "Message",
          icon: <AccountBalanceWalletIcon />,
        },
      ]
    : [
        { path: "home", name: "Home", icon: <HomeIcon /> },
        { path: "jobs", name: "Jobs", icon: <WorkIcon /> },
        { path: "schedule", name: "Schedule", icon: <EventAvailableIcon /> },
        { path: "message", name: "Message", icon: <ModeCommentIcon /> },
        {
          path: "revenue",
          name: "Revenue",
          icon: <AccountBalanceWalletIcon />,
        },
      ];
  const isMdDown = useMediaQuery(
    typeProfessional ? "(max-width:986px)" : "(max-width:920px)"
  );

  // For sidebar drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(true);
  };
  const toggleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // For search drawer
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const toggleSearchDrawer = () => {
    setSearchDrawerOpen(!searchDrawerOpen);
  };

  // For notification popover
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  // For notification popover's tabs
  const [notificationActiveTab, setNotificationActiveTab] = React.useState("1");
  const handleNotificationTab = (event, newValue) => {
    setNotificationActiveTab(newValue);
  };

  return (
    <MuiBox sx={{ backgroundColor: "background.main" }}>
      {/* HEADER ----------Start */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#ffff",
          height: "75px",
          boxShadow: "none",
          overflow: "hidden",
          position: "relative",
          zIndex: anchorEl ? "9999999" : "0",
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            maxWidth: "1400px",
            height: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          {isMdDown ? (
            <MuiBox sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  // dispatch(openAdminModal({
                  //     anchor: 'left', borderRadiusLeft: '0',
                  //     content: <NavbarMobileModal />
                  // }))
                  toggleDrawer();
                }}
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <MuiBox sx={{ maxWidth: "60px" }}>
                <img src={AboutcareSmall} />
              </MuiBox>
            </MuiBox>
          ) : (
            <>
              <MuiBox sx={{ maxWidth: "158px", flexShrink: 0 }}>
                <Link to="home">
                  <img
                    src={AboutCareLogo}
                    alt="about care"
                    className="img-contain"
                  />
                </Link>
              </MuiBox>
              <nav
                className="navbar-container"
                style={{ maxWidth: typeProfessional ? "460px" : "410px" }}
              >
                <ul className="navbar">
                  {navLinks.map((item) => (
                    <li key={item.path} className="navbar-item">
                      <NavLink
                        to={item.path}
                        className={({ isActive }) =>
                          `navbar-link ${isActive ? "active" : ""}`
                        }
                      >
                        <MuiTypography
                          variant="baseFont"
                          sx={{ textWrap: "nowrap" }}
                        >
                          {item.name}
                        </MuiTypography>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </>
          )}

          <MuiBox
            sx={{
              maxWidth: "488px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              gap: 2,
            }}
          >
            {isMdDown ? (
              <IconButton
                onClick={toggleSearchDrawer}
                aria-label="search"
                size="large"
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E8F0F3",
                  borderRadius: "12px",
                  p: "9px",
                }}
              >
                <img src={SearchIcon} alt="search" />
              </IconButton>
            ) : (
              <MuiBox sx={{ maxWidth: "377px" }}>
                <SearchInput bgColor="#E8F0F3" />
              </MuiBox>
            )}
            <Tooltip title="Notification">
              <IconButton
                aria-label="notification"
                size="large"
                onClick={handleClick}
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E8F0F3",
                  borderRadius: "12px",
                }}
              >
                <Badge color="info" variant="dot" badgeContent=" ">
                  <img src={BellIcon} alt="notification" />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Profile">
              <IconButton
                aria-label="profile"
                size="large"
                component={Link}
                to={`/${me?.role}/profile`}
                sx={{
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E8F0F3",
                  borderRadius: "12px",
                  p: 0,
                  overflow: "hidden",
                }}
              >
                <MuiBox sx={{ height: "100%" }}>
                  <img src={me?.profileImageUrl ? me?.profileImageUrl : ProfileIcon} alt="profile" className="img-cover" />
                </MuiBox>
              </IconButton>
            </Tooltip>
          </MuiBox>
        </Toolbar>
      </AppBar>
      {/* NAVBAR DRAWER MENU -----Start */}
      {isMdDown ? (
        <>
          {drawerOpen ? (
            <Drawer
              anchor="left"
              open={drawerOpen}
              className="navbar-drawer"
              onClose={toggleDrawerClose}
            >
              <MuiBox>
                <MuiBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={toggleDrawerClose}
                    sx={{
                      backgroundColor: "button.dark",
                      width: "30px",
                      height: "30px",
                      borderRadius: "12px !important",
                      margin: "10px",
                    }}
                  >
                    <Close sx={{ color: "darkButton.light" }} />
                  </IconButton>
                </MuiBox>
                <MuiBox
                  sx={{ maxWidth: "200px", m: "0 auto", p: "0 10px 16px 10px" }}
                >
                  <img
                    src={AboutCareLogo}
                    alt="about care"
                    className="img-contain"
                  />
                </MuiBox>
                <Divider />
                <List>
                  {navLinks.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        component={NavLink}
                        to={item.path}
                        onClick={toggleDrawerClose}
                        className="navbar-link"
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </MuiBox>
            </Drawer>
          ) : null}
        </>
      ) : null}
      {/* NAVBAR DRAWER MENU -----End */}

      {/* SEARCH DRAWER -----Start */}
      {isMdDown ? (
        <Drawer
          anchor="top"
          open={searchDrawerOpen}
          onClose={toggleSearchDrawer}
        >
          <MuiBox
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SearchInput bgColor="#E8F0F3" autoFocus={true} />
            <IconButton onClick={toggleSearchDrawer}>
              <CloseIcon />
            </IconButton>
          </MuiBox>
        </Drawer>
      ) : null}
      {/* SEARCH DRAWER -----End */}

      {/* NOTIFICATION -----Start */}
      <Popover
        className="notification-dropdown"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TabContext value={notificationActiveTab}>
          <MuiBox
            sx={{
              bgcolor: "#E8F0F3",
              p: { xs: "15px", sm: "28px" },
              borderBottomLeftRadius: "12px",
              borderBottomRightRadius: "12px",
            }}
          >
            <MuiBox sx={{ bgcolor: "#fff", borderRadius: "12px", pt: 1 }}>
              <Tabs
                centered
                value={notificationActiveTab}
                onChange={handleNotificationTab}
                className="notification-tabs"
              >
                <Tab label="Jobs" className="notification-tab" value="1" />
                <Tab
                  label="Information"
                  className="notification-tab"
                  value="2"
                />
              </Tabs>
            </MuiBox>

            <TabPanel value="1" sx={{ p: 0 }}>
              <MuiBox
                sx={{
                  mt: "28px",
                  minHeight: "105px",
                  maxHeight: "345px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  overflowY: "auto",
                }}
                className="no-scrollbar"
              >
                <MuiBox
                  component={Link}
                  sx={{
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    p: { xs: "8px", sm: "12px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    borderLeft: "2px solid #0CA8DF",
                  }}
                >
                  <MuiTypography
                    variant="baseFont"
                    color="text.main"
                    fontWeight="bold"
                  >
                    RN’S
                  </MuiTypography>
                  <MuiTypography
                    variant="baseFont"
                    color="text.blue"
                    fontWeight="bold"
                  >
                    USA old care hospital
                  </MuiTypography>
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <img src={Calender} />
                    <MuiTypography
                      fontSize="14px"
                      color="text.main"
                      fontWeight="bold"
                      sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                    >
                      <span>07/03/2024</span>
                      <span>To</span>
                      <span>07/05/2024</span>
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  component={Link}
                  sx={{
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    p: { xs: "8px", sm: "12px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    borderLeft: "2px solid #0CA8DF",
                  }}
                >
                  <MuiTypography
                    variant="baseFont"
                    color="text.main"
                    fontWeight="bold"
                  >
                    RN’S
                  </MuiTypography>
                  <MuiTypography
                    variant="baseFont"
                    color="text.blue"
                    fontWeight="bold"
                  >
                    USA old care hospital
                  </MuiTypography>
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <img src={Calender} />
                    <MuiTypography
                      fontSize="14px"
                      color="text.main"
                      fontWeight="bold"
                      sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                    >
                      <span>07/03/2024</span>
                      <span>To</span>
                      <span>07/05/2024</span>
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  component={Link}
                  sx={{
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    p: { xs: "8px", sm: "12px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    borderLeft: "2px solid #0CA8DF",
                  }}
                >
                  <MuiTypography
                    variant="baseFont"
                    color="text.main"
                    fontWeight="bold"
                  >
                    RN’S
                  </MuiTypography>
                  <MuiTypography
                    variant="baseFont"
                    color="text.blue"
                    fontWeight="bold"
                  >
                    USA old care hospital
                  </MuiTypography>
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <img src={Calender} />
                    <MuiTypography
                      fontSize="14px"
                      color="text.main"
                      fontWeight="bold"
                      sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                    >
                      <span>07/03/2024</span>
                      <span>To</span>
                      <span>07/05/2024</span>
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
                <MuiBox
                  component={Link}
                  sx={{
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    p: { xs: "8px", sm: "12px" },
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    borderLeft: "2px solid #0CA8DF",
                  }}
                >
                  <MuiTypography
                    variant="baseFont"
                    color="text.main"
                    fontWeight="bold"
                  >
                    RN’S
                  </MuiTypography>
                  <MuiTypography
                    variant="baseFont"
                    color="text.blue"
                    fontWeight="bold"
                  >
                    USA old care hospital
                  </MuiTypography>
                  <MuiBox
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <img src={Calender} />
                    <MuiTypography
                      fontSize="14px"
                      color="text.main"
                      fontWeight="bold"
                      sx={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
                    >
                      <span>07/03/2024</span>
                      <span>To</span>
                      <span>07/05/2024</span>
                    </MuiTypography>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <MuiBox
                sx={{
                  mt: "28px",
                  minHeight: "105px",
                  maxHeight: "345px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  overflowY: "auto",
                }}
                className="no-scrollbar"
              >
                <MuiBox
                  component={Link}
                  sx={{
                    borderRadius: "12px",
                    bgcolor: "#fff",
                    p: { xs: "8px", sm: "12px" },
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <MuiBox
                    sx={{
                      width: "43px",
                      height: "43px",
                      bgcolor: "#0A1E41",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexShrink: "0",
                    }}
                  >
                    <img
                      src={Clock}
                      style={{ height: "24px", width: "24px" }}
                    />
                  </MuiBox>
                  <MuiBox
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <MuiBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap-reverse",
                      }}
                    >
                      <MuiBox sx={{ maxWidth: "16em" }}>
                        <MuiTypography
                          fontSize="14px"
                          color="text.main"
                          fontWeight="bold"
                        >
                          Appointment Reminder
                        </MuiTypography>
                      </MuiBox>
                      <MuiTypography fontSize="14px" color="text.contrastText">
                        9:41 AM
                      </MuiTypography>
                    </MuiBox>
                    <MuiBox sx={{ maxWidth: "16em" }}>
                      <MuiTypography fontSize="14px" color="text.contrastText">
                        {
                          "Don't forget your check-up tomorrow at 10 AM with Dr. Smith. See you then!"
                        }
                      </MuiTypography>
                    </MuiBox>
                  </MuiBox>
                </MuiBox>
              </MuiBox>
            </TabPanel>
          </MuiBox>
        </TabContext>
      </Popover>
      {/* NOTIFICATION -----End */}
      {/* HEADER ----------End */}

      {/* MAIN CONTENT -----Start */}
      <MuiBox component="main" sx={{ backgroundColor: "background.main" }}>
        <MuiBox
          sx={{
            minHeight: "calc(100dvh - 75px)",
            maxWidth: "1424px",
            margin: "0 auto",
            p: {
              xs: "8px 8px 60px 8px",
              sm: "16px 16px 60px 16px",
              md: "24px 24px 60px 24px",
            },
          }}
        >
          <Outlet />
        </MuiBox>
      </MuiBox>
      {/* MAIN CONTENT -----End */}

      {/* FOOTER -----Start */}
      <MuiBox
        component="footer"
        sx={{
          backgroundColor: "button.dark",
          borderTopLeftRadius: "50px",
          borderTopRightRadius: "50px",
        }}
      >
        <MuiBox
          sx={{
            maxWidth: "1400px",
            margin: "0 auto",
            p: { xs: "12px", sm: "24px" },
          }}
        >
          <MuiBox sx={{ maxWidth: "158px", m: { xs: "0 auto", md: "0" } }}>
            <img
              src={AboutCareLogoWhite}
              alt="about care"
              className="img-contain"
            />
          </MuiBox>
          <MuiBox
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: { sm: "center", md: "space-between" },
              gap: 4,
              pt: { xs: 2, sm: 4, md: 1 },
              pb: 2,
            }}
          >
            <MuiBox className="footer-links-list">
              <ul>
                {footerLinks.map((item, index) => (
                  <li key={index}>
                    <Link to={item.path}>
                      <MuiTypography
                        variant="baseFont"
                        sx={{ color: "text.light" }}
                      >
                        {item.name}
                      </MuiTypography>
                    </Link>
                  </li>
                ))}
              </ul>
            </MuiBox>
            <MuiBox
              sx={{
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MuiTypography variant="baseFont" sx={{ color: "text.light" }}>
                Get the latest news from us
              </MuiTypography>
              <form autoComplete="off" className="footer-form">
                <WebInput
                  className="footer-input"
                  placeholder="Your email address"
                  required={false}
                />
                <Button
                  variant="contained"
                  sx={{
                    mt: "8px",
                    borderRadius: "12px",
                    bgcolor: "button.main",
                    px: "30px",
                  }}
                >
                  Subscribe
                </Button>
              </form>
            </MuiBox>
          </MuiBox>
          <Divider sx={{ bgcolor: "#71777D" }} />
          <MuiBox
            className="privacy-links"
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: { sm: "center", md: "space-between" },
              gap: { xs: 2, sm: 4 },
              pt: { xs: 2, sm: 4 },
            }}
          >
            <ul>
              {privacyLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <li>
                    <Link>
                      <MuiTypography
                        variant="baseFont"
                        sx={{ color: "text.light" }}
                      >
                        {link.name}
                      </MuiTypography>
                    </Link>
                  </li>
                  {index < privacyLinks.length - 1 && (
                    <Divider
                      sx={{ bgcolor: "background.main" }}
                      orientation="vertical"
                      flexItem
                    />
                  )}
                </React.Fragment>
              ))}
            </ul>

            <MuiTypography color="#92989F" sx={{ textAlign: "center" }}>
              Design with love © Wedefineapps 2024. All right reserved
            </MuiTypography>
          </MuiBox>
        </MuiBox>
      </MuiBox>
      {/* FOOTER -----End */}
    </MuiBox>
  );
};

export default WebLayout;
