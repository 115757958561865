import React from "react";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";
import { IconButton } from "@mui/material";

import Back from "assets/images/SVG/backArrow.svg";
import { useNavigate } from "react-router-dom";

const AuthHeader = ({ title, backButtonFunc }) => {
  const navigate = useNavigate();

  return (
    <MuiBox
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flexWrap: "wrap",
        gap: 2,
        mb: 5,
        mt:3
      }}
    >
      <MuiBox sx={{ width: "fit-content", position: "relative", left: 0 }}>
        <IconButton
          aria-label="back"
          className="back-button button-hover"
          onClick={backButtonFunc ? backButtonFunc : () => navigate(-1)}
        >
          <img src={Back} alt="Back" />
        </IconButton>
      </MuiBox>
      <MuiBox
        sx={{
          width: "fit-content",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <MuiTypography
          variant="h4"
          fontWeight="bold"
          sx={{ pr: 2 }}
          className="text-center"
        >
          {title}
        </MuiTypography>
      </MuiBox>
    </MuiBox>
  );
};

export default AuthHeader;
