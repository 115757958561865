import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import BlankSvg from "assets/images/blank.png";
import { Grid } from "@mui/material";
import { ImageFile, name, Price } from "utils/validation/AddBadges";
import { AddBadgesAction } from "store/middlewares/badges";
import { useDispatch } from "react-redux";
import { DollarSign } from "lucide-react";

function ModalAddBadges() {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(BlankSvg);

  const intitalVal = {
    name: "",
    price: "",
    file: "",
  };

  const AddBadgesSchema = Yup.object({
    name: name,
    file: ImageFile,
    price: Price,
  });

  const handleSubmit = (values) => {
    values.price = String(values.price);
    dispatch(AddBadgesAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddBadgesSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024;
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("file", file);
        };
        reader.readAsDataURL(file);
      } else {
        formik.setFieldValue("file", "");
        formik.setFieldTouched("file", true);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="">
        <label className="font-medium">
          Badges Image <span className="text-[#FF5C5C]">*</span>
        </label>
        <br />
        <div className="flex flex-col mt-2 gap-x-2.5">
          <div className="relative">
            <div className="h-48 w-48 bg-white">
              <input
                type="file"
                name="file"
                accept="image/png, image/jpeg,image/jpg"
                id="editimg"
                onChange={(e) => {
                  handleFileChange(e);
                  formik.setFieldValue("file", e.target.files[0]);
                }}
                hidden
              />
              <label htmlFor="editimg" className="cursor-pointer">
                <div className="w-48 h-48 overflow-hidden">
                  <img
                    src={imageSrc}
                    alt="flag"
                    className="w-full h-full object-cover"
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
        {formik.touched.file && formik.errors.file && (
          <div className="text-red-500 text-sm mt-1">{formik.errors.file}</div>
        )}
      </div>
      <Grid container columnSpacing={5} rowSpacing={3}>
        <Grid item md={6} xs={12}>
          <Input
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"Name"}
            error={formik.errors.name && formik.touched.name}
            errorMsg={formik.errors.name}
            value={formik.values.name}
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter badge name"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            inputIcon={
              <DollarSign className="absolute size-[39px] bg-[#f5f8fa] left-0 top-2 p-2 text-[#0ca8df] rounded-s-lg border-zinc-400 border" />
            }
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 pl-[45px]"
            label={"Price"}
            error={formik.errors.price && formik.touched.price}
            errorMsg={formik.errors.price}
            value={formik.values.price}
            type="number"
            name="price"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Enter badge price"
          />
        </Grid>
      </Grid>

      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddBadges;
