import React from "react";

const DoughnutChart = ({ percentage, colorVariant = 0, size = 93 }) => {
  const radius = (size - 10) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  const colors = {
    0: { start: "#4FC0F4", end: "#6952FF" },
    1: { start: "#FF9A35", end: "#6952FF" },
    2: { start: "#0CC653", end: "#6952FF" },
    3: { start: "#FF4C4F", end: "#6952FF" },
  };
  const { start, end } = colors[colorVariant] || colors[1];
  const gradientId = `gradient-${colorVariant}-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id={gradientId} x1="1" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={start} />
          <stop offset="100%" stopColor={end} />
        </linearGradient>
      </defs>
      {/* Background circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="#585F71"
        strokeWidth="7"
      />
      {/* Gradient circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={`url(#${gradientId})`}
        strokeWidth="10"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotate to start at the top
      />
      {/* Percentage text */}
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dy=".3em"
        fontSize="20"
        fontWeight="bold"
        fill="#333"
      >
        {percentage}%
      </text>
    </svg>
  );
};

export default DoughnutChart;
