import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllProfessional,
  addProfessional,
  editProfessional,
  getProfessionalById,
  getHolidaysForProfessional,
  deleteProfessional,
  blockProfessionalUser,
  getProfessionalUserHolidays,
  unblockProfessionalUser,
  restoreProfessionalUser,
  activeProfessionalUser,
  addUploadFile,
  getAllProfessionalNotification,
  getProfessionalExperience,
} from "store/services/users/professionals.js";
import { closeAdminModal } from "store/slices/common/adminModal.slice.js";
import { openToast } from "store/slices/common/toast.slice.js";
import { handleAuthError } from "utils/handleAuthError.js";

export const GetAllProfessionalAction = createAsyncThunk(
  "professionals/getAllProfessional",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getAllProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetProfessionalByIdAction = createAsyncThunk(
  "professionals/getProfessionalByIdAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getProfessionalById(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetProfessionalExperience = createAsyncThunk(
  "professionals/getProfessionalExperience",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalExperience(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddProfessionalAction = createAsyncThunk(
  "professionals/addProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      let url = "";
      if (file) {
        const uploadFileResponse = await addUploadFile({ file, uploadFor });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      let addPayload = { ...rest };
      if (url) {
        addPayload = { ...addPayload, profileImage: url };
      }

      const response = await addProfessional(addPayload);
      if (response.status === 1) {
        window.location = "/admin/professional/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const EditProfessionalAction = createAsyncThunk(
  "professionals/editProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, uploadFor, ...rest } = payload;
      let url = "";
      if (typeof file !== "string" && file !== null) {
        const uploadFileResponse = await addUploadFile({ file, uploadFor });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      let editPayload = { ...rest };
      if (url) {
        editPayload = { ...editPayload, profileImage: url };
      }

      const response = await editProfessional(editPayload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        window.history.go(-1);
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteProfessionalAction = createAsyncThunk(
  "users/deleteProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await deleteProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        window.location = "/admin/professional/active";
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const GetHolidaysForProfessional = createAsyncThunk(
  "professionals/getHolidaysForProfessional",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getHolidaysForProfessional(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Active User
export const ActiveProfessionalAction = createAsyncThunk(
  "professionals/activeProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await activeProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Block User
export const BlockProfessionalAction = createAsyncThunk(
  "professionals/blockProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await blockProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Unblock User
export const UnblockProfessionalAction = createAsyncThunk(
  "professionals/unblockProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await unblockProfessionalUser(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Restore User
export const RestoreProfessionalAction = createAsyncThunk(
  "professionals/restoreProfessionalAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    let { goBack, ...rest } = payload;
    try {
      const response = await restoreProfessionalUser(rest);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        if (goBack) {
          window.location = "/admin/professional/active";
        }
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Holidays Data For User
export const GetProfessionalUserHolidays = createAsyncThunk(
  "professionals/getProfessionalUserHolidays",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await getProfessionalUserHolidays(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//get professional notification
export const GetNotificationForProfessional = createAsyncThunk(
  "professionals/getNotificationForProfessional",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await getAllProfessionalNotification(payload);
      if (response.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(response);
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
