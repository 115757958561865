import { createSlice } from "@reduxjs/toolkit";
import {
  AddProvinceAction,
  DeleteProvinceAction,
  GetAllProvincesAction,
  UpdateProvinceAction,
  UpdateVisiblityProvinceAction,
} from "store/middlewares/provinces";

export const provincesSlice = createSlice({
  name: "provinces",
  initialState: {
    provincesLoading: false,
    provinces: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllProvincesAction.pending, (state) => {
        state.provincesLoading = true;
        state.provinces = [];
      })
      .addCase(GetAllProvincesAction.fulfilled, (state, action) => {
        state.provincesLoading = false;
        state.provinces = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllProvincesAction.rejected, (state) => {
        state.provincesLoading = false;
        state.provinces = [];
      })
      // Delete province
      .addCase(DeleteProvinceAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.provinces?.filter((title) => title.id !== id);
        state.provincesLoading = false;
        state.provinces = tempArray;
      })
      //Update province
      .addCase(UpdateProvinceAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.provinces?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.provincesLoading = false;
        state.provinces = tempArray;
      })
      .addCase(UpdateVisiblityProvinceAction.fulfilled, (state, action) => {
        state.provincesLoading = false;
        let { id } = action.payload;
        let tempArray = state.provinces?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.provinces = tempArray;
      })
      // .addCase(UpdateDefaultCountryAction.fulfilled, (state, action) => {
      //   state.provincesLoading = false;
      //   let { id } = action.payload;
      //   let tempArray = state.provinces?.map((title) => {
      //     if (title.id === id) {
      //       return { ...title, isDefault: true };
      //     }
      //     return { ...title, isDefault: false };
      //   });
      //   state.provinces = tempArray;
      // })
      //Add province
      .addCase(AddProvinceAction.fulfilled, (state, action) => {
        state.provincesLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.provinces = [...state.provinces, Obj];
      });
  },
});

export default provincesSlice.reducer;
