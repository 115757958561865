import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";

const DropDownContext = createContext(undefined);

const Dropdown = ({ as: Component = "div", children, className }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen((previousState) => !previousState);
  }, []);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (open && toggleOpen) {
          toggleOpen();
        }
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open, toggleOpen]);

  return (
    <DropDownContext.Provider value={{ open, setOpen, toggleOpen }}>
      <Component ref={dropdownRef} className={`dropdown ${className}`}>
        {children}
      </Component>
    </DropDownContext.Provider>
  );
};

const Trigger = ({ type, children, className, id }) => {
  const { open, toggleOpen } = useContext(DropDownContext);

  const getClassNameButton = className
    ? className
    : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded";
  const getClassNameLink = className
    ? className
    : "transition-all duration-200 ease-linear bg-white border-dashed dropdown-toggle text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600  ";

  return (
    <>
      {type === "a" ? (
        <Link
          id={id}
          to="/#"
          onClick={(e) => {
            e.preventDefault();
            toggleOpen();
          }}
          className={getClassNameLink + (open ? " show" : "")}
        >
          {children}
        </Link>
      ) : (
        <button id={id} onClick={toggleOpen} className={getClassNameButton}>
          {children}
        </button>
      )}
    </>
  );
};

const Content = ({ as: Component = "div", className, children, placement }) => {
  const { open, setOpen } = useContext(DropDownContext);

  const getClassName =
    className ||
    "absolute z-50 py-2 mt-1 text-left list-none bg-white rounded-md shadow-md dropdown-menu min-w-max ";

  const [placementState, setPlacement] = useState("right-end");

  useEffect(() => {
    if (placement) setPlacement(placement);
  }, [placement]);

  const dropdownElementRef = useRef(null);

  const isRtl = document.getElementsByTagName("html")[0].getAttribute("dir");

  const getDropdownStyle = () => {
    if (open && placementState === "right-end" && dropdownElementRef.current) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      isRtl === "rtl"
        ? (dropdownElement.style.inset = "0px auto auto 0px")
        : (dropdownElement.style.inset = "0px 0px auto auto");
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(0px, 54px)";
    }
    if (open && placementState === "start-end" && dropdownElementRef.current) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      dropdownElement.style.inset = "0px auto auto 0px";
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(0px, 20px)";
    }
    if (open && placementState === "top-end" && dropdownElementRef.current) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      dropdownElement.style.inset = "auto 0px 0px auto";
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(-58px, -30px)";
    }
    if (
      open &&
      placementState === "bottom-start" &&
      dropdownElementRef.current
    ) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      dropdownElement.style.inset = "0px 0px auto auto";
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(0px, 54px)";
    }
    if (open && placementState === "bottom-end" && dropdownElementRef.current) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      dropdownElement.style.inset = "0px 0px auto auto";
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(0px, 39px)";
    }
    if (open && placementState === "top-start" && dropdownElementRef.current) {
      const dropdownElement = dropdownElementRef.current;
      dropdownElement.style.position = "absolute";
      dropdownElement.style.inset = "auto auto 0px 0px";
      dropdownElement.style.margin = "0px";
      dropdownElement.style.transform = "translate(0px, -95px)";
    }
    return {};
  };

  return (
    <Transition as={React.Fragment} show={open}>
      {(status) => (
        <Component
          ref={dropdownElementRef}
          onClick={() => setOpen(false)}
          className={`${getClassName} ${
            status === "entered" ? "transition-all" : ""
          }`}
          style={getDropdownStyle()}
        >
          {children}
        </Component>
      )}
    </Transition>
  );
};

Dropdown.Trigger = Trigger;
Dropdown.Content = Content;

export { Dropdown };
