import axiosClient from "utils/api";
const URI = "/admin/plan";

export const getSubscriptions = async () => {
  const URL = `${URI}/subscribers`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Subscriptions:", error);
    throw error;
  }
};