import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers";
import MuiBox from "../MuiBox";
import MuiTypography from "../MuiTypography";

const CustomTimePicker = ({ label, onChange, value }) => {
  return (
    <MuiBox>
      <MuiTypography variant="baseFont" fontWeight="bold">
        {label}
      </MuiTypography>
      <MuiBox sx={{ mt: "8px" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            value={value}
            onChange={onChange}
            className="time-picker-custom"
            sx={{
              bgcolor: "#fff",
              borderRadius: "12px",
              width: "100%",
            }}
          />
        </LocalizationProvider>
      </MuiBox>
    </MuiBox>
  );
};

export default CustomTimePicker;
