import { MuiBox, MuiTypography } from "components/atoms";
import React, { useState, useEffect } from "react";

function TimerClock() {
    const [time, setTime] = useState(0); // Tracks elapsed time in seconds
    const [isRunning, setIsRunning] = useState(false); // Controls whether the timer runs

    useEffect(() => {
        let timer;

        if (isRunning) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isRunning]);

    // useEffect(() => {
    //     const simulateBackendResponse = setTimeout(() => {
    //         console.log("Backend response received!");
    //         setIsRunning(false);
    //     }, 10000);

    //     return () => clearTimeout(simulateBackendResponse);
    // }, []);

    // Format time into HH:MM:SS
    const formatTime = (totalSeconds) => {
        const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
        const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
        const seconds = String(totalSeconds % 60).padStart(2, "0");
        return { hours, minutes, seconds };
    };

    const { hours, minutes, seconds } = formatTime(time);

    return (
        <>
            <MuiBox id="clock" sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: '5px' }}>
                <MuiBox sx={{ height: '54px', width: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#E8F0F3', borderRadius: '6px' }}>
                    <MuiTypography variant="h5" fontWeight='bold'>
                        02
                    </MuiTypography>
                </MuiBox>
                <MuiTypography variant="h5" fontWeight='bold'>
                    :
                </MuiTypography>
                <MuiBox sx={{ height: '54px', width: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#E8F0F3', borderRadius: '6px' }}>
                    <MuiTypography variant="h5" fontWeight='bold'>
                        59
                    </MuiTypography>
                </MuiBox>
                <MuiTypography variant="h5" fontWeight='bold'>
                    :
                </MuiTypography>
                <MuiBox sx={{ height: '54px', width: '54px', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#E8F0F3', borderRadius: '6px' }}>
                    <MuiTypography variant="h5" fontWeight='bold'>
                        59
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
            {/* <button
                onClick={() => setIsRunning(true)}
                disabled={isRunning}
                style={{ marginLeft: "20px" }}
            >
                Start Clock
            </button> */}
        </>
    );
}

export default TimerClock;
