import React from "react";
import { ModalBlockHospital, ModalBlockUser } from "components/molecules";
import BanIcon from "assets/images/BanIcon.svg";
import UnbanIcon from "assets/images/UnnbanIcon.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import { UnblockProfessionalAction } from "store/middlewares/users/professionals";
import { UnblockHospitalAction } from "store/middlewares/users/hospitals";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";

const BlockUnblockBtn = ({ row, blockFor = "user" }) => {
  const dispatch = useDispatch();
  return (
    <>
      {row?.bannedAt === null ? (
        <IconButton
          sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() =>
            dispatch(
              openAdminModal({
                title: `Block ${row?.name}`,
                content:
                  blockFor === "user" ? (
                    <ModalBlockUser row={row} />
                  ) : (
                    <ModalBlockHospital row={row} />
                  ),
              })
            )
          }
        >
          <div className="text-slate-500 btn bg-[#ed6c02] border-slate-200 hover:bg-[#CC5E00] hover:border-slate-30">
            <img src={BanIcon} className="inline-block size-5 mr-1" />
            <span className="align-middle text-white">Block</span>
          </div>
        </IconButton>
      ) : (
        <IconButton
          sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() => {
            Swal.fire({
              title: `Are you sure you want to unblock this ${blockFor === "user" ? "Professional" : "Hospital"}?`,
              imageUrl: ApprovedSVGModel,
              imageWidth: 180,
              imageHeight: 180,
              showCancelButton: true,
              confirmButtonColor: "#00AE46",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                if (blockFor === "user") {
                  dispatch(UnblockProfessionalAction({ id: row.id }));
                } else {
                  dispatch(UnblockHospitalAction({ id: row.id }));
                }
              }
            });
          }}
        >
          <div className="text-slate-500 btn bg-[#422dff] border-slate-200 hover:bg-[#635096] hover:border-slate-30">
            <img src={UnbanIcon} className="inline-block size-5 mr-1" />
            <span className="align-middle text-white">Unblock</span>
          </div>
        </IconButton>
      )}
    </>
  );
};

export default BlockUnblockBtn;
