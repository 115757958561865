import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader, TableContainer } from "components/atoms";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { ResumeTableActionBar } from "components/molecules";
import { GetProfessionalUserResume } from "store/middlewares/credential";

const UserResumeTab = ({ id }) => {
  const { resumes = [], resumesLoading } = useSelector(
    (state) => state.credential
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProfessionalUserResume({ id }));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p>{dayjs(row.original?.createdAt).format("DD/MM/YYYY")}</p>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ row }) => (
          <p className="capitalize">{row?.original?.status}</p>
        ),
      },
      {
        id: "action",
        header: <p className="text-right">Action</p>,
        enableColumnFilter: false,
        cell: ({ row }) =>
          row?.original?.status === "pending" ? (
            <ResumeTableActionBar row={row.original} />
          ) : (
            <></>
          ),
      },
    ],
    []
  );
  return (
    <>
      {!resumesLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={true}
              isSelect={true}
              isGlobalFilter={true}
              columns={columns}
              data={resumes}
              customPageSize={10}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </>
  );
};

export default UserResumeTab;
