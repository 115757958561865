import { MuiBox, MuiTypography } from 'components/atoms'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import DollarBlue from 'assets/images/SVG/DollarBlue.svg'
import Target from 'assets/images/SVG/target.svg'
import { Button } from '@mui/material'


const MyJobsCardVerticle = () => {
    const [applied, setApplied] = useState(false)
    const handleApplyClick = () => {
        setApplied(!applied)
    }

    return (
        <MuiBox component={Link} to='/professional/application-details' sx={{ borderRadius: '12px', bgcolor: '#fff', p: { xs: '8px', sm: '12px' }, display: 'flex', flexDirection: 'column', gap: '12px', borderTop: '2px solid #0CA8DF' }}>
            <MuiTypography variant='h6' color='text.main'>
                RN’S
            </MuiTypography>
            <MuiBox
                sx={{ maxWidth: '11em', display: 'flex', gap: '5px', mt: 1, alignItems: 'center' }}>
                <img src={DollarBlue} style={{ height: '24px', width: '24px', objectFit: 'contain' }} />
                <MuiTypography color='text.main' variant="baseFont" >
                    20.00/hr
                </MuiTypography>
            </MuiBox>
            <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: '5px' }}>
                <img src={Target} />
                <MuiBox>
                    <MuiTypography color='text.main' variant='baseFont' >
                        07:00 AM - 11:00 AM
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
            <MuiBox sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', }}>
                <MuiBox sx={{ width: 'fit-content', p: '5px 12px', bgcolor: '#007AFF26', borderRadius: '30px' }}>
                    <MuiTypography color='#2D2635' fontSize='15px' sx={{ whiteSpace: 'nowrap' }}>
                        Patient safety
                    </MuiTypography>
                </MuiBox>
                <MuiBox sx={{ width: 'fit-content', p: '5px 12px', bgcolor: '#007AFF26', borderRadius: '30px' }}>
                    <MuiTypography color='#2D2635' fontSize='15px' sx={{ whiteSpace: 'nowrap' }}>
                        Full Time
                    </MuiTypography>
                </MuiBox>
            </MuiBox>
            <Button variant={applied ? 'darkButton' : 'lightButton'} sx={{ width: 'fit-content', p: '5px 12px' }} onClick={handleApplyClick}>
                {applied ? 'Applied' : 'Apply'}
            </Button>
        </MuiBox>
    )
}

export default MyJobsCardVerticle