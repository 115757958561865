import React, { useEffect } from "react";
import { GetAllCredentialAddressAction } from "store/middlewares/credential";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCountriesAction } from "store/middlewares/countries";
import { GetAllCitiesAction } from "store/middlewares/cities";
import { GetAllProvincesAction } from "store/middlewares/provinces";
import {
  CustomLoader,
  NoDataFound,
  PhysicalAddressItem,
} from "components/atoms";

function PhysicalAddress({ userId }) {
  const dispatch = useDispatch();
  const {
    provinces: { provinces },
    countries: { countries },
    cities: { cities },
    credential: { physicalAddress, physicalAddressLoading },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(GetAllCountriesAction());
    dispatch(GetAllCitiesAction());
    dispatch(GetAllProvincesAction());
    dispatch(GetAllCredentialAddressAction({ id: userId }));
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">
            Physical Address :
          </h6>
          {!physicalAddressLoading ? (
            <>
              {physicalAddress?.length > 0 ? (
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                  {physicalAddress?.map((address) => {
                    let city = cities?.find(
                      (city) => city?.id === address?.cityId
                    )?.name;
                    let state = provinces?.find(
                      (city) => city?.id === address?.stateId
                    )?.name;
                    let country = countries?.find(
                      (city) => city?.id === address?.countryId
                    )?.name;
                    return (
                      <PhysicalAddressItem
                        address={{ ...address, city, country, state }}
                        key={address?.id}
                      />
                    );
                  })}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PhysicalAddress;
