import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { setPlanFilterProps } from "store/slices/admin/subscriptions";

const PlanFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { plansFilterProps } = useSelector(
    (state) => state.subscriptionManagement
  );

  const intitalVal = {
    isVisible: "",
    planType: "",
    min: "",
    max: "",
    maxDuration: "",
    minDuration: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];
  const planTypeOptions = [
    { label: "Free", value: "free" },
    { label: "Paid", value: "paid" },
  ];

  const PlanFilterSchema = Yup.object().shape({
    isVisible: Yup.string().nullable(),
    planType: Yup.string().nullable(),
    maxDuration: Yup.string().nullable(),
    minDuration: Yup.string().nullable(),
    min: Yup.string().nullable(),
    max: Yup.string().nullable(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setPlanFilterProps({
        ...values,
        isVisible: values.isVisible === "" ? null : values.isVisible,
        planType: values.planType === "" ? null : values.planType,
      })
    );
    
  };

  const formik = useFormik({
    initialValues: { ...intitalVal, ...plansFilterProps },
    validationSchema: PlanFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setPlanFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"Plan Type"}
          isRequired={false}
          options={planTypeOptions}
          placeholder="Select plan type"
          name={"planType"}
          value={formik.values.planType}
          error={formik.errors.planType && formik.touched.planType}
          errorMsg={formik.errors.planType}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "planType",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        <Grid container spacing={1} className="">
          <Grid item md={6} xs={12}>
            <Input
              inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
              label={"Min"}
              type="number"
              name="min"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.min && formik.touched.min}
              errorMsg={formik.errors.min}
              value={formik.values.min}
              placeholder="Enter min"
              isRequired={false}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Input
              inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
              label={"Max"}
              type="number"
              name="max"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.max && formik.touched.max}
              errorMsg={formik.errors.max}
              value={formik.values.max}
              placeholder="Enter max"
              isRequired={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className="">
          <Grid item md={6} xs={12}>
          <Input
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"Min Duration"}
          type="number"
          name="minDuration"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.minDuration && formik.touched.minDuration}
          errorMsg={formik.errors.minDuration}
          value={formik.values.minDuration}
          placeholder="Enter min duration"
          isRequired={false}
        />
          </Grid>
          <Grid item md={6} xs={12}>
          <Input
          inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
          label={"Max Duration"}
          type="number"
          name="maxDuration"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.maxDuration && formik.touched.maxDuration}
          errorMsg={formik.errors.maxDuration}
          value={formik.values.maxDuration}
          placeholder="Enter max duration"
          isRequired={false}
        />
          </Grid>
        </Grid>
        
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default PlanFilter;
