import React, { useState } from "react";
import { Button, Slider, Tooltip } from "@mui/material";
import { MuiBox, MuiTypography, Textfield } from "components/atoms";
import * as Yup from "yup";
// Importing emoji assets
import AwfulEmoji from "assets/images/SVG/awful emoji.svg";
import BadEmoji from "assets/images/SVG/bad emoji.svg";
import OkayEmoji from "assets/images/SVG/okay emoji.svg";
import GoodEmoji from "assets/images/SVG/good emoji.svg";
import GreatEmoji from "assets/images/SVG/great emoji.svg";
import { useFormik } from "formik";
import { onlySpaceRegExp } from "utils/regex";
import { AddReviewForChatAction } from "store/middlewares/HelpAndSupport";
import { useDispatch } from "react-redux";
import { closeDrawer } from "store/slices/common/drawer.slice";

// Custom Tooltip for Slider Thumb
const ValueLabelComponent = ({ children, value }) => {
  const emojiLabels = ["Awful", "Bad", "Okay", "Good", "Great"];
  return (
    <Tooltip title={emojiLabels[value - 1]} placement="top" arrow>
      {children}
    </Tooltip>
  );
};

const EmojiReviewsModal = ({ chatId }) => {
  const [rating, setRating] = useState(3);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(
      AddReviewForChatAction({ chatId: chatId, expeirence: rating, ...values })
    );
  };

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    validationSchema: Yup.object().shape({
      notes: Yup.string()
        .required("Please enter Notes")
        .matches(onlySpaceRegExp, "Notes must contain atleast one character"),
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const emojiData = [
    { label: "Awful", emoji: AwfulEmoji },
    { label: "Bad", emoji: BadEmoji },
    { label: "Okay", emoji: OkayEmoji },
    { label: "Good", emoji: GoodEmoji },
    { label: "Great", emoji: GreatEmoji },
  ];

  const handleSliderChange = (event, newValue) => {
    setRating(newValue);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MuiBox
        sx={{
          px: { xs: "15px", sm: "25px", md: "35px" },
          py: 5,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <MuiTypography variant="h6" fontWeight="bold">
          How was your Experience?
        </MuiTypography>

        <MuiBox
          sx={{
            bgcolor: "#fff",
            borderRadius: "12px",
            p: "15px 20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MuiBox sx={{ mb: 2, textAlign: "center" }}>
            <MuiBox
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "space-between",
                px: 1,
              }}
            >
              {emojiData.map((item, index) => (
                <MuiBox key={index} sx={{ width: "50px", height: "50px" }}>
                  <img src={item.emoji} alt={item.label} />
                </MuiBox>
              ))}
            </MuiBox>
          </MuiBox>

          <Slider
            className="emoji-review-slider"
            value={rating}
            onChange={handleSliderChange}
            step={1}
            marks
            min={1}
            max={5}
            valueLabelDisplay="auto"
            components={{
              ValueLabel: ValueLabelComponent,
            }}
            sx={{
              "& .MuiSlider-track": {
                border: "none",
                height: "6px",
                backgroundColor: "transparent",
              },
              "& .MuiSlider-rail": {
                background:
                  "linear-gradient(to right, #CF213D, #F03F42, #F47131, #EBA722, #64C548)",
                height: "6px",
                opacity: 1,
              },
              width: "100%",
              "& .MuiSlider-markLabel": {
                fontSize: "12px",
                fontWeight: "bold",
              },
            }}
            aria-label="Emoji rating"
          />
        </MuiBox>

        <MuiBox>
          <Textfield
            label="Note"
            name={"notes"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.notes}
            error={formik.errors.notes && formik.touched.notes}
            errorMsg={formik.errors.notes}
            bgcolor="#fff"
            placeholder={"Enter here"}
            rows={5}
          />
        </MuiBox>

        <MuiBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Button
            onClick={() => dispatch(closeDrawer())}
            sx={{ maxWidth: "166px" }}
            variant="darkButton"
          >
            Cancel
          </Button>
          <Button
            type={"submit"}
            sx={{ maxWidth: "166px" }}
            variant="lightButton"
          >
            Submit
          </Button>
        </MuiBox>
      </MuiBox>
    </form>
  );
};

export default EmojiReviewsModal;
