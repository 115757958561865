import { createSlice } from "@reduxjs/toolkit";
import {
  AddLanguageAction,
  DeleteLanguageAction,
  GetAllLanguagesAction,
  UpdateLanguageAction,
} from "store/middlewares/language";

export const languagesSlice = createSlice({
  name: "language",
  initialState: {
    languagesLoading: false,
    languages: [],
    languagesFilterProps: {},
  },
  reducers: {
    setLanguagesFilterProps: (state, action) => {
      state.languagesFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllLanguagesAction.pending, (state) => {
        state.languagesLoading = true;
        state.languages = [];
      })
      .addCase(GetAllLanguagesAction.fulfilled, (state, action) => {
        state.languagesLoading = false;
        state.languages = action.payload.response;
      })
      .addCase(GetAllLanguagesAction.rejected, (state) => {
        state.languagesLoading = false;
        state.languages = [];
      })
      // Delete language
      .addCase(DeleteLanguageAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.languages?.filter((title) => title.id !== id);
        state.languagesLoading = false;
        state.languages = tempArray;
      })
      //Update language
      .addCase(UpdateLanguageAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.languages?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.languagesLoading = false;
        state.languages = tempArray;
      })
      //Add language
      .addCase(AddLanguageAction.fulfilled, (state, action) => {
        state.languagesLoading = false;
        state.languages = [action.payload.response,...state.languages];
      })
  },
});


export const {setLanguagesFilterProps} = languagesSlice.actions;

export default languagesSlice.reducer;
