import { createSlice } from "@reduxjs/toolkit";
import {
  AddDeleteReasonAction,
  DeleteDeleteReasonAction,
  GetAllDeleteReasonsAction,
  GetAllDeleteReasonsWebAction,
  UpdateDeleteReasonAction,
} from "store/middlewares/deleteReason";

// isDeleteMeFulFilled
export const deleteReasonsSlice = createSlice({
  name: "deleteReason",
  initialState: {
    deleteReasonsLoading: false,
    deleteReasons: [],
    deleteReasonsFilterProps: {},
  },
  reducers: {
    setDeleteReasonsFilterProps: (state, action) => {
      state.deleteReasonsFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllDeleteReasonsAction.pending, (state) => {
        state.deleteReasonsLoading = true;
        state.deleteReasons = [];
      })
      .addCase(GetAllDeleteReasonsAction.fulfilled, (state, action) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = action.payload.response;
      })
      .addCase(GetAllDeleteReasonsAction.rejected, (state) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = [];
      })
      .addCase(GetAllDeleteReasonsWebAction.pending, (state) => {
        state.deleteReasonsLoading = true;
        state.deleteReasons = [];
      })
      .addCase(GetAllDeleteReasonsWebAction.fulfilled, (state, action) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = action.payload.response;
      })
      .addCase(GetAllDeleteReasonsWebAction.rejected, (state) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = [];
      })
      // Delete  Delete Reason
      .addCase(DeleteDeleteReasonAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.deleteReasons?.filter((title) => title.id !== id);
        state.deleteReasonsLoading = false;
        state.deleteReasons = tempArray;
      })
      //Update  Delete Reason
      .addCase(UpdateDeleteReasonAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.deleteReasons?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.deleteReasonsLoading = false;
        state.deleteReasons = tempArray;
      })
      //Add  Delete Reason
      .addCase(AddDeleteReasonAction.fulfilled, (state, action) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = [action.payload.response,...state.deleteReasons];
      })
  },
});

export const {setDeleteReasonsFilterProps} = deleteReasonsSlice.actions;

export default deleteReasonsSlice.reducer;
