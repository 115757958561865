import axiosClient from "utils/api";
const URI = "/admin/dashboard";

export const getDashboard = async () => {
  const URL = `${URI}/dashboard`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during dashboard:", error);
    throw error;
  }
};
