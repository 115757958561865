import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { menuName } from "utils/validation/AddDynamicMenu";
import { UpdateDynamicMenuAction } from "store/middlewares/dynamicMenu";

function ModalEditDynamicMenu({ row }) {
  const dispatch = useDispatch();

  const AddDynamicMenuSchema = Yup.object({ name: menuName });
  const handleSubmit = (values) => {
    dispatch(UpdateDynamicMenuAction({ id: row?.id, ...values,isVisible:row?.isVisible }));
  };

  const formik = useFormik({
    initialValues: { name: row?.name },
    validationSchema: AddDynamicMenuSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Menu Name"}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Menu Name"
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditDynamicMenu;
