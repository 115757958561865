import { MuiBox, MuiTypography } from 'components/atoms'
import React from 'react'

import LocationIcon from 'assets/images/SVG/LocationIcon.svg'
import Hospital1 from 'assets/images/PNG/hospital1.png'
import { Link } from 'react-router-dom'
const HospitalCard = () => {
    return (
        <MuiBox component={Link} to='/professional/details' sx={{ p: { xs: '10px', sm: '15px' }, bgcolor: '#fff', borderRadius: '20px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <MuiBox sx={{ height: '180px', borderRadius: '12px', overflow: 'hidden' }}>
                <img src={Hospital1} alt='Hospital 1' className='img-cover' />
            </MuiBox>
            <MuiTypography variant='baseFont' fontWeight='bold' color='text.main'>
                Mayo Clinic
            </MuiTypography>
            <MuiBox sx={{ display: 'flex', gap: '6px', alignItems: 'flex-start' }}>
                <img src={LocationIcon} />
                <MuiTypography color='#817D86' variant='baseFont'>
                    101 Sanford Farm Shpg Center,
                    Amsterdam NY 12010
                </MuiTypography>
            </MuiBox>
        </MuiBox>
    )
}

export default HospitalCard