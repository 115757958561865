import axiosClient from "utils/api";
const URI = "/admin/blockReason";

export const getBlockReason = async (payload) => {
  const URL = `${URI}/blockReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All block Reasons:", error);
    throw error;
  }
};

export const deleteBlockReason = async (payload) => {
  const URL = `${URI}/blockReason/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete block Reason :", error);
    throw error;
  }
};

export const updateBlockReason = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/blockReason/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update block Reason :", error);
    throw error;
  }
};

export const addBlockReason = async (payload) => {
  const URL = `${URI}/blockReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add block Reason :", error);
    throw error;
  }
};

export const updateBlockReasonVisiblity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred visiblity block Reason:", error);
    throw error;
  }
};
