import React from "react";
import { useNavigate } from "react-router-dom";
import MuiTypography from "../MuiTypography";
import { IconButton } from "@mui/material";
import MuiBox from "../MuiBox";

import Arrow from "assets/images/SVG/arrow-left.svg";
const BackButtonHeader = ({ title = "back", backButtonFunc }) => {
  const navigate = useNavigate();
  return (
    <MuiBox
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "#fff",
        borderRadius: "12px",
        p: "10px 12px",
        gap: "12px",
        mb: "24px",
      }}
    >
      <IconButton
        aria-label="filter"
        sx={{
          height: "42px",
          width: "42px",
          bgcolor: "#E8F0F3",
          borderRadius: "12px",
        }}
        onClick={backButtonFunc ? backButtonFunc : () => navigate(-1)}
      >
        <img
          src={Arrow}
          style={{ height: "22px", width: "22px", objectFit: "contain" }}
        />
      </IconButton>
      <MuiTypography variant="subtitle1" fontWeight="bold" lineHeight="40px">
        {title}
      </MuiTypography>
    </MuiBox>
  );
};

export default BackButtonHeader;
