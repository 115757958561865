import React, { useMemo } from "react";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
    Filler, // Import Filler for fill behavior
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register Chart.js components, including Filler
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend, Filler);

const LineChart = () => {
    // Use useMemo to create the data object
    const data = useMemo(() => {
        return {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "Months",
                    data: [30, 50, 40, 60, 80, 90, 100, 55, 33, 45, 66, 50],
                    borderColor: "#FF3030",
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);
                        gradient.addColorStop(0, "rgba(126, 72, 193, 0.3)"); // Top color
                        gradient.addColorStop(1, "rgba(254, 86, 89, 0.3)"); // Bottom color
                        return gradient;
                    },
                    fill: "start",
                    pointStyle: 'circle',
                    pointRadius: 8,
                    pointHoverRadius: 12,
                    pointBackgroundColor: '#FFFFFF',
                    pointBorderColor: '#FF3030',
                    pointBorderWidth: 2,
                },
            ],
        };
    }, []);

    // Chart options with boundaries
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            y: {
                min: 20, // Set the minimum boundary for the Y-axis
                max: 100, // Set the maximum boundary for the Y-axis
                // title: {
                //     display: true,
                //     text: "Value",
                // },
                grid: {
                    borderDash: [5, 5], // Dashed grid lines (5px dash, 5px gap)
                    color: "rgba(200, 200, 200, 0.5)", // Optional: grid line color
                },
            },
            x: {
                // title: {
                //     display: true,
                //     text: "Months",
                // },
                grid: {
                    borderDash: [5, 5], // Dashed grid lines for X-axis
                    color: "rgba(200, 200, 200, 0.5)", // Optional: grid line color
                },
            },
        },
    };


    return <Line data={data} options={options} />;
};

export default LineChart;
