import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  CustomDatePicker,
  CustomTimePicker,
  MuiBox,
  MuiTypography,
  Select,
} from "components/atoms";
import { Button, IconButton } from "@mui/material";

import PlusIcon from "assets/images/SVG/plus.svg";
import Location from "assets/images/SVG/locationBlack.svg";
const ScheduleFilterModal = () => {
  return (
    <MuiBox sx={{ px: { xs: "10px", sm: "20px", md: "30px" } }}>
      <MuiTypography variant="h6" fontWeight={"bold"} align={"center"}>
        Filter By
      </MuiTypography>
      <MuiBox>
        <Select
          label="Address"
          variant="plain"
          icon={<img src={Location} />}
          placeholder="Enter here"
        />
      </MuiBox>
      <MuiBox sx={{ mt: 5 }}>
        <Grid container spacing={"25px"} sx={{ mt: "20px" }}>
          <Grid xs={6}>
            <CustomDatePicker label={"Start Date"} />
          </Grid>
          <Grid xs={6}>
            <CustomDatePicker label={"End Date"} />
          </Grid>
        </Grid>
      </MuiBox>

      <MuiBox sx={{ mt: 5 }}>
        <MuiBox
          sx={{
            display: "center",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MuiTypography variant="subtitle1" fontWeight={"bold"}>
            Choose Date
          </MuiTypography>
          <IconButton className="plus-button-02 button-hover">
            <img
              src={PlusIcon}
              alt="add"
              style={{ width: "17px", height: "17px", objectFit: "contain" }}
            />
          </IconButton>
        </MuiBox>
        <Grid container spacing={"25px"} sx={{ mt: "20px" }}>
          <Grid xs={6}>
            <CustomTimePicker label={"Start Time"} />
          </Grid>
          <Grid xs={6}>
            <CustomTimePicker label={"End Time"} />
          </Grid>
        </Grid>
      </MuiBox>

      <MuiBox sx={{ mt: 5, display: "flex", gap: 3, justifyContent: "center" }}>
        <Button type="submit" variant="darkButton" sx={{ maxWidth: "200px" }}>
          Cancel
        </Button>
        <Button type="submit" variant="lightButton" sx={{ maxWidth: "200px" }}>
          Apply
        </Button>
      </MuiBox>
    </MuiBox>
  );
};

export default ScheduleFilterModal;
