import axiosClient from "utils/api";
const URI = "/admin/paymentPeriod";

export const getPaymentPeriods = async (payload) => {
  const URL = `${URI}/paymentPeriod`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Payment Periods:", error);
    throw error;
  }
};

export const deletePaymentPeriod = async (payload) => {
  const URL = `${URI}/paymentPeriod/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Payment Period:", error);
    throw error;
  }
};

export const updatePaymentPeriod = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/paymentPeriod/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Payment Period:", error);
    throw error;
  }
};

export const addPaymentPeriod = async (payload) => {
  const URL = `${URI}/paymentPeriod`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Payment Period:", error);
    throw error;
  }
};

export const updateVisiblityPaymentPeriod = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update visiblity Period:", error);
    throw error;
  }
};

export const updateDefaultPaymentPeriod = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/default/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update default Payment Period:", error);
    throw error;
  }
};
