import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { Name, Country } from "utils/validation/AddProvince";
import { AddProvinceAction } from "store/middlewares/provinces";

function ModalAddProvinces() {
  const dispatch = useDispatch();

  const { countries } = useSelector((state) => state.countries);

  const intitalVal = {
    name: "",
    countryId: "",
  };

  const AddCategorySchema = Yup.object({ name: Name, countryId: Country });

  const handleSubmit = (values) => {
    dispatch(AddProvinceAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddCategorySchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SelectComponent
        className={"px-0"}
        label={"Country"}
        options={countries?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Country"
        name={"coutryId"}
        value={formik.values.countryId}
        error={formik.errors.countryId && formik.touched.countryId}
        errorMsg={formik.errors.countryId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "countryId",
            selectedOption ? selectedOption.value : ""
          );
        }}
      />
      {/* <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Code"}
        type="text"
        name="code"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.code && formik.touched.code}
        errorMsg={formik.errors.code}
        value={formik.values.code}
        placeholder="Enter code"
      /> */}
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddProvinces;
