import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const MuiBox = ({ children, ...props }) => {
    let {sx, ...rest} = props
    return (
        <Box
            sx={{
                width: '100%',
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};

MuiBox.propTypes = {
    children: PropTypes.node,
};

export default MuiBox;
