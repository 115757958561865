import { IconButton } from "@mui/material";
import React from "react";
import DeleteIcon from "assets/images/deleteIconSVG.svg";
import UnnbanIcon from "assets/images/UnnbanIcon.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";

const DeleteButton = ({ onClickFun, title, type }) => {
  return (
    <>
      {type === "Delete" && (
        <IconButton
          sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() => onClickFun()}
        >
          <div
            type="button"
            className="text-slate-500 btn bg-[#F8585A] border-slate-200 hover:bg-[#ff3e41] hover:border-slate-30"
          >
            <img src={DeleteIcon} className="inline-block size-5 mr-1" />
            <span className="align-middle text-white">{type}</span>
          </div>
        </IconButton>
      )}
      {type === "Restore" && (
        <IconButton
          sx={{ padding: "0 !important" }}
          disableRipple
          onClick={() => {
            Swal.fire({
              title: title,
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                onClickFun();
              }
            });
          }}
        >
          <div
            type="button"
            className="text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 ml-3 rounded-lg"
          >
            <img src={UnnbanIcon} className="inline-block size-5 mr-1" />
            <span className="align-middle text-white">{type}</span>
          </div>
        </IconButton>
      )}
    </>
  );
};

export default DeleteButton;
