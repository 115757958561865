import { createSlice } from "@reduxjs/toolkit";
import {
  AddBadgesAction,
  DeleteBadgesAction,
  UpdateBadgesAction,
  GetAllBadgesAction,
  GetAllBadgesForWebAction,
} from "store/middlewares/badges";

export const badgesSlice = createSlice({
  name: "badges",
  initialState: {
    badgesLoading: false,
    badges: [],
    badgesFilterProps: {},
  },
  reducers: {
    setBadgesFilterProps: (state, action) => {
      state.badgesFilterProps = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetAllBadgesAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(GetAllBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = action.payload.response;
      })
      .addCase(GetAllBadgesAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })

      .addCase(GetAllBadgesForWebAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(GetAllBadgesForWebAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = action.payload.response;
      })
      .addCase(GetAllBadgesForWebAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })

      // Delete Badges
      .addCase(DeleteBadgesAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.badges?.filter((title) => title.id !== id);
        state.badgesLoading = false;
        state.badges = tempArray;
      })

      //Update Badges
      .addCase(UpdateBadgesAction.fulfilled, (state, action) => {
        let {
          data: { id, ...rest },
        } = action.payload.response;

        let tempArray = state.badges?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.badgesLoading = false;
        state.badges = tempArray;
      })

      //Add Badges
      .addCase(AddBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = [action.payload.response, ...state.badges];
      });
  },
});
export const { setBadgesFilterProps } = badgesSlice.actions;
export default badgesSlice.reducer;
