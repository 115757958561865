import axiosClient from "utils/api";
const URI = "/admin/specialities";
const WEB = "/specialities";

export const getSpecialities = async (payload) => {
  const URL = `${URI}/specialities`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Specialities:", error);
    throw error;
  }
};

export const getSpecialitiesWeb = async (payload) => {
  const URL = `${WEB}/specialities`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Specialities:", error);
    throw error;
  }
};

export const deleteSpeciality = async (payload) => {
  const URL = `${URI}/speciality/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Speciality:", error);
    throw error;
  }
};

export const updateSpeciality = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/speciality/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Speciality:", error);
    throw error;
  }
};

export const addSpeciality = async (payload) => {
  const URL = `${URI}/speciality`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Speciality:", error);
    throw error;
  }
};

export const updateVisiblitySpeciality = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Facility:", error);
    throw error;
  }
};
