import React, { useEffect, useState } from "react";
import TableContainer from "../tablecomponet/TableContainer";
import blankImg from "assets/images/blank.png";
import { Box, IconButton, Typography } from "@mui/material";
import CustomLoader from "../CustomLoader";
import TooltipProvider from "../TooltipProvider";
import { ChevronsRight } from "lucide-react";
import checkAccess from "utils/checkAccess";
import { useNavigate } from "react-router-dom";

const ProductsStatistics = ({
  dashboardLoading,
  tableData,
  title = "Users",
}) => {
  const navigate = useNavigate();
  const [columnsToDisplay, setColumnsToDisplay] = useState([]);
  const columns = React.useMemo(
    () => [
      {
        header: "No.",
        cell: ({ row }) => (
          <Typography variant="p">{row.index + 1} </Typography>
        ),
      },
      {
        header: "Image",
        accessorKey: "profileImage",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
          <Box className="flex items-center">
            <img
              className="h-14 w-14 mr-5 rounded-[50%] object-cover pointer-events-none"
              src={
                row.original.profileImage ? row.original.profileImage : blankImg
              }
              alt={row.original.firstName}
            />
          </Box>
        ),
      },
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Email",
        accessorKey: "email",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: title === "Professional Users" ? "Phone No." : "DUNS Number",
        accessorKey: title === "Professional Users" ? "phone" : "dunsNumber",
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  );
  useEffect(() => {
    setColumnsToDisplay([
      ...columns,
      {
        id: "action",
        header: <p>Action</p>,
        enableColumnFilter: false,
        cell: ({ row }) =>
          title === "Professional Users"
            ? checkAccess("read_professional") && (
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  color="info"
                  disableRipple
                  onClick={() =>
                    navigate(`/admin/professional/active/${row?.original.id}`)
                  }
                >
                  <TooltipProvider title="Detail">
                    <ChevronsRight />
                  </TooltipProvider>
                </IconButton>
              )
            : checkAccess("read_hospital") && (
                <IconButton
                  sx={{ marginLeft: "auto" }}
                  color="info"
                  disableRipple
                  onClick={() =>
                    navigate(`/admin/hospital/active/${row?.original.id}`)
                  }
                >
                  <TooltipProvider title="Detail">
                    <ChevronsRight />
                  </TooltipProvider>
                </IconButton>
              ),
      },
    ]);
  }, [columns]);
  return (
    <div className="Professional-User-page">
      {!dashboardLoading ? (
        <div className="card mt-5">
          <div className="card-body p-3">
            <TableContainer
              isPagination={false}
              isSelect={false}
              isGlobalFilter={false}
              columns={columnsToDisplay}
              data={tableData ? tableData : []}
              customPageSize={5}
              divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12 email-tablea"
              tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
              theadclassName="border-b border-slate-200 "
              trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
              thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
              tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-wrap overflow-break-word"
            />
          </div>
        </div>
      ) : (
        <CustomLoader />
      )}
    </div>
  );
};

export default ProductsStatistics;
