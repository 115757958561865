import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import React, { useEffect } from "react";
import {
  GetProfessionalUserResume,
  UpdateProfessionalUserResume,
} from "store/middlewares/credential";
import { useDispatch, useSelector } from "react-redux";
import {
  AddButton,
  CustomLoader,
  DeleteButton,
  NoDataFound,
} from "components/atoms";
import Graduate from "assets/images/Graduate.svg";
import ResumeBag from "assets/images/ResumeBag.svg";
import Swal from "sweetalert2";
import checkAccess from "utils/checkAccess";

function Resume({ userId }) {
  const dispatch = useDispatch();
  const { resumes, resumesLoading } = useSelector((state) => state.credential);

  useEffect(() => {
    dispatch(GetProfessionalUserResume({ id: userId }));
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">Resume :</h6>
          {!resumesLoading ? (
            <>
              <div className="grid grid-cols-1 gap-4">
                {resumes?.map((item) => (
                  <div
                    className="border border-dashed card border-custom-200"
                    key={item?.id}
                  >
                    <div className="card-body">
                      <div className="grid grid-cols-1 gap-4">
                        <h6 className="mb-4 text-16 font-bold underline capitalize">
                          Personal Information
                        </h6>
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <span className="font-bold capitalize ">
                                name :{" "}
                              </span>
                              <span className="mb-4 text-slate-500 capitalize">
                                {item?.personalData?.name}
                              </span>
                            </div>
                            <div>
                              <span className="font-bold capitalize ">
                                language :{" "}
                              </span>
                              <span className="mb-4 text-slate-500 capitalize">
                                {item?.languages
                                  ?.map((lang) => lang.language)
                                  .join(", ")}
                              </span>
                            </div>
                            <div>
                              <span className="font-bold capitalize ">
                                skill :{" "}
                              </span>
                              <span className="mb-4 text-slate-500 capitalize">
                                {item?.skills?.join(", ")}
                              </span>
                            </div>
                            <div>
                              <span className="font-bold capitalize ">
                                address :{" "}
                              </span>
                              <span className="mb-4 text-slate-500 capitalize">
                                {item?.personalData?.street},{" "}
                                {item?.personalData?.city?.name},{" "}
                                {item?.personalData?.state?.name},{" "}
                                {item?.personalData?.country?.name},{" "}
                                {item?.personalData?.zipcode}{" "}
                              </span>
                            </div>
                            <div>
                              <span className="font-bold capitalize ">
                                about :{" "}
                              </span>
                              <span className="mb-4 text-slate-500 capitalize">
                                {item?.personalData?.about}
                              </span>
                            </div>
                          </div>
                        </div>
                        {item?.education?.length > 0 && (
                          <>
                            <h6 className="mb-4 text-16 font-bold underline capitalize">
                              education
                            </h6>
                            <div className="grid grid-cols-3 gap-4">
                              {item?.education?.map((edu) => (
                                <div className="card" key={edu.id}>
                                  <div className="card-body">
                                    <div className="flex">
                                      <div className="flex py-4 pl-0 pr-6  justify-center">
                                        <img
                                          className="size-8"
                                          src={Graduate}
                                        ></img>
                                      </div>
                                      <div>
                                        <span className="font-bold text-16 capitalize ">
                                          Studied at
                                        </span>
                                        <br />
                                        <span className="font-bold text-16 capitalize ">
                                          {edu.college}
                                        </span>
                                        <br />
                                        <span className=" text-base capitalize ">
                                          {edu.degree}
                                        </span>
                                        <br />
                                        <span className=" text-base capitalize ">
                                          batch {edu.startMonth},{edu.startYear}{" "}
                                          to {edu.endMonth},{edu.endYear}
                                        </span>
                                        <br />
                                        <span className="text-slate-500 text-sm capitalize ">
                                          {edu.description}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {item?.experience?.length > 0 && (
                          <>
                            <h6 className="mb-4 text-16 font-bold underline capitalize">
                              experience
                            </h6>
                            <div className="grid grid-cols-3 gap-4">
                              {item.experience.map((exp) => (
                                <div className="card" key={exp.id}>
                                  <div className="card-body">
                                    <div className="flex">
                                      <div className="flex py-4 pl-0 pr-6 justify-center">
                                        <img
                                          className="size-8"
                                          src={ResumeBag}
                                        ></img>
                                      </div>
                                      <div>
                                        <span className="font-bold text-16 capitalize ">
                                          {exp.hospitalName}
                                        </span>
                                        <br />
                                        <span className=" text-base capitalize ">
                                          {exp.role}
                                        </span>
                                        <br />
                                        <span className=" text-base capitalize ">
                                          <>
                                            {exp.startMonth}, {exp.startYear} to{" "}
                                            {exp.endMonth && exp.endYear
                                              ? `${exp.endMonth}, ${exp.endYear}`
                                              : "Present"}
                                          </>
                                        </span>
                                        <br />
                                        <span className="text-slate-500 text-sm capitalize ">
                                          {exp.phoneNumber}
                                        </span>
                                        <br />
                                        <span className="text-slate-500 text-sm capitalize ">
                                          {exp.address}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                      {checkAccess("update_credentials") && (
                        <div className="text-end mt-2">
                          {item.status !== "accepted" && (
                            <AddButton
                              title="Accept"
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    "Are you sure you want to accept this resume?",
                                  imageUrl: ApprovedSVGModel,
                                  imageWidth: 180,
                                  imageHeight: 180,
                                  showCancelButton: true,
                                  confirmButtonColor: "#00AE46",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Confirm",
                                  customClass: {
                                    title: "Swal-title-class",
                                    popup: "custom-swal-popup",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    dispatch(
                                      UpdateProfessionalUserResume({
                                        id: item.id,
                                        status: "accepted",
                                      })
                                    );
                                  }
                                });
                              }}
                            />
                          )}
                          {item.status !== "rejected" && (
                            <DeleteButton
                              title="Reject"
                              style="ml-2"
                              onClick={() => {
                                Swal.fire({
                                  title:
                                    "Are you sure you want to reject this resume?",
                                  imageUrl: RejectSVGModel,
                                  imageWidth: 180,
                                  imageHeight: 180,
                                  showCancelButton: true,
                                  confirmButtonColor: "#00AE46",
                                  cancelButtonColor: "#d33",
                                  confirmButtonText: "Confirm",
                                  customClass: {
                                    title: "Swal-title-class",
                                    popup: "custom-swal-popup",
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    dispatch(
                                      UpdateProfessionalUserResume({
                                        id: item.id,
                                        status: "rejected",
                                      })
                                    );
                                  }
                                });
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {resumes?.length === 0 && <NoDataFound />}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Resume;
