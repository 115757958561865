import React, { Fragment, useEffect, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from "lucide-react";

const Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue();
  return (
    <>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
};

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);
  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const TableContainer = ({
  columns,
  data,
  tableclassName,
  theadclassName,
  divclassName,
  trclassName,
  thclassName,
  tdclassName,
  tbodyclassName,
  isTfoot,
  isSelect,
  isPagination,
  customPageSize,
  isGlobalFilter,
  PaginationClassName,
  SearchPlaceholder = "Search...",
  extraButton = <></>,
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [lowerLimit, setLowerLimit] = useState(0);
  const [upperLimit, setUpperLimit] = useState(5);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };
  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const {
    getHeaderGroups,
    getFooterGroups,
    getRowModel,
    getPageOptions,
    setPageIndex,
    setPageSize,
    getState,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
  } = table;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);
  return (
    <Fragment>
      <div className="flex justify-end flex-wrap gap-3">
        <div className="self-center lg:place-self-start">
          {isGlobalFilter && (
            <label>
              Search:{"  "}
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={(value) => setGlobalFilter(String(value))}
                className="py-2 pr-4 text-sm text-topbar-item bg-topbar border border-topbar-border rounded pl-2 placeholder:text-slate-400 form-control focus-visible:outline-0 min-w-[200px] focus:border-blue-400 search-input-border-style"
                placeholder={SearchPlaceholder}
              />
            </label>
          )}
        </div>
        <div className="self-center lg:place-self-end">{extraButton}</div>
      </div>
      <div className={divclassName}>
        <table className={tableclassName}>
          <thead className={theadclassName}>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={trclassName}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      {...{
                        className: `${header.column.getCanSort()} ${thclassName}`,
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <Fragment>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.columnDef.enableSorting && (
                            <>
                              {header.column.getIsSorted() === "asc" && (
                                <ChevronUp className="inline-block size-4 ml-1" />
                              )}
                              {header.column.getIsSorted() === "desc" && (
                                <ChevronDown className="inline-block size-4 ml-1" />
                              )}
                            </>
                          )}
                          {{
                            asc: " ",
                            desc: " ",
                          }[header.column.getIsSorted() ?? ""] ?? null}
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </Fragment>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className={tbodyclassName}>
            {getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={trclassName}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={tdclassName}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {(data?.length === 0 || getRowModel().rows.length === 0) && (
              <tr className={trclassName}>
                <td colSpan={columns?.length}>
                  <p className="text-center my-4">No Data Found!</p>
                </td>
              </tr>
            )}
            {/* {table?.options?.data.length === 0 && (
              <tr className={trclassName}>
                <td colSpan={columns?.length}>
                  <p className="text-center my-4">No Data Found!</p>
                </td>
              </tr>
            )} */}
          </tbody>
          {isTfoot && (
            <tfoot>
              {getFooterGroups()?.map((footer, tfKey) => (
                <tr key={tfKey}>
                  {footer.headers?.map((tf, key) => (
                    <th
                      key={key}
                      className="p-3 text-left group-[.bordered]:border group-[.bordered]:border-slate-200"
                    >
                      {flexRender(tf.column.columnDef.header, tf.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      {isPagination && (
        <div className={PaginationClassName}>
          <div className="mb-4 grow md:mb-0 flex items-center">
            {isSelect && (
              <div className="mr-3">
                <label>
                  Show
                  <select
                    name="basic_tables_length"
                    aria-controls="basic_tables"
                    className="px-3 py-2 form-select border rounded ml-2 border-slate-200  focus:outline-none focus:border-custom-500 disabled:bg-slate-100  disabled:border-slate-300   disabled:text-slate-500  placeholder:text-slate-400  inline-block w-auto"
                    onClick={(event) => setPageSize(event.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            )}
            <div className="text-slate-500">
              {/* Showing */}
              {/* <b> {getState().pagination.pageSize}</b>  */}
              of
              <b> {data.length}</b> Results
            </div>
          </div>
          <ul className="flex flex-wrap items-center gap-2 shrink-0">
            <li>
              <button
                to="#!"
                className={`inline-flex items-center justify-center bg-white  h-8 px-3 transition-all duration-150 ease-linear border rounded border-slate-200  text-slate-500  hover:text-custom-500  hover:bg-custom-50  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-custom-500  [&.active]:bg-custom-50 [&.active]:border-custom-50  [&.active]:hover:text-custom-700 [&.disabled]:text-slate-400 [&.disabled]:cursor-auto ${
                  !getCanPreviousPage() && "disabled"
                }`}
                disabled={!getCanPreviousPage()}
                onClick={() => {
                  previousPage();
                  if (
                    getState().pagination.pageIndex === lowerLimit &&
                    lowerLimit > 0
                  ) {
                    setUpperLimit(upperLimit - 1);
                    setLowerLimit(lowerLimit - 1);
                  }
                }}
              >
                <ChevronLeft className="size-4 mr-1"></ChevronLeft> Prev
              </button>
            </li>
            {getPageOptions()
              ?.slice(lowerLimit, upperLimit)
              .map((item, key) => (
                <Fragment key={key}>
                  <li>
                    <button
                      to="#"
                      className={`inline-flex items-center justify-center bg-white  size-8 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500  hover:bg-custom-100  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-white  [&.active]:bg-custom-500  [&.active]:border-custom-500  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
                      ${getState().pagination.pageIndex === item && "active"}`}
                      onClick={() => setPageIndex(item)}
                    >
                      <p
                        style={{
                          color:
                            getState().pagination.pageIndex === item
                              ? "#fff"
                              : "inherit",
                        }}
                      >
                        {item + 1}
                      </p>
                    </button>
                  </li>
                </Fragment>
              ))}
            {getPageOptions().length > upperLimit && (
              <li>
                <button
                  className={`inline-flex items-center justify-center bg-white  size-8 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500  hover:bg-custom-100  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-white  [&.active]:bg-custom-500  [&.active]:border-custom-500  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
                `}
                  onClick={() => {
                    setUpperLimit(upperLimit + 1);
                    setLowerLimit(lowerLimit + 1);
                    setPageIndex(getState().pagination.pageIndex + 1);
                  }}
                >
                  <p
                    style={{
                      color: "inherit",
                    }}
                  >
                    ...
                  </p>
                </button>
              </li>
            )}
            <li>
              <button
                to="#!"
                className={`inline-flex items-center justify-center bg-white  h-8 px-3 transition-all duration-150 ease-linear border rounde text-slate-500  hover:text-custom-500 hover:bg-custom-50  focus:bg-custom-50  focus:text-custom-500  [&.active]:text-custom-500  [&.active]:bg-custom-50  [&.active]:border-custom-50  [&.active]:hover:text-custom-700  [&.disabled]:text-slate-400  [&.disabled]:cursor-auto 
                ${!getCanNextPage() && ""}`}
                onClick={() => {
                  getCanNextPage() && nextPage();
                  if (getState().pagination.pageIndex + 1 === upperLimit) {
                    setUpperLimit(upperLimit + 1);
                    setLowerLimit(lowerLimit + 1);
                  }
                }}
                disabled={!getCanNextPage()}
              >
                Next <ChevronRight className="size-4 ml-1"></ChevronRight>{" "}
              </button>
            </li>
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default TableContainer;
