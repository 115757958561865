import { MuiBox, MuiTypography, SearchInput } from "components/atoms";
import React, { Fragment, useEffect, useState } from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const SelectionModal = ({
  onClick,
  selected,
  options,
  multiple,
  title = "Speciality",
}) => {
  const [listStyle, setListStyle] = useState(multiple ? selected : selected[0]);

  const handleToggleStyle = (title) => {
    if (multiple) {
      if (listStyle?.includes(title)) {
        setListStyle(listStyle?.filter((item) => item !== title));
      } else {
        setListStyle([...listStyle, title]);
      }
    } else {
      setListStyle(title);
    }
  };

  useEffect(() => {
    onClick(listStyle);
  }, [listStyle]);

  return (
    <MuiBox sx={{ px: "30px" }}>
      <MuiTypography
        variant="h6"
        fontWeight={"bold"}
        align={"center"}
        sx={{ mb: "15px" }}
      >
        {title}
      </MuiTypography>
      <SearchInput
        type="text"
        variant="plain"
        name={"search"}
        placeholder="Search here..."
      />
      <MuiBox sx={{ mt: 2 }}>
        <List>
          {options?.map((title) => {
            return (
              <Fragment key={title?.value}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: 0 }}
                    onClick={() => handleToggleStyle(title?.value)}
                  >
                    <ListItemText
                      primary={title?.label}
                      sx={{
                        color: multiple
                          ? listStyle?.includes(title?.value)
                            ? "text.main"
                            : "button.light"
                          : listStyle === title?.value
                          ? "text.main"
                          : "button.light",
                        fontWeight: multiple
                          ? listStyle?.includes(title?.value)
                            ? "600"
                            : "400"
                          : listStyle === title?.value
                          ? "600"
                          : "400",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </MuiBox>
    </MuiBox>
  );
};

export default SelectionModal;
