import axiosClient from "utils/api";
const URI = "/admin/jobTitle";
const WEB = "/jobTitles";

export const getJobTitle = async (payload) => {
  const URL = `${URI}/jobTitle`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Job Title:", error);
    throw error;
  }
};

export const getJobTitleWeb = async (payload) => {
  const URL = `${WEB}/jobTitles`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Job Title:", error);
    throw error;
  }
};

export const deleteJobTitle = async (payload) => {
  const URL = `${URI}/jobTitle/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Job Title:", error);
    throw error;
  }
};

export const updateJobTitle = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/jobTitle/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Job Title:", error);
    throw error;
  }
};

export const updateJobTitleVisiblity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Job Title:", error);
    throw error;
  }
};

export const addJobTitle = async (payload) => {
  const URL = `${URI}/jobTitle`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Job Title:", error);
    throw error;
  }
};
