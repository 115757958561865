import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveProfessionalAction,
  BlockProfessionalAction,
  GetAllProfessionalAction,
  RestoreProfessionalAction,
  UnblockProfessionalAction,
} from "store/middlewares/users/professionals";
import {
  ActiveHospitalAction,
  BlockHospitalAction,
  GetAllHospitalsAction,
  RestoreHospitalAction,
  UnblockHospitalAction,
} from "store/middlewares/users/hospitals";

const initialState = {
  getUsersLoading: false,
  users: {
    items: [],
    totalPages: 1,
    totalCount: 0,
    currentPage: 1,
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    deletedUsersRequest: (state) => {
      state.getUsersLoading = true;
    },
    deletedUsersSuccess: (state, action) => {
      state.getUsersLoading = false;
      state.users = action.payload.data;
    },
    deletedUsersError: (state) => {
      state.getUsersLoading = false;
      state.users = [];
    },
    resetUser: (state) => {
      state.getUsersLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      // For Get All Professional Users
      .addCase(GetAllProfessionalAction.pending, (state) => {
        let { users } = state;
        state.getUsersLoading = users?.items?.length > 0 ? false : true;
        state.users = users?.items?.length > 0 ? { ...users } : null;
      })
      .addCase(GetAllProfessionalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let { users, totalPages, totalCount, currentPage } =
          action.payload.response;
        state.users = { items: users, totalPages, totalCount, currentPage };
      })
      .addCase(GetAllProfessionalAction.rejected, (state) => {
        state.getUsersLoading = false;
        state.users = null;
      })

      // For Get All Hospitals
      .addCase(GetAllHospitalsAction.pending, (state) => {
        let { users } = state;
        state.getUsersLoading = users?.items?.length > 0 ? false : true;
        state.users = users?.items?.length > 0 ? { ...users } : null;
      })
      .addCase(GetAllHospitalsAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let { users, totalPages, totalCount, currentPage } =
          action.payload.response;
        state.users = { items: users, totalPages, totalCount, currentPage };
      })
      .addCase(GetAllHospitalsAction.rejected, (state) => {
        state.getUsersLoading = false;
        state.users = null;
      })

      // For Activate Professional User
      .addCase(ActiveProfessionalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Activate Hospital User
      .addCase(ActiveHospitalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Block Professional User
      .addCase(BlockProfessionalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Block Hospital User
      .addCase(BlockHospitalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Unblock Professional User
      .addCase(UnblockProfessionalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Unblock Hospital User
      .addCase(UnblockHospitalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Restore Professional User
      .addCase(RestoreProfessionalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      })

      // For Restore Hospital User
      .addCase(RestoreHospitalAction.fulfilled, (state, action) => {
        state.getUsersLoading = false;
        let tempArray = state.users.items.filter(
          (user) => user.id !== action.payload.response.id
        );
        state.users = { ...state.users, items: tempArray };
      });
  },
});

export const { resetUser } = usersSlice.actions;

export default usersSlice.reducer;
