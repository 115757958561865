import React from "react";
import MuiTypography from "../MuiTypography";
import MuiBox from "../MuiBox";

const NoDataFoundCard = ({ title = "No Data Found" }) => {
  return (
    <MuiBox
      sx={{
        p: 2,
        bgcolor: "#fff",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        mt: "5px",
      }}
    >
      <MuiTypography variant="baseFont" color="#817D86">
        {title}
      </MuiTypography>
    </MuiBox>
  );
};

export default NoDataFoundCard;
