import React, { useContext } from "react";
import * as Yup from "yup";
// import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { SelectComponent, SubmitBtn } from "components/atoms";
import { FilterPopoverContext } from "context/PopoverContext";
import { useDispatch, useSelector } from "react-redux";
import { setFacilitiesFilterProps } from "store/slices/admin/facilities";

const FacilityFilter = () => {
  const dispatch = useDispatch();
  const { handleClose } = useContext(FilterPopoverContext);
  const { facilitiesFilterProps } = useSelector((state) => state.facilities);

  const intitalVal = {
    isVisible: "",
  };

  const visiblityOptions = [
    { label: "On", value: "true" },
    { label: "Off", value: "false" },
  ];

  const FacilityFilterSchema = Yup.object().shape({
    isVisible: Yup.string().optional(),
  });

  const handleSubmit = (values) => {
    dispatch(
      setFacilitiesFilterProps({
        ...values,
        isVisible: values.isVisible === "" ? null : values.isVisible,
      })
    );    
  };

  const formik = useFormik({
    initialValues: {...intitalVal,...facilitiesFilterProps},
    validationSchema: FacilityFilterSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onReset={() => {
        dispatch(setFacilitiesFilterProps(null));
      }}
    >
      <div className="p-4 flex flex-col gap-2">
        <p className="font-semibold">Filter </p>
        <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Visiblity"}
          isRequired={false}
          options={visiblityOptions}
          placeholder="Select Visiblity"
          name={"isVisible"}
          value={formik.values.isVisible}
          error={formik.errors.isVisible && formik.touched.isVisible}
          errorMsg={formik.errors.isVisible}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "isVisible",
              selectedOption ? selectedOption.value : ""
            );
          }}
        />
        {/* <SelectComponent
          selectclassName={"mt-2"}
          className={"px-0"}
          label={"By Access"}
          isRequired={false}
          options={access?.map((item) => ({
            label: item?.name,
            value: item?.name,
          }))}
          placeholder="Select Access"
          name={"accessName"}
          value={formik?.values?.accessName}
          error={formik.errors.accessName && formik.touched.accessName}
          errorMsg={formik.errors.accessName}
          onChange={(selectedOption) => {
            formik.setFieldValue(
              "accessName",
              selectedOption ? selectedOption.value : ""
            );
          }}
        /> */}
        <div className="flex self-end justify-between w-full">
          <SubmitBtn type="reset" title={"Reset"} buttonClassName={"!ml-0"} />
          <SubmitBtn title={"Apply"} buttonClassName={"!ml-0"} />
          <SubmitBtn
            onClickFunc={() => handleClose()}
            type={"button"}
            title={"Cancel"}
            buttonClassName={"!ml-0"}
          />
        </div>
      </div>
    </form>
  );
};

export default FacilityFilter;
