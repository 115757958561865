import axiosClient from "utils/api";

export const uploadFile = async (payload) => {
  const URL = `/upload/file`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Register:", error);
    throw error;
  }
};
