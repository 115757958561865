import React from "react";
// import { useDispatch } from 'react-redux';
import { Switch } from "@mui/material";

function VisiblitySwitch({ checked, defaultChecked, onChange, ...rest }) {
  return (
    <Switch
      checked={defaultChecked}
      defaultChecked={checked}
      onChange={onChange}
      {...rest}
    />
  );
}

export default VisiblitySwitch;
